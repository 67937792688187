import { useReducer } from "react";
import StateContext, { HEADER_STATE } from "./state-context";

const DEFAULT_STATE = {
  headerState: HEADER_STATE.Closed,
  portfolioShownTutorialsCount: 0,
  scroll: "Home"
};

const REDUCER_ACTION = {
  SetHeaderState: "SET_HEDER_STATE",
  IncrementPortfolioShownTutorialsCount: "INCREMENT_PORTFOLIO_TUTORIAL",
  ResetPortfolioTutorial: "RESET_PORTFOLIO_TUTORIAL",
  SetScroll: "SET_SCROLL"
};

const stateReducer = (state, action) => {
  switch (action.type) {
    case REDUCER_ACTION.SetHeaderState:
      return { ...state, headerState: action.headerState };
    case REDUCER_ACTION.IncrementPortfolioShownTutorialsCount:
      if(!state.portfolioShownTutorialsCount) {
        state.portfolioShownTutorialsCount = 0;
      }
      return {
        ...state,
        portfolioShownTutorialsCount: state.portfolioShownTutorialsCount + 1,
      };
    case REDUCER_ACTION.ResetPortfolioTutorial:
      return {
        ...state,
        portfolioShownTutorialsCount: 0,
      };
      case REDUCER_ACTION.SetScroll:
        return {
          ...state, scroll: action.payload
        }
    default:
      return DEFAULT_STATE;
  }
};

const StateProvider = (props) => {
  const [state, dispatchAction] = useReducer(stateReducer, DEFAULT_STATE);

  const setHeaderState = (headerState) => {
    dispatchAction({
      type: REDUCER_ACTION.SetHeaderState,
      headerState: headerState,
    });
  };

  const incrementPortfolioShownTutorialsCount = () => {
    dispatchAction({
      type: REDUCER_ACTION.IncrementPortfolioShownTutorialsCount,
    });
  };
  const resetPortfolioShownTutorialsCount = () => {
    dispatchAction({ type: REDUCER_ACTION.ResetPortfolioTutorial });
  };

  const setScroll = (scroll) => {
    dispatchAction({type: REDUCER_ACTION.SetScroll, payload: scroll})
  }

  const stateContext = {
    headerState: state.headerState,
    portfolioShownTutorialsCount: state.portfolioShownTutorialsCount,
    scroll: state.scroll,
    setHeaderState,
    incrementPortfolioShownTutorialsCount,
    resetPortfolioShownTutorialsCount,
    setScroll
  };

  return (
    <StateContext.Provider value={stateContext}>
      {props.children}
    </StateContext.Provider>
  );
};

export default StateProvider;
