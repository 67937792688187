import React, { useCallback } from "react";
import styles from "./ScrollUpSVG.module.css";

const ScrollUpSVG = ({ isVisible }) => {
  console.log(isVisible);

  const windowWidth = window.innerWidth;

  const scrollUpHandler = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div
      className={`absolute w-12 h-12 rounded-full`}
      style={{
        zIndex: 99,
        bottom: `${!isVisible ? 0 : "22vh"}`,
        right: `${windowWidth >= 768 ? "4rem" : "0"}`,
        opacity: `${isVisible ? 1 : 0}`,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className="w-full h-full"
        onClick={scrollUpHandler}
      >
        <path
          d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"
          className={styles.arrow}
        />

        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </div>
  );
};

export default ScrollUpSVG;
// fixed
//                 ease-out duration-1000

//                 w-12 h-12
//                 top-full
//                 z-50
//                 bg-backgroundColorDark
//                 rounded-full
//                 flex justify-center items-center
//                 ${
//                   isVisible
//                     ? "ease-out duration-300 opacity-50 bottom-[23vh] right-8"
//                     : "opacity-0 bottom-0"
//                 }
//                  hover:opacity-100
