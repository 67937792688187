import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StateContext, { HEADER_STATE } from "../../store/state-context";
import Logo from "../Logo";

const Header = () => {
  const [opacity, setOpacity] = useState(0);
  const navigateTo = useNavigate();

  var stateCtx = useContext(StateContext);

  const onMenuButtonClick = () => {
    stateCtx.setHeaderState(HEADER_STATE.Open);
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setOpacity(100);
    }, 333);
    return () => clearTimeout(timeOut);
  }, []);

  useEffect(() => {
    if (stateCtx.headerState === HEADER_STATE.Open) {
      setOpacity(0);
    } else if (stateCtx.headerState === HEADER_STATE.Closing) {
      setOpacity(100);
    }
  }, [stateCtx.headerState]);

  return (
    <div
      className={`absolute w-full
      top-0
      left-0
      pt-8
      px-8
      h-auto
      flex justify-between items-center
      bg-transparent 
      opacity-0 ${opacity === 0 ? "animate-fadeOut" : "animate-fadeIn"} z-20`}
    >
      <Logo
        className={`w-[12vmax] h-auto max-w-[128px] cursor-pointer`}
        inFooter={false}
        onClick={() => {
          navigateTo("/");
        }}
      />
      {/* <div
        className={`relative bg-none text-xl md:text-2xl xl:text-3xl
        origin-center
        font-extrabold 
        cursor-pointer
        border-2
        rounded-md
        border-backgroundColorDark dark:border-backgroundColor
         ease-out
         text-backgroundColorDark dark:text-backgroundColor
        lg:hover:scale-x-105 duration-300`}
        onClick={() => navigateTo("contactUs")}
      >
        <p className="ease-out duration-300 lg:hover:-translate-y-1 py-1 px-2">Contact Us</p>
      </div> */}
      <div className="relative h-[100%] mt-[2.5%] md:mt-[1.8%] lg:mt-[.75%]">
        <svg
          viewBox="0 0 32 22"
          
          xmlns="http://www.w3.org/2000/svg"
          className={`w-[3vmax] h-auto max-w-[32px] cursor-pointer fill-backgroundColorDark dark:fill-backgroundColor`}
          onClick={onMenuButtonClick}
        >
          <path d="M0 0H32V5.37778H0V0Z"  />
          <path d="M0 8.31111H32V13.6889H0V8.31111Z" />
          <path d="M0 16.6222H32V22H0V16.6222Z" />
        </svg>
      </div>
    </div>
  );
};

export default Header;
