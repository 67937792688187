import React, { useEffect, useState } from "react";
import ContactForm from "../ContactForm/ContactForm";

const ContactUs = () => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setOpacity(1);
  }, []);

  return (
    <div
      className={`min-h-[100vh]
      flex flex-col justify-start  
      shadow-xl
      w-full
      px-4 md:pl-[20vw] pt-32 lg:pt-40 lg:pl-[25vw]
      ease-in duration-[333ms]
     `}
      style={{ opacity: opacity ,
        transform: opacity === 0 ? "translateY(4px)" : "translateY(0)"
      }}
    >
      <p
        className={`
        text-black dark:text-backgroundColor
        uppercase 
        font-bold 
        text-center md:text-left
        text-3xl md:text-4xl lg:text-5xl 
        leading-relaxed md:leading-relaxed lg:leading-relaxed`}
      >
        Contact Us
      </p>
      <p
        className={`
        text-black dark:text-backgroundColor
        font-bold 
        text-center md:text-left
        text-xl
        leading-tight md:leading-tight lg:leading-tight
        `}
      >
        Send a message
      </p>
      <p
        className={`text-toofstone-green dark:to-toofstone-light-green
          mb-[5vh]
          text-center md:text-left
          text-lg md:text-xl
          leading-tight md:leading-tight`}
      >
        For all inquiries, please email us using the form below.
      </p>
      <ContactForm />
    </div>
  );
};

export default ContactUs;
