import { initializeApp } from "firebase/app";
import React, { useEffect } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyB7XqDt6Ixz4t3ad-kF6VjtMaG6_TlajuE",
  authDomain: "toof-stone.firebaseapp.com",
  projectId: "toof-stone",
  storageBucket: "toof-stone.appspot.com",
  messagingSenderId: "836945740733",
  appId: "1:836945740733:web:58009ad034dea6990b88d4",
  measurementId: "G-BBPVM3ZZEZ",
};

const FirebaseInitializer = () => {
  useEffect(() => {
    initializeApp(firebaseConfig);
  }, []);

  return <></>;
};

export default React.memo(FirebaseInitializer);
