import React, {forwardRef} from "react";
import { StackLinks, Svgs } from "../StackIcons";

const SericeItem = forwardRef(({ service, style, className }, ref) => {
  return (
    <div
      key={service.title}
      className={`w-full h-screen 
          p-8 md:p-12
          flex flex-col 
          justify-start
          gap-8 md:gap-24
          lg:gap-12 xl:gap-16
          ${className}
          `}
      style={style}
      ref={ref}
    >
      <div
        className="flex flex-col 
      gap-8 md:gap-24
      md:w-[80%] lg:w-[75%] xl:w-[50%]"
      >
        <p
          className={`text-backgroundColorDark dark:text-backgroundColor
             text-2xl lg:text-4xl xl:text-6xl font-semibold`}
        >
          {service.title}
        </p>
        <p className="text-xl lg:text-2xl xl:text-4xl leading-relaxed lg:leading-loose px-4 md:px-8 whitespace-pre-line text-backgroundColorDark dark:text-backgroundColor text-justify">
          {service.description}
        </p>
      </div>

      <div
        className={`relative w-full
         flex flex-wrap p-4 md:p-8 gap-4 lg:gap-8
            justify-start
            md:w-[80%] lg:w-[75%] xl:w-[50%]
            
             fill-backgroundColorDark dark:fill-backgroundColor`}
      >
        {service.stack.map((icon, index) => (
          <Svgs
            icon={icon}
            className={`flex-1 max-w-[15%] md:max-w-[10%] lg:max-w-[7.5%] h-auto
                ease-in hover:ease-out
                hover:scale-105 hover:-translate-y-3
                duration-150 hover:duration-300 
                origin-bottom`}
            key={`stack${service}${index}`}
            onClick={() => {
              window.open(
                `${StackLinks[icon]}`,
                "_blank",
                "noopener,noreferrer"
              );
            }}
          />
        ))}
      </div>
    </div>
  );
});

export default SericeItem;
