import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomNavLink from "../MobileHeader/CustomNavLink";
import StateContext, { HEADER_STATE } from "../../store/state-context";

const LINKS = [
  {
    id: 0,
    name: "Home",
    navigation: "/",
  },
  {
    id: 1,
    name: "Services",
    navigation: "services",
  },
  {
    id: 2,
    name: "Portfolio",
    navigation: "portfolio",
  },
  {
    id: 3,
    name: "Contact Us",
    navigation: "contactUs",
  },
];

const Menu = (props) => {
  const [opacity, setOpacity] = useState(0);
  const stateCtx = useContext(StateContext);

  const navigate = useNavigate();

  const onClose = useCallback(() => {
    stateCtx.setHeaderState(HEADER_STATE.Closing);
    setTimeout(() => {
      stateCtx.setHeaderState(HEADER_STATE.Closed);
    }, 300);
  }, [stateCtx]);

  const navigateTo = useCallback(
    (path) => {
      onClose();
      navigate(path);
    },
    [navigate, onClose]
  );

  useEffect(() => {
    switch (stateCtx.headerState) {
      case HEADER_STATE.Closing:
        setOpacity(0);
        return;
      case HEADER_STATE.Open:
        setOpacity(100);
        return;
      default:
        return;
    }
  }, [stateCtx.headerState]);

  if (stateCtx.headerState === HEADER_STATE.Closed) {
    return;
  }

  const view = (
    <div
      className={`absolute top-0 left-0 w-[100vw] h-[100vh] flex flex-col md:flex-row box-border
      opacity-0 ${
        opacity === 0
          ? "ease-in duration-200 translate-x-[15%] opacity-0"
          : "ease-out duration-200 translate-x-0 opacity-100"
      } z-20`}
    >
      <div
        className={`absolute top-0 left-0 w-[100vw] h-[50vh] md:w-[50%] md:h-[100%]
      bg-backgroundColor
      `}
      ></div>
      <div className="absolute top-[50%] md:top-0 md:left-[50%] bg-toofstone-green dark:bg-buttonBgDark w-[100vw] h-[50vh] z-[-1] md:w-[50%] md:h-[100%]"></div>
      <div
        className="absolute top-4 right-4 z-30 fill-white cursor-pointer w-10 h-10"
        onClick={onClose}
      >
        <svg
          className="w-6 h-7 md:w-9 md:h-10"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.61479 3.4274e-05L17 15.2877L14.4478 18L0.0625791 2.71236L2.61479 3.4274e-05Z"
            className="fill-black md:fill-white"
          />
          <path
            d="M16.9374 2.71232L2.55221 18L0 15.2876L14.3852 0L16.9374 2.71232Z"
            className="fill-black md:fill-white"
          />
        </svg>
      </div>

      <div className="flex z-20 flex-1 justify-center ">
        <ul className="list-none flex-1 z-20 flex flex-col justify-center align-middle pl-8 lg:pl-[35%] ">
          {LINKS.map((link, index) => (
            <CustomNavLink
              text={link.name}
              id={link.id}
              key={link.id}
              onClick={() => {
                if (link.id === 3) {
                  navigateTo(link.navigation);
                } else {
                  onClose();
                  stateCtx.setScroll(link.name)
                }
              }}
              className={`opacity-0 ${
                opacity === 0 ? "animate-fadeOut" : "animate-fadeIn"
              }`}
            />
          ))}
        </ul>
      </div>
      <div className="box-border flex-1 flex flex-col md:justify-center pl-8 pt-8 md:pt-0 lg:pt-0 md:pl-16 lg:pl-32">
        <p
          className={`text-white text-[4vmax] md:text-[3vmax] m-0 p-0 font-bold drop-shadow-2xl shadow-black`}
        >
          {/* Lets build something. */}
          Drum up your idea.
        </p>
        <p className="text-white text-[2.5vmax] md:text-[1vmax] mt-[3vh] p-0 w-[80%]">
          {/* Ready to start a project, get some advice, or just want more details
          about how we work? */}
          Leave all the coding to a trusted technology partner, and keep sharp
          focus on the business
        </p>
        <button
          className="relative border-white border-2 w-[21vmax] h-[5vmax] md:w-[13vmax] md:h-[3vmax] rounded-lg text-white font-semibold text-[2.5vmax] md:text-[1.3vmax] mt-[4vh]
          hover:scale-105  ease-out duration-200"
          onClick={() => navigateTo("contactUs")}
        >
          <div className="ease-out duration-[333ms] hover:scale-110">
            Get In Touch
          </div>
        </button>
      </div>
    </div>
  );

  //   return <>{ReactDOM.createPortal(view, portalElement)}</>;
  return view;

  // return <div className="absolute top-0 left-0 w-screen h-full bg-transparent backdrop-blur-lg">

  // </div>;
};

export default Menu;
