import React from "react";

function Svg({ icon, className, id, children, onClick, fill, backgroundFill }) {
  const mobileApp = (
    <svg
      onClick={onClick}
      className={className}
      id={id}
      width="107"
      height="120"
      viewBox="0 0 107 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.3907 110.315C56.4388 110.315 58.0991 108.661 58.0991 106.62C58.0991 104.579 56.4388 102.924 54.3907 102.924C52.3425 102.924 50.6822 104.579 50.6822 106.62C50.6822 108.661 52.3425 110.315 54.3907 110.315Z"
        fill="black"
      />
      <path
        d="M91.3516 77.3623H91.4752V77.2391L91.3516 77.3623ZM87.7049 81.6739L87.7668 81.1196L87.7049 81.1812V81.6739Z"
        fill="black"
      />
      <path
        d="M98.7067 41.9456H91.4752V40.0978H91.537V12.2572C91.537 5.48188 86.0361 0 79.2373 0H29.544C22.7452 0 17.2443 5.48188 17.2443 12.2572V23.4674H6.79883C3.09038 23.4674 0 26.4855 0 30.2428V55.558C0 59.2536 3.02857 62.3333 6.79883 62.3333H17.3061V106.804C17.3061 113.58 22.807 119.062 29.6058 119.062H79.2991C85.7271 119.062 90.9808 114.134 91.537 107.851V81.7355H98.7685C102.786 81.7355 106.062 78.471 106.062 74.4674V49.2138C106 45.2101 102.724 41.9456 98.7067 41.9456ZM6.79883 58.5761C5.13003 58.5761 3.70845 57.221 3.70845 55.4964V30.1812C3.70845 28.5181 5.06822 27.1014 6.79883 27.1014H41.4111C43.0799 27.1014 44.5015 28.4565 44.5015 30.1812V55.4964C44.5015 57.1594 43.1417 58.5761 41.4111 58.5761H6.79883ZM87.7049 81.6739V106.743C87.7049 111.424 83.8729 115.181 79.2373 115.181H29.544C24.8466 115.181 21.0764 111.362 21.0764 106.743V62.2717H41.4729C45.1813 62.2717 48.2717 59.2536 48.2717 55.4964V30.1812C48.2717 26.4855 45.2431 23.4058 41.4729 23.4058H21.0764V17.5543H50.9913C52.042 17.5543 52.9073 16.692 52.9073 15.6449C52.9073 14.5978 52.042 13.7355 50.9913 13.7355H21.0764V12.1957C21.0764 7.51449 24.9085 3.75725 29.544 3.75725H79.2373C83.9347 3.75725 87.7049 7.57609 87.7049 12.1957V13.7971H58.0991C57.0484 13.7971 56.2449 14.5978 56.2449 15.6449C56.2449 16.692 57.0484 17.4928 58.0991 17.4928H87.7049V41.8841H64.4653C60.4478 41.8841 57.172 45.1485 57.172 49.1522V74.2826C57.172 78.2862 60.4478 81.5507 64.4653 81.5507H87.7049V81.058L87.7668 80.9964L87.7049 81.6739ZM101.673 74.3442C101.673 76.0072 100.314 77.3007 98.7067 77.3007H64.5271C62.8583 77.3007 61.5603 75.9456 61.5603 74.3442V49.2138C61.5603 47.5507 62.9201 46.2572 64.5271 46.2572H98.7067C100.376 46.2572 101.673 47.6123 101.673 49.2138V74.3442Z"
        fill="black"
      />
      <path
        d="M81.8951 95.8406H26.8863C25.8356 95.8406 25.0321 95.0399 25.0321 93.9928C25.0321 92.9457 25.8356 92.1449 26.8863 92.1449H81.8951C82.9458 92.1449 83.7493 92.9457 83.7493 93.9928C83.7493 95.0399 82.9458 95.8406 81.8951 95.8406Z"
        fill="black"
      />
      <path
        d="M49.137 89.0652H26.8863C25.8356 89.0652 25.0321 88.2645 25.0321 87.2174C25.0321 86.1703 25.8356 85.3696 26.8863 85.3696H49.137C50.1878 85.3696 50.9913 86.1703 50.9913 87.2174C50.9913 88.2645 50.1878 89.0652 49.137 89.0652Z"
        fill="black"
      />
      <path
        d="M69.8426 89.0652H54.6997C53.649 89.0652 52.8455 88.2645 52.8455 87.2174C52.8455 86.1703 53.649 85.3696 54.6997 85.3696H69.8426C70.8933 85.3696 71.6968 86.1703 71.6968 87.2174C71.6968 88.2645 70.8933 89.0652 69.8426 89.0652Z"
        fill="black"
      />
      <path
        d="M81.895 88.942H74.7872C73.7982 88.942 73.0566 88.1413 73.0566 87.2174C73.0566 86.2935 73.8601 85.4928 74.7872 85.4928H81.895C82.884 85.4928 83.6257 86.2935 83.6257 87.2174C83.6257 88.1413 82.884 88.942 81.895 88.942Z"
        fill="black"
      />
      <path
        d="M71.5732 68.9855C71.1405 68.9855 70.7697 68.8007 70.5224 68.4928L65.516 62.3333L70.4606 55.4964C70.8933 54.8804 71.7586 54.7572 72.3767 55.1884C72.9948 55.6196 73.1184 56.4819 72.6857 57.0978L68.9773 62.2717L72.6239 66.8297C73.1184 67.4457 72.9948 68.308 72.4385 68.7391C72.1913 68.9239 71.8822 68.9855 71.5732 68.9855Z"
        fill="black"
      />
      <path
        d="M91.5988 68.9855C91.2898 68.9855 90.9808 68.8623 90.7335 68.6775C90.1155 68.1848 90.0536 67.3225 90.5481 66.7681L94.1948 62.2101L90.4863 57.0362C90.0536 56.4203 90.1773 55.558 90.7953 55.1268C91.4134 54.6957 92.2787 54.8188 92.7114 55.4348L97.656 62.2717L92.6496 68.4312C92.4023 68.8007 92.0315 68.9855 91.5988 68.9855Z"
        fill="black"
      />
      <path
        d="M77.6303 68.9855C77.3831 68.9855 77.1359 68.9239 76.9504 68.8007C76.2706 68.4312 76.0851 67.5688 76.456 66.9529L83.1312 55.6196C83.502 54.942 84.3673 54.7572 84.9854 55.1268C85.6653 55.4964 85.8507 56.3587 85.4799 56.9746L78.8047 68.308C78.5574 68.7391 78.1248 68.9855 77.6303 68.9855Z"
        fill="black"
      />
      <path
        d="M14.0921 49.8913C13.6595 49.8913 13.2886 49.7065 13.0414 49.3986L8.03499 43.2391L12.9796 36.4022C13.4122 35.7862 14.2776 35.663 14.8956 36.0942C15.5137 36.5254 15.6373 37.3877 15.2047 38.0036L11.4962 43.1775L15.1429 47.7355C15.6373 48.3515 15.5137 49.2138 14.9574 49.6449C14.7102 49.8297 14.4012 49.8913 14.0921 49.8913Z"
        fill="black"
      />
      <path
        d="M34.1178 49.8913C33.8088 49.8913 33.4997 49.7681 33.2525 49.5833C32.6344 49.0906 32.5726 48.2283 33.0671 47.6739L36.7137 43.1159L33.0052 37.942C32.5726 37.3261 32.6962 36.4638 33.3143 36.0326C33.9324 35.6014 34.7977 35.7246 35.2303 36.3406L40.1749 43.1775L35.1685 49.337C34.9213 49.7065 34.5504 49.8913 34.1178 49.8913Z"
        fill="black"
      />
      <path
        d="M20.1493 49.8913C19.902 49.8913 19.6548 49.8297 19.4694 49.7065C18.7895 49.337 18.6041 48.4746 18.9749 47.8587L25.6501 36.5254C26.021 35.8478 26.8863 35.663 27.5044 36.0326C28.1843 36.4022 28.3697 37.2645 27.9988 37.8804L21.3854 49.2138C21.0764 49.6449 20.6437 49.8913 20.1493 49.8913Z"
        fill="black"
      />
      <path
        d="M91.4752 77.2391V77.3623H91.3516L91.4752 77.2391Z"
        fill="black"
      />
      <path
        d="M87.7049 81.1812V81.6739L87.7668 81.6739L87.7049 81.1812Z"
        fill="black"
      />
    </svg>
  );

  const gameDev = (
    <svg
      onClick={onClick}
      id={id}
      className={className}
      width="118"
      height="84"
      viewBox="0 0 118 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M114.086 49.9886L104.672 33.6402C101.221 27.6218 95.0345 23.5346 88.0862 22.7711H87.9069V5.92857C87.9069 2.64989 85.2621 0 82.0345 0H34.338C31.1104 0 28.4207 2.64989 28.4207 5.92857V22.8609L28.1966 22.9058C21.5173 23.8939 15.7345 27.8014 12.3724 33.6851L2.95864 50.0336C0.0448475 55.1088 -0.762049 61.0373 0.762089 66.7413C2.28623 72.4004 5.91726 77.1613 10.9828 80.1255C16.0483 83.0449 21.9655 83.8534 27.6586 82.3263C33.3069 80.7992 38.0586 77.1613 41.0173 72.086L43.5276 67.7294H73.5621L76.0724 72.086C78.9862 77.1613 83.738 80.7992 89.4311 82.3263C91.3138 82.8204 93.2414 83.0898 95.169 83.0898C97.0517 83.0898 98.9793 82.8204 100.817 82.3263C102.655 81.8323 104.448 81.0687 106.152 80.1255C111.217 77.2062 114.848 72.4454 116.372 66.7413C117.762 60.9924 117 55.0639 114.086 49.9886ZM31.6483 5.92857C31.6483 4.44643 32.8586 3.23377 34.338 3.23377H82.0345C83.5138 3.23377 84.7242 4.44643 84.7242 5.92857V24.2532C81.5862 24.388 78.4035 25.2863 75.4448 26.993C75.1759 27.1277 74.9517 27.3074 74.6828 27.4421H42.3173C42.0931 27.3074 41.8242 27.1277 41.5552 26.993C38.4173 25.1964 35.0104 24.2982 31.6035 24.2532V5.92857H31.6483ZM113.235 65.8431C112.562 68.2684 111.486 70.5141 110.007 72.4454C108.528 74.3766 106.645 76.0384 104.493 77.296C100.145 79.8111 95.0793 80.4848 90.1931 79.1824C85.3517 77.8799 81.2724 74.7359 78.7621 70.3793L75.3552 64.4508H41.6897L38.2828 70.3793C35.7724 74.7359 31.6931 77.8799 26.8517 79.1824C22.0104 80.4848 16.9 79.8111 12.5517 77.296C8.20347 74.7808 5.06554 70.6937 3.76554 65.8431C2.46554 60.9924 3.13795 55.8723 5.6483 51.5157L15.0621 35.1672C17.6621 30.631 21.9655 27.4421 27.0311 26.2294C27.7483 29.5979 30.7517 32.1131 34.338 32.1131H82.0345C85.6655 32.1131 88.669 29.5081 89.3414 26.0947C94.6311 27.1726 99.2483 30.4513 101.938 35.1672L111.352 51.5157C113.862 55.9172 114.535 60.9924 113.235 65.8431Z"
        fill="black"
      />
      <path
        d="M30.9311 54.3902C35.8826 54.3902 39.8966 50.3685 39.8966 45.4075C39.8966 40.4465 35.8826 36.4248 30.9311 36.4248C25.9795 36.4248 21.9655 40.4465 21.9655 45.4075C21.9655 50.3685 25.9795 54.3902 30.9311 54.3902Z"
        fill="black"
      />
      <path
        d="M97.0517 45.4075C97.0517 46.2159 96.738 46.9345 96.2 47.4735C95.6621 48.0124 94.9448 48.3268 94.1379 48.3268H91V51.4708C91 53.0877 89.7 54.3901 88.0862 54.3901C86.4724 54.3901 85.1724 53.0877 85.1724 51.4708V48.3268H82.0345C80.4207 48.3268 79.1207 47.0243 79.1207 45.4075C79.1207 43.7906 80.4207 42.4881 82.0345 42.4881H85.1724V39.3442C85.1724 37.7273 86.4724 36.4248 88.0862 36.4248C88.8931 36.4248 89.6104 36.7392 90.1483 37.2781C90.6862 37.8171 91 38.5357 91 39.3442V42.4881H94.1379C95.7517 42.4881 97.0517 43.7906 97.0517 45.4075Z"
        fill="black"
      />
      <path
        d="M46.3966 23.1753C45.9483 23.1753 45.5 22.9957 45.1862 22.5914L39.4931 15.4951L45.1414 7.63528C45.6345 6.91667 46.6207 6.78193 47.338 7.27597C48.0552 7.77002 48.1897 8.75812 47.6966 9.47673L43.4828 15.3604L47.6517 20.5703C48.1897 21.244 48.1 22.2321 47.4276 22.7711C47.1138 23.0406 46.7552 23.1753 46.3966 23.1753Z"
        fill="black"
      />
      <path
        d="M69.2586 23.1753C68.9 23.1753 68.5862 23.0406 68.2724 22.816C67.6 22.2771 67.5104 21.289 68.0483 20.6153L72.2173 15.4053L68.0035 9.52165C67.5104 8.80303 67.6449 7.81493 68.3621 7.32089C69.0793 6.82684 70.0655 6.96158 70.5586 7.68019L76.2069 15.54L70.5138 22.6364C70.2 22.9508 69.7069 23.1753 69.2586 23.1753Z"
        fill="black"
      />
      <path
        d="M53.3448 23.1753C53.0759 23.1753 52.8069 23.0855 52.5379 22.9508C51.7759 22.5016 51.5517 21.5584 52 20.7949L59.6207 7.77002C60.069 7.00649 61.0104 6.78193 61.7724 7.23106C62.5345 7.68019 62.7586 8.62338 62.3104 9.3869L54.6897 22.4118C54.4207 22.9058 53.8828 23.1753 53.3448 23.1753Z"
        fill="black"
      />
    </svg>
  );

  const webDev = (
    <svg
      onClick={onClick}
      id={id}
      className={className}
      width="123"
      height="99"
      viewBox="0 0 123 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M114.544 0H8.45587C3.77816 0 0 3.82291 0 8.55604V66.4458C0 71.1789 3.77816 75.0019 8.45587 75.0019H57.6319V91.3858H32.3842C30.5851 91.3858 29.0858 92.9028 29.0858 94.7232C29.0858 96.5437 30.5851 98.0607 32.3842 98.0607H90.5558C92.3549 98.0607 93.8542 96.5437 93.8542 94.7232C93.8542 92.9028 92.3549 91.3858 90.5558 91.3858H65.3681V75.0019H114.544C119.222 75.0019 123 71.1789 123 66.4458V8.55604C123 3.82291 119.222 0 114.544 0ZM118.742 66.4458C118.742 68.8124 116.883 70.6935 114.544 70.6935H8.45587C6.11702 70.6935 4.25792 68.8124 4.25792 66.4458V19.1146H118.742V66.4458ZM118.742 14.8669H4.25792V8.55604C4.25792 6.18947 6.11702 4.30836 8.45587 4.30836H114.544C116.883 4.30836 118.742 6.18947 118.742 8.55604V14.8669Z"
        fill="black"
      />
      <path
        d="M12.5939 12.4396C13.7531 12.4396 14.6928 11.4888 14.6928 10.3158C14.6928 9.14283 13.7531 8.19195 12.5939 8.19195C11.4346 8.19195 10.4949 9.14283 10.4949 10.3158C10.4949 11.4888 11.4346 12.4396 12.5939 12.4396Z"
        fill="black"
      />
      <path
        d="M20.3901 12.4396C21.5493 12.4396 22.489 11.4888 22.489 10.3158C22.489 9.14283 21.5493 8.19195 20.3901 8.19195C19.2308 8.19195 18.2911 9.14283 18.2911 10.3158C18.2911 11.4888 19.2308 12.4396 20.3901 12.4396Z"
        fill="black"
      />
      <path
        d="M28.1863 12.4396C29.3455 12.4396 30.2852 11.4888 30.2852 10.3158C30.2852 9.14283 29.3455 8.19195 28.1863 8.19195C27.027 8.19195 26.0873 9.14283 26.0873 10.3158C26.0873 11.4888 27.027 12.4396 28.1863 12.4396Z"
        fill="black"
      />
      <path
        d="M42.2794 47.9381H18.8908V26.0929H42.2794V47.9381ZM23.0887 43.6904H38.0814V30.3406H23.0887V43.6904Z"
        fill="black"
      />
      <path
        d="M73.1643 48.2415H49.7757V26.3963H73.1643V48.2415ZM53.9737 43.9938H68.9664V30.644H53.9737V43.9938Z"
        fill="black"
      />
      <path
        d="M104.049 48.5449H80.6607V26.6997H104.049V48.5449ZM84.8586 44.2972H99.8513V30.9474H84.8586V44.2972Z"
        fill="black"
      />
      <path
        d="M40.1804 55.5232H20.9898C19.8503 55.5232 18.8908 54.5523 18.8908 53.3994C18.8908 52.2464 19.8503 51.2755 20.9898 51.2755H40.1804C41.3198 51.2755 42.2794 52.2464 42.2794 53.3994C42.2794 54.5523 41.3798 55.5232 40.1804 55.5232Z"
        fill="black"
      />
      <path
        d="M40.1804 62.805H20.9898C19.8503 62.805 18.8908 61.8341 18.8908 60.6811C18.8908 59.5282 19.8503 58.5573 20.9898 58.5573H40.1804C41.3198 58.5573 42.2794 59.5282 42.2794 60.6811C42.2794 61.8341 41.3798 62.805 40.1804 62.805Z"
        fill="black"
      />
      <path
        d="M71.3652 55.5232H52.1745C51.0351 55.5232 50.0756 54.5523 50.0756 53.3994C50.0756 52.2464 51.0351 51.2755 52.1745 51.2755H71.3652C72.5046 51.2755 73.4642 52.2464 73.4642 53.3994C73.4642 54.5523 72.5646 55.5232 71.3652 55.5232Z"
        fill="black"
      />
      <path
        d="M71.3652 62.805H52.1745C51.0351 62.805 50.0756 61.8341 50.0756 60.6811C50.0756 59.5282 51.0351 58.5573 52.1745 58.5573H71.3652C72.5046 58.5573 73.4642 59.5282 73.4642 60.6811C73.4642 61.8341 72.5646 62.805 71.3652 62.805Z"
        fill="black"
      />
      <path
        d="M102.55 55.5232H83.3593C82.2199 55.5232 81.2604 54.5523 81.2604 53.3994C81.2604 52.2464 82.2199 51.2755 83.3593 51.2755H102.55C103.689 51.2755 104.649 52.2464 104.649 53.3994C104.649 54.5523 103.749 55.5232 102.55 55.5232Z"
        fill="black"
      />
      <path
        d="M102.55 62.805H83.3593C82.2199 62.805 81.2604 61.8341 81.2604 60.6811C81.2604 59.5282 82.2199 58.5573 83.3593 58.5573H102.55C103.689 58.5573 104.649 59.5282 104.649 60.6811C104.649 61.8341 103.749 62.805 102.55 62.805Z"
        fill="black"
      />
    </svg>
  );

  const uiUx = (
    <svg
      onClick={onClick}
      className={className}
      id={id}
      width="124"
      height="115"
      viewBox="0 0 124 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.9867 105.68C53.9443 105.68 55.5312 104.095 55.5312 102.14C55.5312 100.184 53.9443 98.5994 51.9867 98.5994C50.0291 98.5994 48.4421 100.184 48.4421 102.14C48.4421 104.095 50.0291 105.68 51.9867 105.68Z"
        fill="black"
      />
      <path
        d="M76.9757 21.8913H57.1263C54.9404 21.8913 53.1682 23.6615 53.1682 25.8447V34.4006C53.1682 36.5839 54.9404 38.354 57.1263 38.354H76.9166C79.1024 38.354 80.8747 36.5839 80.8747 34.4006V25.8447C80.9338 23.6615 79.1615 21.8913 76.9757 21.8913ZM77.3892 34.4006C77.3892 34.6367 77.212 34.8137 76.9757 34.8137H57.1263C56.8899 34.8137 56.7127 34.6367 56.7127 34.4006V25.8447C56.7127 25.6087 56.8899 25.4317 57.1263 25.4317H76.9166C77.1529 25.4317 77.3302 25.6087 77.3302 25.8447V34.4006H77.3892Z"
        fill="black"
      />
      <path
        d="M77.98 45.4938H56.122C55.1177 45.4938 54.3497 44.7267 54.3497 43.7236C54.3497 42.7205 55.1177 41.9534 56.122 41.9534H77.98C78.9843 41.9534 79.7523 42.7205 79.7523 43.7236C79.7523 44.7267 78.9843 45.4938 77.98 45.4938Z"
        fill="black"
      />
      <path
        d="M77.98 51.3944H56.122C55.1177 51.3944 54.3497 50.6273 54.3497 49.6242C54.3497 48.6211 55.1177 47.854 56.122 47.854H77.98C78.9843 47.854 79.7523 48.6211 79.7523 49.6242C79.7523 50.6273 78.9843 51.3944 77.98 51.3944Z"
        fill="black"
      />
      <path
        d="M77.98 57.295H56.122C55.1177 57.295 54.3497 56.528 54.3497 55.5248C54.3497 54.5217 55.1177 53.7547 56.122 53.7547H77.98C78.9843 53.7547 79.7523 54.5217 79.7523 55.5248C79.7523 56.528 78.9843 57.295 77.98 57.295Z"
        fill="black"
      />
      <path
        d="M91.3311 73.9938H83.6513C82.5879 73.9938 81.7608 74.8199 81.7608 75.882C81.7608 76.9441 82.5879 77.7702 83.6513 77.7702H83.8876V102.258C83.8876 106.742 80.2249 110.342 75.7942 110.342H28.2382C23.7485 110.342 20.1448 106.683 20.1448 102.258V59.6553H39.6398C43.1844 59.6553 46.1382 56.764 46.1382 53.1646V28.913C46.1382 25.3727 43.2435 22.4224 39.6398 22.4224H20.1448V16.8168H48.7375C49.7418 16.8168 50.5688 15.9907 50.5688 14.9876C50.5688 13.9845 49.7418 13.1584 48.7375 13.1584H20.1448V11.6832C20.1448 7.19876 23.8075 3.59938 28.2382 3.59938H75.7351C80.2249 3.59938 83.8285 7.25776 83.8285 11.6832V13.2174H55.5312C54.5269 13.2174 53.7589 13.9845 53.7589 14.9876C53.7589 15.9907 54.5269 16.7578 55.5312 16.7578H83.8285V63.3137H83.5922C82.5288 63.3137 81.7018 64.1397 81.7018 65.2019C81.7018 66.264 82.5288 67.0901 83.5922 67.0901H91.272C92.3354 67.0901 93.1625 66.264 93.1625 65.2019C93.1625 64.1397 92.3354 63.3137 91.272 63.3137H87.4321V11.7422C87.4321 5.25155 82.1744 0 75.676 0H28.2382C21.7399 0 16.4821 5.25155 16.4821 11.7422V22.4814H6.49833C2.95379 22.4814 0 25.3727 0 28.972V53.2236C0 56.764 2.89471 59.7143 6.49833 59.7143H16.5412V102.317C16.5412 108.807 21.799 114.059 28.2973 114.059H75.7942C82.2925 114.059 87.5503 108.807 87.5503 102.317V77.8292H91.3902C92.4536 77.8292 93.2806 77.0031 93.2806 75.941C93.2215 74.8199 92.3354 73.9938 91.3311 73.9938ZM6.49833 56.1149C4.90329 56.1149 3.54455 54.8168 3.54455 53.1646V28.913C3.54455 27.3199 4.84421 25.9627 6.49833 25.9627H39.5808C41.1758 25.9627 42.5345 27.2609 42.5345 28.913V53.1646C42.5345 54.7578 41.2349 56.1149 39.5808 56.1149H6.49833Z"
        fill="black"
      />
      <path
        d="M78.2754 93.5839H25.698C24.6937 93.5839 23.9257 92.8168 23.9257 91.8137C23.9257 90.8106 24.6937 90.0435 25.698 90.0435H78.2754C79.2797 90.0435 80.0476 90.8106 80.0476 91.8137C80.0476 92.8168 79.2797 93.5839 78.2754 93.5839Z"
        fill="black"
      />
      <path
        d="M46.9652 87.0932H25.698C24.6937 87.0932 23.9257 86.3261 23.9257 85.323C23.9257 84.3199 24.6937 83.5528 25.698 83.5528H46.9652C47.9695 83.5528 48.7375 84.3199 48.7375 85.323C48.7375 86.3261 47.9695 87.0932 46.9652 87.0932Z"
        fill="black"
      />
      <path
        d="M66.7556 87.0932H52.282C51.2778 87.0932 50.5098 86.3261 50.5098 85.323C50.5098 84.3199 51.2778 83.5528 52.282 83.5528H66.7556C67.7599 83.5528 68.5279 84.3199 68.5279 85.323C68.5279 86.3261 67.7599 87.0932 66.7556 87.0932Z"
        fill="black"
      />
      <path
        d="M78.2754 86.9752H71.4817C70.5364 86.9752 69.8275 86.2081 69.8275 85.323C69.8275 84.4379 70.5955 83.6708 71.4817 83.6708H78.2754C79.2206 83.6708 79.9295 84.4379 79.9295 85.323C79.9295 86.2081 79.2206 86.9752 78.2754 86.9752Z"
        fill="black"
      />
      <path
        d="M102.792 70.9255C103.412 70.9255 103.914 70.767 103.914 70.5714C103.914 70.3759 103.412 70.2174 102.792 70.2174C102.172 70.2174 101.669 70.3759 101.669 70.5714C101.669 70.767 102.172 70.9255 102.792 70.9255Z"
        fill="black"
      />
      <path
        d="M75.9123 73.9938H68.2325C67.1691 73.9938 66.3421 74.8199 66.3421 75.882C66.3421 76.9441 67.1691 77.7702 68.2325 77.7702H75.9123C76.9757 77.7702 77.8028 76.9441 77.8028 75.882C77.8028 74.8199 76.9757 73.9938 75.9123 73.9938ZM60.5526 73.9938H52.8728C51.8094 73.9938 50.9824 74.8199 50.9824 75.882C50.9824 76.9441 51.8094 77.7702 52.8728 77.7702H60.5526C61.616 77.7702 62.4431 76.9441 62.4431 75.882C62.4431 74.8199 61.616 73.9938 60.5526 73.9938ZM45.1339 73.9938H37.454C36.3907 73.9938 35.5636 74.8199 35.5636 75.882C35.5636 76.9441 36.3907 77.7702 37.454 77.7702H45.1339C46.1972 77.7702 47.0243 76.9441 47.0243 75.882C47.0834 74.8199 46.1972 73.9938 45.1339 73.9938Z"
        fill="black"
      />
      <path
        d="M29.7742 77.7702H24.1029V72.1056C24.1029 71.0435 24.93 70.2174 25.9933 70.2174C27.0567 70.2174 27.8838 71.0435 27.8838 72.1056V73.9938H29.7742C30.8375 73.9938 31.6646 74.8199 31.6646 75.882C31.6646 76.9441 30.8375 77.7702 29.7742 77.7702Z"
        fill="black"
      />
      <path
        d="M25.9933 70.9255C24.93 70.9255 24.1029 70.0994 24.1029 69.0373V63.3727H29.7742C30.8375 63.3727 31.6646 64.1988 31.6646 65.2609C31.6646 66.323 30.8375 67.1491 29.7742 67.1491H27.8838V69.0373C27.8838 70.0994 27.0567 70.9255 25.9933 70.9255Z"
        fill="black"
      />
      <path
        d="M75.9123 63.3727H68.2325C67.1691 63.3727 66.3421 64.1988 66.3421 65.2609C66.3421 66.323 67.1691 67.1491 68.2325 67.1491H75.9123C76.9757 67.1491 77.8028 66.323 77.8028 65.2609C77.8028 64.1988 76.9757 63.3727 75.9123 63.3727ZM60.5526 63.3727H52.8728C51.8094 63.3727 50.9824 64.1988 50.9824 65.2609C50.9824 66.323 51.8094 67.1491 52.8728 67.1491H60.5526C61.616 67.1491 62.4431 66.323 62.4431 65.2609C62.4431 64.1988 61.616 63.3727 60.5526 63.3727ZM45.1339 63.3727H37.454C36.3907 63.3727 35.5636 64.1988 35.5636 65.2609C35.5636 66.323 36.3907 67.1491 37.454 67.1491H45.1339C46.1972 67.1491 47.0243 66.323 47.0243 65.2609C47.0834 64.1988 46.1972 63.3727 45.1339 63.3727Z"
        fill="black"
      />
      <path
        d="M104.682 63.3727V69.0373C104.682 69.6863 104.387 70.2174 103.914 70.5714C103.619 70.8074 103.205 70.9255 102.792 70.9255C102.378 70.9255 101.965 70.8074 101.669 70.5714C101.197 70.2174 100.901 69.6863 100.901 69.0373V67.1491H99.011C97.9476 67.1491 97.1205 66.323 97.1205 65.2609C97.1205 64.1988 97.9476 63.3727 99.011 63.3727H104.682Z"
        fill="black"
      />
      <path
        d="M124 87.5652L104.682 74.8199V72.1056C104.682 71.4565 104.387 70.9255 103.914 70.5714C103.619 70.8074 103.205 70.9255 102.792 70.9255C102.378 70.9255 101.965 70.8074 101.669 70.5714C101.197 70.9255 100.901 71.4565 100.901 72.1056V72.3416L97.4159 70.0404L95.5255 101.786L108.286 95.413L109.763 98.3633C110.176 99.1894 111.181 99.4845 112.008 99.1304C112.835 98.7174 113.13 97.7143 112.776 96.8882L111.299 93.9379L124 87.5652ZM99.1882 96.2391L100.311 77.7702L100.429 75.941L117.443 87.1522L99.1882 96.2391Z"
        fill="black"
      />
    </svg>
  );

  const downArrow = (
    <svg
      onClick={onClick}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11L13 17L18 11"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="13" cy="13" r="12.5" stroke="white" />
    </svg>
  );

  const facebookIcon = (
    <svg
      className={className}
      id={id}
      onClick={onClick}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4_169)">
        <rect width="31.3448" height="31.3448" fill={backgroundFill} />
        <path d="M31.3448 0H0V31.3448H31.3448V0Z" fill={backgroundFill} />
        <path
          d="M20.256 17.3064V27.645H16.5254V17.1317H13.5965C13.4629 17.1317 13.4218 17.1009 13.4218 16.9672C13.432 15.8676 13.432 14.7782 13.4218 13.6889C13.4218 13.545 13.4731 13.5142 13.6067 13.5142C14.5214 13.5245 15.4155 13.5142 16.3301 13.5142H16.5254V13.3395C16.5254 12.4351 16.5151 11.5513 16.5254 10.6469C16.5357 9.87612 16.6898 9.13618 17.0393 8.43734C17.6662 7.19383 18.6938 6.50527 20.0401 6.23807C20.8006 6.09419 21.5611 6.1353 22.3216 6.15585C22.8252 6.17641 23.3082 6.23807 23.8015 6.25862C23.9146 6.2689 23.9454 6.31001 23.9454 6.42305V9.37255C23.9454 9.4856 23.9146 9.52671 23.7912 9.51643C23.226 9.51643 22.6608 9.51643 22.0955 9.52671C21.8386 9.52671 21.5714 9.54726 21.3145 9.60892C20.6773 9.74252 20.3279 10.1433 20.3073 10.8011C20.2765 11.6952 20.2868 12.579 20.2765 13.4525C20.2765 13.4731 20.2868 13.4833 20.2868 13.5142H23.8323C23.7912 13.8739 23.7399 14.1925 23.6987 14.511C23.6063 15.2715 23.5035 16.0115 23.411 16.772C23.3699 17.142 23.3802 17.142 22.9999 17.142H20.4512C20.2868 17.1214 20.256 17.1625 20.256 17.3064Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4_169">
          <rect width="31.3448" height="31.3448" fill={backgroundFill} />
        </clipPath>
      </defs>
    </svg>
  );

  const instagramIcon = (
    <svg
      className={className}
      id={id}
      onClick={onClick}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4_172)">
        <rect
          width="31.3448"
          height="31.3448"
          transform="translate(0.827576)"
          fill={backgroundFill}
        />
        <path
          d="M32.1724 0H0.827576V31.3448H32.1724V0Z"
          fill={backgroundFill}
        />
        <path
          d="M16.5308 5.38518C18.3499 5.38518 20.1792 5.37491 21.9982 5.38518C24.8244 5.39546 26.8284 7.31726 26.8592 10.1434C26.9003 13.8226 26.9003 17.5018 26.8592 21.1706C26.8181 24.264 24.9066 26.1036 21.7926 26.1242C18.2779 26.1344 14.7632 26.1344 11.2485 26.1242C8.05231 26.1139 6.14079 24.1921 6.13051 21.0165C6.12023 17.4709 6.12023 13.9254 6.13051 10.3798C6.14079 7.34809 8.12425 5.39546 11.1662 5.39546C12.9442 5.39546 14.7324 5.38518 16.5308 5.38518ZM16.5103 24.0276C18.2676 24.0276 20.025 24.0276 21.7824 24.0276C23.7761 24.0174 24.7935 23.0102 24.7935 21.037C24.8038 17.4915 24.8038 13.9459 24.7935 10.4004C24.7935 8.54022 23.7556 7.47141 21.916 7.46114C18.3396 7.45086 14.7632 7.45086 11.1868 7.46114C9.24444 7.47141 8.22702 8.48883 8.22702 10.4106C8.21674 13.987 8.21674 17.5634 8.22702 21.1398C8.22702 22.9691 9.28555 24.0174 11.1457 24.0276C12.9339 24.0379 14.7221 24.0276 16.5103 24.0276Z"
          fill={fill}
        />
        <path
          d="M16.5103 21.1502C13.4991 21.1605 11.1251 18.7762 11.1354 15.765C11.1457 12.8361 13.5299 10.4621 16.4794 10.4313C19.3878 10.4107 21.8338 12.8155 21.8646 15.7342C21.9057 18.7351 19.5112 21.1502 16.5103 21.1502ZM19.7681 15.837C19.7784 14.018 18.3293 12.5381 16.5206 12.5278C14.7015 12.5175 13.2422 13.9563 13.2319 15.7856C13.2216 17.6046 14.6604 19.0845 16.4692 19.1051C18.2574 19.1153 19.7681 17.6355 19.7681 15.837Z"
          fill={fill}
        />
        <path
          d="M23.4267 10.5032C23.4164 11.2534 22.7895 11.8597 22.0496 11.8597C21.2993 11.8597 20.7033 11.2534 20.7033 10.4826C20.7033 9.72213 21.3096 9.13634 22.0804 9.14662C22.8409 9.14662 23.437 9.75296 23.4267 10.5032Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4_172">
          <rect
            width="31.3448"
            height="31.3448"
            fill={backgroundFill}
            transform="translate(0.827576)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const linkedInIcon = (
    <svg
      className={className}
      id={id}
      onClick={onClick}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4_178)">
        <rect
          width="31.3448"
          height="31.3448"
          transform="translate(0.655151)"
          fill={backgroundFill}
        />
        <path d="M32 0H0.655151V31.3448H32V0Z" fill={backgroundFill} />
        <path
          d="M17.4992 23.6164C16.081 23.6164 14.7655 23.6164 13.3781 23.6164C13.3781 19.6701 13.3781 15.7545 13.3781 11.7568C14.7347 11.7568 16.0707 11.7568 17.4478 11.7568C17.4889 12.1987 17.5197 12.5995 17.5711 13.1545C18.6297 12.0651 19.8115 11.5615 21.1989 11.5513C23.614 11.5307 25.5666 12.9078 25.7927 15.3024C26.0394 18.0258 25.8544 20.7903 25.8544 23.6062C24.58 23.6062 23.2337 23.6062 21.7436 23.6062C21.7436 21.7769 21.7539 19.9681 21.7333 18.1696C21.723 17.553 21.7128 16.9261 21.5894 16.3301C21.3736 15.3229 20.6131 14.7371 19.6471 14.7371C18.7016 14.7371 17.9 15.3332 17.6431 16.2889C17.5403 16.6897 17.5095 17.1214 17.5095 17.5427C17.4889 19.5468 17.4992 21.5508 17.4992 23.6164Z"
          fill={fill}
        />
        <path
          d="M7.3147 11.7053C8.53766 11.7053 9.69896 11.7053 10.9425 11.7053C10.9425 15.6825 10.9425 19.5981 10.9425 23.5958C9.76062 23.5958 8.57877 23.5958 7.3147 23.5958C7.3147 19.6597 7.3147 15.7442 7.3147 11.7053Z"
          fill={fill}
        />
        <path
          d="M9.01037 10.205C7.7052 10.1844 6.74944 9.2492 6.78027 8.02623C6.8111 6.80327 7.77713 5.99139 9.16453 6.0325C10.4697 6.06333 11.3535 6.91632 11.3433 8.13928C11.3535 9.33141 10.3361 10.2255 9.01037 10.205Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4_178">
          <rect
            width="31.3448"
            height="31.3448"
            fill={backgroundFill}
            transform="translate(0.655151)"
          />
        </clipPath>
      </defs>
    </svg>
  );
  const clutchIcon = (
    <svg
      onClick={onClick}
      className={className}
      id={id}
      width="136"
      height="38"
      viewBox="0 0 136 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="136" height="38" rx="11" fill="white" />
      <g clipPath="url(#clip0_10_103)">
        <path
          d="M99.4712 5C100.863 5 102.223 5 103.615 5C103.615 8.13317 103.615 11.2451 103.615 14.4526C103.942 14.219 104.184 14.0065 104.458 13.8472C106.957 12.4453 111.501 12.5621 113.251 16.5768C113.821 17.8938 113.979 19.2958 113.989 20.719C114 24.0114 113.989 27.3039 113.989 30.5964C113.989 30.6495 113.979 30.7026 113.968 30.777C112.587 30.777 111.217 30.777 109.793 30.777C109.793 30.5645 109.793 30.3734 109.793 30.1822C109.793 26.7198 109.793 23.2467 109.793 19.7843C109.793 19.625 109.783 19.4657 109.783 19.3064C109.688 17.5752 108.802 16.7892 107.084 16.8954C105.07 17.0123 103.678 18.531 103.657 20.7296C103.625 23.9477 103.636 27.1658 103.636 30.384C103.636 30.5752 103.636 30.7663 103.636 30.9788C102.234 30.9788 100.873 30.9788 99.4817 30.9788C99.4712 22.3227 99.4712 13.6773 99.4712 5Z"
          fill="#0E333D"
        />
        <path
          d="M44.1079 26.826C41.1768 30.0866 37.4866 31.4567 33.1849 30.8407C28.9886 30.2353 25.9627 27.8456 24.2547 23.9477C22.5045 19.9543 22.5466 15.8971 24.6026 12.0098C26.5953 8.23938 29.7899 6.17893 34.06 5.92402C37.94 5.69036 41.2401 6.98611 43.9919 9.73693C44.1079 9.84314 44.2555 9.91749 44.4136 10.0237C43.412 11.0008 42.5158 11.8824 41.588 12.7958C41.2506 12.4877 40.8816 12.116 40.4915 11.7761C37.086 8.80229 31.6561 9.07843 28.9043 12.6683C27.9238 13.9428 27.3439 15.3873 27.1752 16.9698C26.901 19.5294 27.2701 21.9616 28.9043 24.0327C30.781 26.4118 33.2798 27.2721 36.2319 26.8472C38.2879 26.5498 40.0065 25.6152 41.3771 24.0858C42.2839 24.9886 43.18 25.8913 44.1079 26.826Z"
          fill="#0E333D"
        />
        <path
          d="M69.5807 30.9789C68.2101 30.9789 66.8605 30.9789 65.4582 30.9789C65.4582 30.5222 65.4582 30.0761 65.4582 29.5132C63.9927 30.7558 62.3374 31.032 60.6083 30.9682C57.1184 30.862 54.3771 28.3343 54.1346 24.8187C53.9554 22.1104 54.0292 19.3808 53.9976 16.6512C53.987 15.5785 53.9976 14.4952 53.9976 13.3906C55.3998 13.3906 56.7599 13.3906 58.1728 13.3906C58.1728 13.5924 58.1728 13.7836 58.1728 13.9748C58.1728 17.0761 58.1622 20.1668 58.1833 23.2681C58.1833 23.8841 58.2571 24.5107 58.4153 25.0949C58.7843 26.4331 59.6488 27.1128 61.0195 27.1341C61.9895 27.1447 62.9384 26.9323 63.7608 26.3694C65.0998 25.4666 65.4477 24.0965 65.4477 22.5883C65.4582 19.7207 65.4477 16.853 65.4477 13.9854C65.4477 13.7942 65.4477 13.603 65.4477 13.3906C66.8394 13.3906 68.189 13.3906 69.5807 13.3906C69.5807 19.2427 69.5807 25.0843 69.5807 30.9789Z"
          fill="#0E323C"
        />
        <path
          d="M97.4258 15.9821C96.4663 16.8849 95.5069 17.7877 94.5053 18.7223C93.356 17.2672 91.8272 16.7574 90.0665 16.8955C88.7802 16.9911 87.6521 17.469 86.7875 18.4674C85.1006 20.411 85.2482 23.7885 87.1038 25.6153C88.9173 27.4102 92.1963 27.5377 94.4842 25.2117C95.4331 26.1676 96.382 27.1235 97.3414 28.0793C93.2822 32.0516 87.6942 31.7223 84.4258 28.6741C80.9148 25.3922 80.6723 19.4764 83.7616 16.014C86.8086 12.594 92.6707 11.5744 97.4258 15.9821Z"
          fill="#0E333D"
        />
        <path
          d="M77.794 13.3693C78.8589 13.3693 79.8289 13.3693 80.8516 13.3693C80.8516 14.7075 80.8516 16.0245 80.8516 17.4052C79.871 17.4052 78.8589 17.4052 77.8151 17.4052C77.8151 21.9509 77.8151 26.4436 77.8151 30.9681C76.4023 30.9681 75.0316 30.9681 73.6188 30.9681C73.6188 26.4755 73.6188 21.9828 73.6188 17.4477C72.5961 17.4477 71.605 17.4477 70.5718 17.4477C70.5718 16.0882 70.5718 14.7712 70.5718 13.4117C71.5629 13.4117 72.5539 13.4117 73.5977 13.4117C73.5977 11.2663 73.5977 9.15275 73.5977 7.02856C75.0105 7.02856 76.3706 7.02856 77.7835 7.02856C77.794 9.11026 77.794 11.2132 77.794 13.3693Z"
          fill="#0E333D"
        />
        <path
          d="M46.7332 30.9576C46.7332 22.3016 46.7332 13.6668 46.7332 5.01074C48.1143 5.01074 49.485 5.01074 50.8767 5.01074C50.8767 13.6668 50.8767 22.291 50.8767 30.9576C49.5061 30.9576 48.146 30.9576 46.7332 30.9576Z"
          fill="#0D323C"
        />
        <path
          d="M93.2295 21.9936C93.2401 23.6186 91.9538 24.9462 90.3723 24.9462C88.7697 24.9462 87.4518 23.6398 87.4307 22.036C87.4201 20.4323 88.7697 19.0834 90.3828 19.0941C91.9327 19.0941 93.2295 20.4111 93.2295 21.9936Z"
          fill="#FE2C26"
        />
      </g>
      <defs>
        <clipPath id="clip0_10_103">
          <rect
            width="91"
            height="26"
            fill="white"
            transform="translate(23 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
  const moreButton = (
    <svg
      onClick={onClick}
      className={className}
      id={id}
      width="123"
      height="47"
      viewBox="0 0 123 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="121"
        height="45"
        rx="9"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M93 29L99 24L93 19"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="95"
        cy="24"
        r="12.5"
        transform="rotate(-90 95 24)"
        stroke="black"
      />
      {children}
    </svg>
  );
  const burger = (
    <svg
      onClick={onClick}
      className={className}
      id={id}
      width="32"
      height="22"
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H32V5.37778H0V0Z" fill="black" />
      <path d="M0 8.31111H32V13.6889H0V8.31111Z" fill="black" />
      <path d="M0 16.6222H32V22H0V16.6222Z" fill="black" />
    </svg>
  );
  const x = (
    <svg
      onClick={onClick}
      className={className}
      id={id}
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.61479 3.4274e-05L17 15.2877L14.4478 18L0.0625791 2.71236L2.61479 3.4274e-05Z"
        fill="black"
      />
      <path
        d="M16.9374 2.71232L2.55221 18L0 15.2876L14.3852 0L16.9374 2.71232Z"
        fill="black"
      />
    </svg>
  );
  const arrow = (
    <svg
      className={className}
      id={id}
      onClick={onClick}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.13999 1.90703L11.1416 9.42827L2.13999 16.4481"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );

  //Switch case returns the desired icon based on the value of "icon" prop
  switch (icon) {
    case "mobileApp":
      return mobileApp;
    case "gameDev":
      return gameDev;
    case "webDev":
      return webDev;
    case "uiUx":
      return uiUx;
    case "downArrow":
      return downArrow;
    case "fb":
      return facebookIcon;
    case "insta":
      return instagramIcon;
    case "linkedin":
      return linkedInIcon;
    case "clutch":
      return clutchIcon;
    case "more":
      return moreButton;
    case "burger":
      return burger;
    case "x":
      return x;
    case "arrow":
      return arrow;
    default:
      return null;
  }
}

export default Svg;
