import React from "react";
import { StackIcon } from "../StackIcons";
import Phone from "../Phone/Phone";
import { ORIENTATION } from "../Utils";

export const PORTFOLIO_ITEMS = [
  {
    id: "hollywood",
    title: "Hollywood Legends: Makeover",
    description:
      "Follow your dream quest - start your Hollywood story, become a movie star, and enjoy a red carpet adventure. Level up your avatar, dress up, and choose your own makeup and hairstyles.",
    imgCount: 8,
    stack: [
      StackIcon.Unity,
      StackIcon.Spine,
      StackIcon.CSharp,
      StackIcon.UniRx,
      StackIcon.Ps,
      StackIcon.Android,
      StackIcon.Ios,
    ],
    link: "https://play.google.com/store/apps/details?id=com.whaleapp.hollywood.legends.hidden.objects",
    demo: "hollywood-demo.mp4",
    orientation: ORIENTATION.Landscape,
  },
  {
    id: "bridge",
    title: "Tricky Bridge",
    description:
      "The fun, free app for learning bridge, practicing, and competing. Featured by Apple on the App Store!",
    imgCount: 8,
    stack: [
      StackIcon.Unity,
      StackIcon.Spine,
      StackIcon.CSharp,
      StackIcon.UniRx,
      StackIcon.Ps,
      StackIcon.Android,
      StackIcon.Ios,
    ],
    link: "https://apps.apple.com/us/app/tricky-bridge/id1437652160",
    demo: "bridge-demo.mp4",
    orientation: ORIENTATION.Portrait,
  },
  {
    id: "durak",
    title: "Kings Of Durak",
    description:
      "Welcome to the best family-friendly online UNO game: Crazy Mau Mau, otherwise known as Czech Fool, Crazy Eights (8s), English Fool, Pharaoh, or Pentagon with some rule variations.",
    imgCount: 2,
    stack: [
      StackIcon.Unity,
      StackIcon.Spine,
      StackIcon.CSharp,
      StackIcon.UniRx,
      StackIcon.Ps,
      StackIcon.Android,
      StackIcon.Ios,
    ],
    link: "https://play.google.com/store/apps/details?id=com.kingsofdurak.candywings",
    demo: "durak-demo.mp4",
    orientation: ORIENTATION.Landscape,
  },
  {
    id: "mau",
    title: "Mau Mau - The Craziest Game",
    description:
      "Create powerful decks. Compete with other players. Win gold and experience. Unlock devastating moves. Climb the top of leaderboards. Take everything. No mercy!",
    imgCount: 8,
    stack: [
      StackIcon.Unity,
      StackIcon.Spine,
      StackIcon.CSharp,
      StackIcon.UniRx,
      StackIcon.Ps,
      StackIcon.Android,
      StackIcon.Ios,
    ],
    link: "https://apps.apple.com/us/app/crazy-mau-mau-uno/id1506643679",
    demo: "mau-demo.mp4",
    orientation: ORIENTATION.Portrait,
  },
  {
    id: "siteSpecs",
    title: "Site Specs",
    description: `Site Specs is the best enterprise construction & property management photo & communication app.\n
      - Wirelessly upload and file photos to a secure server
      - Most meta data per photo enabling instant retrieval\n
      - Best communication app for your remote jobsites\n
      - Optimized team communication with notifications\n
      - Commenting enables immediate conflict resolution`,
    imgCount: 7,
    stack: [StackIcon.Aws, StackIcon.Figma, StackIcon.Android, StackIcon.Ios],
    link: "https://apps.apple.com/us/app/site-specs/id992667088",
    demo: "siteSpecs-demo.mp4",
    orientation: ORIENTATION.Portrait,
  },
];

const Portfolio = () => {
  return (
    <div
      className={`min-h-screen pt-24 landscape:pt-16 landscape:lg:pt-40 md:pt-40`}
    >
      <Phone className={``} />
    </div>
  );
};

export default Portfolio;
