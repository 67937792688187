import React from "react";

//<style type="text/css">{`.st0{fill:${fill};}`}</style>

const StackIcon = {
  Unity: "Unity",
  ThreeDsMax: "3dsMax",
  Android: "Android",
  Aws: "Aws",
  Blender: "Blender",
  CSharp: "C#",
  Css: "CSS",
  Html: "HTML",
  Ios: "iOS",
  Java: "Java",
  Kotlin: "Kotlin",
  Maui: "Maui",
  Ps: "Photoshop",
  React: "React",
  Swift: "Swift",
  Zenject: "Zenject",
  UniRx: "UniRx",
  Figma: "Figma",
  Firebase: "Firebase",
  Spine: "Spine",
};

export const StackLinks = {
  "Unity": "https://unity.com/",
  "3dsMax": "https://www.autodesk.com/products/3ds-max/overview?term=1-YEAR&tab=subscription",
  "Android": "https://www.android.com/what-is-android/",
  "Aws": "https://aws.amazon.com/",
  "Blender": "https://www.blender.org/",
  "C#": "https://learn.microsoft.com/en-us/dotnet/csharp/tour-of-csharp/",
  "CSS": "https://developer.mozilla.org/en-US/docs/Web/CSS",
  "HTML": "https://developer.mozilla.org/en-US/docs/Web/HTML",
  "iOS": "https://www.apple.com/ios",
  "Java": "https://www.java.com/en/download/help/whatis_java.html",
  "Kotlin": "https://kotlinlang.org/",
  "Maui": "https://learn.microsoft.com/en-us/dotnet/maui/what-is-maui?view=net-maui-7.0",
  "Photoshop": "https://www.adobe.com/products/photoshop.html",
  "React": "https://reactjs.org/",
  "Swift": "https://developer.apple.com/xcode/swiftui/",
  "Zenject": "https://github.com/modesttree/Zenject",
  "UniRx": "https://github.com/neuecc/UniRx",
  "Figma": "https://www.figma.com/",
  "Firebase": "https://firebase.google.com/?gclid=CjwKCAiAheacBhB8EiwAItVO208kHlAPSvJFtsPKv4BAuo9pzb7rrpw0JAcj2Jmd08SajXXB9vVHhxoCk4QQAvD_BwE&gclsrc=aw.ds",
  "Spine": "http://esotericsoftware.com/",
};

const Svgs = ({ icon, fill, className, onClick }) => {
  const unity = (
    <svg
      version="1.1"
      id="Layer_1"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      // xmlnSlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 106.9 111.6"
      style={{ enableBackground: "new 0 0 106.9 111.6" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g id="JU7nCx.tif">
        <g>
          <path
            className="st0"
            d="M106.9,66.9c0,0.2,0,0.5,0,0.7c-0.1,0.3-0.3,0.6-0.4,1c-3.5,13.2-7.1,26.4-10.6,39.5
             c-0.2,0.8-0.5,1.6-0.7,2.4c-0.6-0.1-1-0.1-1.4-0.3c-13.6-3.6-27.2-7.3-40.8-10.9c-0.8-0.2-1.6-0.9-2-1.6c-1.7-2.8-3.3-5.7-4.9-8.6
             c-0.6-1.1-1.2-1.6-2.5-1.5c-3.2,0.1-6.5,0-9.7,0.1c-1.3,0-2.2-0.3-3-1.2c-6.3-6.3-12.6-12.6-18.9-18.9C7.9,63.7,3.9,59.9,0,56
             c0-0.1,0-0.2,0-0.4c0.5-0.4,1.1-0.7,1.5-1.2c9.7-9.7,19.4-19.4,29.1-29.1c0.9-0.9,1.9-1.3,3.2-1.3c3.1,0.1,6.2,0,9.3,0.1
             c1.5,0.1,2.3-0.5,3-1.8c1.5-2.8,3-5.6,4.7-8.3c0.5-0.8,1.4-1.5,2.3-1.7C65.8,8.9,78.4,5.6,91.1,2.2c1.1-0.3,2.3-0.7,3.4-1.1
             c0.2,0,0.5,0,0.7,0c2.5,9.4,5,18.7,7.5,28.1c1.4,5.1,2.8,10.1,4.2,15.1c0,0.2,0,0.5,0,0.7c-2.1,3.5-4.2,6.9-6.3,10.4
             c-0.2,0.3-0.1,0.9,0,1.1C102.7,60,104.8,63.5,106.9,66.9z M88.9,22.8c-0.4,0.6-0.6,0.8-0.7,1.1c-6,10.3-11.9,20.6-17.8,30.9
             c-0.3,0.6-0.2,1.8,0.1,2.4C76.2,67.3,82,77.3,87.8,87.3c0.3,0.5,0.6,0.9,1,1.5c0.2-0.4,0.4-0.7,0.4-1c2.9-10.3,5.8-20.7,8.6-31
             c0.2-0.7,0.1-1.6-0.1-2.3c-1.4-5.1-2.8-10.1-4.2-15.1C92,34,90.5,28.6,88.9,22.8z M79.5,17.4c-1,0.2-1.6,0.3-2.2,0.5
             c-9.8,2.5-19.6,5-29.4,7.6c-1.1,0.3-2.3,0.9-3.1,1.7c-7.2,7.3-14.4,14.6-21.5,21.9c-0.3,0.4-0.6,0.8-1.1,1.3
             c0.5,0.1,0.7,0.1,1,0.1c12,0,23.9,0,35.9-0.1c0.7,0,1.6-0.7,2-1.3c3.5-6,7-12,10.5-18C74.2,26.7,76.7,22.2,79.5,17.4z M22.3,61.4
             c0.4,0.5,0.5,0.7,0.7,0.9c7.5,7.7,15,15.3,22.6,23c0.5,0.5,1.2,0.8,1.9,1c10.1,2.6,20.3,5.2,30.4,7.8c0.4,0.1,0.9,0.1,1.5,0.2
             c-0.2-0.4-0.3-0.7-0.4-0.9C73,83.1,67,72.8,61.1,62.5c-0.6-1-1.3-1.2-2.4-1.2c-11.5,0-23,0-34.6,0C23.6,61.3,23.1,61.4,22.3,61.4z
             "
          />
        </g>
      </g>
    </svg>
  );

  const html = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 73.1 104.9"
      style={{ enableBackground: "new 0 0 73.1 104.9" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g>
        <path
          className="st0"
          d="M3.9,25.1c1.3,0,2.4,0,3.5,0c20.8,0,41.7,0,62.5,0c1.4,0,1.4,0,1.2,1.4c-1,11.4-2,22.7-3,34.1
		c-1,10.8-1.9,21.6-2.9,32.4c-0.1,0.7-0.3,1-0.9,1.2c-8.8,2.4-17.5,4.9-26.3,7.3c-0.4,0.1-0.9,0-1.3-0.1c-8.5-2.3-17-4.7-25.5-7.1
		c-0.8-0.2-1.2-0.6-1.3-1.5C9.1,84.2,8.4,75.6,7.6,67c-1-11.3-2.1-22.6-3.1-33.8C4.3,30.6,4.1,28,3.9,25.1z M25.6,47.6
		c0.7,0,1.2,0,1.8,0c9.7,0,19.5,0,29.2,0.1c1,0,1.4-0.3,1.5-1.3c0.1-1.8,0.3-3.6,0.5-5.4c0.2-1.7,0.2-1.7-1.5-1.7
		c-13,0-26.1,0-39.1,0c-1.5,0-1.5,0.1-1.4,1.6c0.7,7.2,1.3,14.4,1.9,21.7c0.2,2.6,0.2,2.2,2.4,2.2c8.5,0,17,0,25.5,0
		c1.5,0,1.5,0,1.4,1.6c-0.2,2.8-0.5,5.6-0.7,8.3c-0.1,0.8-0.4,1.1-1.1,1.3c-2.6,0.7-5.2,1.4-7.9,2.1c-0.3,0.1-0.8,0.1-1.1,0
		c-2.7-0.7-5.3-1.4-8-2.1c-0.6-0.2-0.9-0.5-1-1.2c-0.1-1.5-0.3-3-0.4-4.5c0-0.9-0.3-1.3-1.3-1.3c-2,0.1-3.9,0.1-5.9,0
		c-0.9,0-1.3,0.3-1.2,1.2c0.4,3.7,0.7,7.4,1,11.1c0.1,0.8,0.6,1,1.2,1.2c5.1,1.4,10.2,2.8,15.3,4.2c0.6,0.2,1.4,0.1,2,0
		c5-1.3,10-2.8,15.1-4.1c0.8-0.2,1.1-0.6,1.2-1.4c0.7-7.8,1.4-15.6,2.1-23.3c0.1-1.5,0.1-1.5-1.4-1.5c-6.2,0-12.5,0-18.7,0
		c-3.1,0-6.3,0-9.4,0c-0.6,0-1.2,0-1.2-0.8C26.1,52.9,25.8,50.4,25.6,47.6z"
        />
        <path
          className="st0"
          d="M45.4,10.7c0.8-1.2,1.3-2.2,2.1-3.2c0.3-0.4,0.8-0.6,1.2-0.7c1-0.1,2.1,0,3.2,0c0.6,0,0.9,0.2,0.9,0.8
		c0,3.6,0,7.2,0,10.9c0,0.6-0.3,0.9-0.9,0.8c-0.1,0-0.2,0-0.2,0c-1,0-2.5,0.3-3-0.3c-0.6-0.5-0.2-2-0.3-3c0-0.8,0-1.6,0-2.4
		c-0.1,0-0.2-0.1-0.3-0.1c-0.8,1.3-1.7,2.6-2.7,4c-0.9-1.4-1.8-2.7-2.6-4c-0.1,0-0.2,0.1-0.3,0.1c0,1.5,0,3,0,4.5
		c0,0.9-0.2,1.2-1.2,1.2c-3.2,0-3.2,0-3.2-3.2c0-2.7,0-5.4,0-8.2c0-0.4,0-0.7,0.1-1.2c1.5,0,2.9,0,4.3,0c0.3,0,0.6,0.4,0.8,0.6
		C44.1,8.4,44.7,9.5,45.4,10.7z"
        />
        <path
          className="st0"
          d="M10.1,6.8c1.5,0,2.9,0,4.4,0c0,1.2,0,2.4,0,3.7c1.2,0,2.2,0,3.3,0c0.2,0,0.5-0.5,0.5-0.8
		c0.1-0.7,0-1.4,0.1-2.1c0-0.3,0.3-0.7,0.6-0.7c1.2-0.1,2.5,0,3.8,0c0,0.9,0,1.6,0,2.3c0,3,0,6,0,9c0,1-0.3,1.3-1.3,1.2
		c-0.7-0.1-1.5,0-2.3,0c-0.7,0-0.9-0.3-0.9-0.9c0-1.1,0-2.2,0-3.3c-1.3,0-2.4,0-3.8,0c0,1.1,0,2.2,0,3.3c0,0.7-0.2,1-1,1
		c-3.5,0.1-3.5,0.1-3.5-3.3C10.1,13,10.1,10,10.1,6.8z"
        />
        <path
          className="st0"
          d="M32.6,10.7c0,2.6,0,5,0,7.4c0,0.9-0.3,1.2-1.2,1.2c-3.2,0-3.2,0-3.2-3.2c0-1.7,0-3.5,0-5.4c-1.1,0-2,0-3,0
		c-0.6,0-0.9-0.2-0.8-0.8c0,0,0-0.1,0-0.1c-0.2-3.1-0.2-3.1,2.9-3.1c2.7,0,5.4,0,8,0c0.8,0,1.1,0.2,1.1,1.1c0,2.9,0.1,2.9-2.8,2.9
		C33.4,10.7,33.1,10.7,32.6,10.7z"
        />
        <path
          className="st0"
          d="M54.7,6.8c1.5,0,2.9,0,4.4,0c0,2.7,0,5.3,0,8.1c1.7,0,3.2,0.1,4.7,0c1.2-0.1,1.5,0.3,1.4,1.4c-0.1,3,0,3-3,3
		c-2.2,0-4.4,0-6.6,0c-0.5,0-1,0-1-0.7C54.7,14.7,54.7,10.8,54.7,6.8z"
        />
      </g>
    </svg>
  );

  const threeDsMax = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 106.9 111.6"
      style={{ enableBackground: "new 0 0 106.9 111.6" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <path
        className="st0"
        d="M39.3,36c-1.4-0.5-2.6-0.9-3.9-1.3c-5.9-1.9-11.7-3.9-17.6-5.8c-1.3-0.4-1.9-1.8-1.2-3
	c3.4-6.1,6.8-12.1,10.1-18.2c0.5-1,1.3-1.4,2.4-1.4c14,0,28,0,42,0c0.3,0,0.7,0,1,0c0.8,0,1.5,0.4,1.8,1.1c4.2,8,8.4,16,12.5,24.1
	c4,7.6,7.9,15.2,11.9,22.8c0.1,0.1,0.1,0.3,0.2,0.4c0.4,0.7,0.4,1.4,0.1,2.2c-5,9.6-10,19.2-15.1,28.8c-3.1,5.9-6.2,11.8-9.2,17.7
	c-0.1,0.2-0.2,0.5-0.3,0.7c-0.4,0.9-1.2,1.4-2.2,1.3c-0.3,0-0.6,0-0.8,0c-13.9,0-27.9,0-41.8,0c-1.2,0-2-0.4-2.6-1.5
	c-3.3-6.1-6.7-12.1-10.1-18.1c-0.7-1.2-0.1-2.6,1.3-3c4.3-1.4,8.7-2.9,13-4.3c2.5-0.8,5.1-1.7,7.6-2.5c0.2-0.1,0.3-0.1,0.6-0.2
	c-0.1-0.1-0.2-0.2-0.3-0.3c-6.5-5.9-13-11.8-19.5-17.7c-1.2-1.1-1.2-2.4,0-3.5c6.5-5.9,12.9-11.8,19.4-17.6
	C39,36.3,39.1,36.2,39.3,36z M23.7,55.8c0.1,0.1,0.3,0.2,0.4,0.3C30.5,62,37,67.9,43.4,73.8c0.2,0.2,0.4,0.2,0.7,0.1
	c1.6-0.6,3.2-1.3,4.8-1.8c0.4-0.1,0.5-0.3,0.5-0.7c0-10.3,0-20.7,0-31c0-0.4-0.1-0.6-0.5-0.7c-1.6-0.6-3.1-1.2-4.6-1.8
	c-0.5-0.2-0.7-0.1-1.1,0.2c-5.7,5.2-11.4,10.4-17.2,15.6C25.3,54.3,24.5,55,23.7,55.8z M91.1,55.9c0,0,0-0.1,0-0.1
	c-12.5-4.8-24.9-9.6-37.4-14.4c0,9.7,0,19.2,0,28.9C66.2,65.4,78.6,60.6,91.1,55.9z M66.8,10.5c0,0,0-0.1-0.1-0.1
	c-0.2,0-0.4,0-0.6,0c-2.1,0-4.3,0-6.4,0c-9.7,0-19.4,0-29.2,0c-0.3,0-0.6,0-0.8,0.4c-2.5,4.6-5,9.1-7.6,13.7
	c-0.1,0.1-0.1,0.3-0.2,0.4c0.2,0,0.4,0,0.6,0c9.4,0,18.9,0,28.3,0c0.3,0,0.6-0.2,0.8-0.4c4.2-3.9,8.4-7.8,12.6-11.6
	C65.2,12,66,11.3,66.8,10.5z M22.1,86.8c0.1,0.2,0.2,0.4,0.2,0.5c2.5,4.5,5,9,7.5,13.5c0.2,0.4,0.4,0.5,0.8,0.5
	c11.9,0,23.7,0,35.6,0c0.2,0,0.4,0,0.6,0c-0.1-0.2-0.2-0.2-0.3-0.3c-4.9-4.6-9.9-9.2-14.9-13.8c-0.2-0.2-0.5-0.3-0.8-0.3
	c-9.4,0-18.8,0-28.2,0C22.6,86.8,22.4,86.8,22.1,86.8z M55.3,26.8c12.1,7.7,24.1,15.3,36.1,23c0,0,0.1-0.1,0.1-0.1
	c-6.6-12.7-13.2-25.3-19.9-38C66.2,16.7,60.8,21.7,55.3,26.8z M55.3,84.8c5.5,5.1,10.9,10.1,16.3,15.2c6.6-12.7,13.2-25.4,19.9-38
	c0,0-0.1,0-0.1-0.1C79.4,69.4,67.4,77.1,55.3,84.8z M78,65.4C78,65.4,78,65.4,78,65.4c-0.1-0.1-0.2,0-0.3,0
	c-7.9,3-15.8,6.1-23.7,9.1c-0.3,0.1-0.4,0.3-0.4,0.6c0,1.7,0,3.5,0,5.2c0,0.1,0,0.3,0,0.5C61.8,75.7,69.9,70.6,78,65.4z M77.8,46.2
	c0,0,0-0.1,0-0.1c-8-5.1-16.1-10.2-24.2-15.4c0,2,0,3.8,0,5.6c0,0.5,0.1,0.7,0.6,0.9c7.1,2.7,14.2,5.5,21.4,8.2
	C76.3,45.6,77.1,45.9,77.8,46.2z M46.8,82.6c0,0,0.1-0.1,0.1-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-1.1-1-2.2-2-3.3-3.1
	c-0.3-0.3-0.5-0.3-0.8-0.2c-3.4,1.2-6.9,2.3-10.3,3.4c-0.1,0-0.2,0.1-0.5,0.2C36.7,82.6,41.8,82.6,46.8,82.6z M31.6,29
	c0.2,0.1,0.2,0.1,0.2,0.1c3.6,1.2,7.1,2.4,10.7,3.6c0.2,0.1,0.4,0,0.6-0.2c0.8-0.7,1.5-1.4,2.3-2.1c0.5-0.5,1-0.9,1.5-1.4
	C41.7,29,36.7,29,31.6,29z M49.5,79.3c0-1.1,0-2,0-3c-0.8,0.3-1.5,0.6-2.3,0.9C48,77.9,48.7,78.6,49.5,79.3z M49.5,32.2
	c-0.8,0.8-1.5,1.4-2.3,2.2c0.8,0.3,1.6,0.6,2.3,0.9C49.5,34.3,49.5,33.3,49.5,32.2z"
      />
    </svg>
  );

  const cSharp = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 136.7 170"
      style={{ enableBackground: "new 0 0 136.7 170" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g>
        <path
          className="st0"
          d="M173.8,30.1c1,0,1.7,0,2.4,0c30,0,59.9,0,89.9,0c8.5,0,17.1,0,25.6,0c2.9,0,2.9,0,2.7,2.8
		c-1.1,12.5-2.2,25-3.3,37.4c-1.2,13.2-2.3,26.3-3.5,39.5c-1.2,13-2.3,26-3.6,39.1c-0.4,4.7-0.5,4.7-5.1,6c-14.4,4-28.7,8-43.1,11.9
		c-1.1,0.3-2.4,0.3-3.5,0c-15.2-4.2-30.4-8.4-45.6-12.6c-1.3-0.4-2-0.8-2.2-2.4c-0.8-10.3-1.8-20.5-2.7-30.8
		c-1.2-13.1-2.4-26.2-3.5-39.3c-1-11.3-2-22.7-3-34c-0.5-5.3-0.9-10.5-1.4-15.8C173.9,31.4,173.8,30.9,173.8,30.1z M201.3,107.5
		c0,0.9,0,1.6,0,2.1c0.6,6.5,1.2,12.9,1.7,19.4c0.1,1.6,0.7,2.4,2.3,2.8c9.3,2.5,18.5,5.2,27.8,7.7c0.9,0.3,2,0.3,2.9,0
		c9.3-2.5,18.5-5.1,27.8-7.7c1.3-0.3,1.7-1,1.8-2.3c0.7-8.3,1.4-16.6,2.1-24.9c1.4-15.8,2.9-31.7,4.3-47.5c0.3-3.1,0.2-3.2-3-3.1
		c-22.2,0-44.3,0.1-66.5,0.1c-1.4,0-2.9,0.1-4.3,0c-1.5-0.1-2.1,0.4-1.9,2c0.4,3.6,0.7,7.3,1,10.9c0.1,1.6,0.7,2.2,2.4,2.2
		c18,0,35.9,0.1,53.9,0.2c1.4,0,1.8,0.4,1.7,1.7c-0.4,3.7-0.8,7.4-1,11.1c-0.1,2-0.9,2.4-2.8,2.4c-11.1,0-22.2,0.1-33.3,0.2
		c-0.6,0-1.2,0.1-2,0.2c0.4,4.3,0.8,8.4,1,12.5c0.1,1.8,0.8,2.5,2.7,2.5c10.1-0.1,20.2,0.1,30.3,0.1c1.8,0,2.4,0.5,2.2,2.3
		c-0.5,5.1-0.9,10.1-1.3,15.2c-0.1,1.3-0.7,2-2,2.4c-4.8,1.2-9.5,2.6-14.3,3.9c-0.6,0.1-1.2,0.1-1.8,0c-4.8-1.3-9.5-2.6-14.3-3.8
		c-1.1-0.3-1.5-0.9-1.6-2c-0.2-3-0.5-5.9-0.8-8.9c-0.1-0.6-0.8-1.5-1.3-1.6C210.5,107.4,206,107.5,201.3,107.5z"
        />
        <path
          className="st0"
          d="M258.9,2.9c1.1,1.2,1.8,2.1,2.6,2.9c1.9,2,3.6,4.2,5.7,6.1c2.2,1.9,1.8,4.3,1.7,6.7c0,0.3-0.8,0.9-1.2,0.9
		c-6.4,0.1-12.7,0.1-19.1,0.1c-0.1,0-0.3,0-0.4,0c-2-1.4-0.6-3.4-0.9-5.2c-0.1-0.4,0.9-1.3,1.4-1.3c2.7-0.1,5.4-0.1,8.6-0.1
		c-0.6-0.8-1-1.3-1.4-1.7c-2.5-2.7-5-5.6-7.6-8.2c-1.6-1.6-1-3.5-1-5.3c0-0.2,0.7-0.6,1-0.7c6.6,0,13.2,0,19.7,0
		c0.2,0.3,0.3,0.4,0.4,0.5c0.5,5.3,0.5,5.3-4.8,5.3C262.2,2.9,260.8,2.9,258.9,2.9z"
        />
        <path
          className="st0"
          d="M232.1,13c-2.9-3.2-5.3-6.1-8-8.6c-2.2-2-2.5-4.3-2-7.2c0.5-0.1,1.1-0.2,1.7-0.2c5.9,0,11.8,0,17.7,0
		c1.5,0,1.8,0.6,1.8,1.9c-0.1,4.9,0.3,3.8-3.8,3.9c-1.8,0-3.6,0-5.8,0c0.5,0.6,0.7,1.1,1,1.4c2.6,2.8,5.2,5.7,7.9,8.5
		c0.9,1,1.6,4.9,1,6.1c-0.2,0.3-0.7,0.6-1.1,0.6c-6.7,0-13.4,0-20.4,0c0-2-0.1-3.9,0.1-5.8c0-0.3,1-0.8,1.5-0.8
		C226.2,12.9,228.9,13,232.1,13z"
        />
        <path
          className="st0"
          d="M199.3-2.9c6.1,0,11.8,0,17.6,0.1c0.4,0,1.1,0.8,1.1,1.2c0.1,1.5,0.1,3,0.1,4.7c-3,0-5.7,0.1-8.5,0
		c-1.8-0.1-2.5,0.4-2.3,2.3c0.2,1.7,0.2,3.4,0,5.1c-0.2,2,0.7,2.6,2.6,2.5c2.6-0.1,5.3,0,8.2,0c0,2,0.1,3.8-0.1,5.6
		c0,0.4-0.8,1.1-1.2,1.1c-5.4,0.1-10.8,0.1-16.3,0c-0.4,0-1.1-0.7-1.1-1C199.3,11.5,199.3,4.4,199.3-2.9z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M0.2,83.6c0-10.2,0.1-20.4,0-30.6c-0.1-5.9,2.3-10,7.4-13C25.4,29.9,43,19.7,60.7,9.4c5-2.9,10-2.9,15,0
		c17.8,10.3,35.5,20.5,53.3,30.7c5,2.9,7.3,7.1,7.3,12.8c0,20.5,0,41,0,61.5c0,5.9-2.6,10.1-7.8,13c-17.3,9.8-34.6,19.7-51.8,29.8
		c-5.9,3.5-11.3,3.4-17.2-0.1c-16.8-9.9-33.7-19.6-50.7-29.2c-6.1-3.4-8.9-8-8.7-15C0.5,103.2,0.2,93.4,0.2,83.6z M87.9,94.7
		c-4,6.2-9.3,10.2-16.5,11.1c-7.1,0.9-13.4-0.9-18.7-6c-8.9-8.5-9.3-22.6-0.9-31.7c4.2-4.5,9.5-6.9,15.6-7.1
		c8.7-0.2,15.4,3.8,20.3,11.1c6.6-3.8,12.9-7.4,19.2-11.1c-7.4-14.4-26.5-25.8-47.3-21.8c-20.3,4-36.4,21.3-36.5,44.6
		c-0.1,20.8,15.6,39.8,36,43.8c22,4.3,40.7-7.8,47.9-21.9C100.7,102.2,94.4,98.5,87.9,94.7z M119.6,84.5c-0.1-0.4-0.2-0.6-0.2-0.8
		c0.7-2.3-0.6-6,4-5.7c0.2,0,0.7-1,0.7-1.6c0-0.5-0.5-1.5-0.8-1.5c-2.7-0.2-2.2-1.9-1.9-3.6c0.2-1,0.7-2.2-1.1-2.4
		c-1.6-0.2-2.2,0.6-2.5,2.1c-0.7,3.9-1.2,4.2-5,3.8c-1.3-0.1-1.8-0.7-1.5-2c0.2-1.2,0.3-2.4,0.5-3.6c-2.9-1.1-3.7,0.4-3.9,2.6
		c-0.1,1.8-0.6,3-2.8,3c-0.6,0-1.2,1.1-1.9,1.6c0.6,0.6,1.2,1.2,1.9,1.7c0.4,0.3,0.9,0.3,1.5,0.4c-0.2,1.2-0.3,2.2-0.4,3.3
		c-0.2,1.8-0.7,3.3-3,3.1c-0.4,0-1.3,1-1.3,1.5c0,0.6,0.7,1.6,1.2,1.7c1.9,0.2,1.8,1.4,1.6,2.7c-0.1,1-0.2,2.1-0.3,3
		c2.3,0.8,3.4,0.2,3.8-2c0.6-3.8,0.7-3.8,4.7-3.6c1.5,0.1,2.1,0.6,1.8,2.1c-0.2,1.2-0.3,2.4-0.4,3.6c3.3,0.4,3.4,0.4,3.8-2.8
		c0.3-1.9,0.7-3.1,3-3c0.5,0,1.5-1,1.6-1.5c0-0.6-0.8-1.3-1.4-1.7C120.9,84.5,120.2,84.6,119.6,84.5z"
        />
        <path
          className="st0"
          d="M109.7,84.5c0.4-7,0.3-7,6.7-6.1C116.2,85.7,115.9,85.2,109.7,84.5z"
        />
      </g>
    </svg>
  );

  const css = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 136.7 170"
      style={{ enableBackground: "new 0 0 136.7 170" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g>
        <path
          className="st0"
          d="M7.8,33.1c1,0,1.7,0,2.4,0c30,0,59.9,0,89.9,0c8.5,0,17.1,0,25.6,0c2.9,0,2.9,0,2.7,2.8
		c-1.1,12.5-2.2,25-3.3,37.4c-1.2,13.2-2.3,26.3-3.5,39.5c-1.2,13-2.3,26-3.6,39.1c-0.4,4.7-0.5,4.7-5.1,6c-14.4,4-28.7,8-43.1,11.9
		c-1.1,0.3-2.4,0.3-3.5,0c-15.2-4.2-30.4-8.4-45.6-12.6c-1.3-0.4-2-0.8-2.2-2.4c-0.8-10.3-1.8-20.5-2.7-30.8
		c-1.2-13.1-2.4-26.2-3.5-39.3c-1-11.3-2-22.7-3-34c-0.5-5.3-0.9-10.5-1.4-15.8C7.9,34.4,7.8,33.9,7.8,33.1z M35.3,110.5
		c0,0.9,0,1.6,0,2.1c0.6,6.5,1.2,12.9,1.7,19.4c0.1,1.6,0.7,2.4,2.3,2.8c9.3,2.5,18.5,5.2,27.8,7.7c0.9,0.3,2,0.3,2.9,0
		c9.3-2.5,18.5-5.1,27.8-7.7c1.3-0.3,1.7-1,1.8-2.3c0.7-8.3,1.4-16.6,2.1-24.9c1.4-15.8,2.9-31.7,4.3-47.5c0.3-3.1,0.2-3.2-3-3.1
		c-22.2,0-44.3,0.1-66.5,0.1c-1.4,0-2.9,0.1-4.3,0c-1.5-0.1-2.1,0.4-1.9,2c0.4,3.6,0.7,7.3,1,10.9c0.1,1.6,0.7,2.2,2.4,2.2
		c18,0,35.9,0.1,53.9,0.2c1.4,0,1.8,0.4,1.7,1.7c-0.4,3.7-0.8,7.4-1,11.1c-0.1,2-0.9,2.4-2.8,2.4c-11.1,0-22.2,0.1-33.3,0.2
		c-0.6,0-1.2,0.1-2,0.2c0.4,4.3,0.8,8.4,1,12.5c0.1,1.8,0.8,2.5,2.7,2.5c10.1-0.1,20.2,0.1,30.3,0.1c1.8,0,2.4,0.5,2.2,2.3
		c-0.5,5.1-0.9,10.1-1.3,15.2c-0.1,1.3-0.7,2-2,2.4c-4.8,1.2-9.5,2.6-14.3,3.9c-0.6,0.1-1.2,0.1-1.8,0c-4.8-1.3-9.5-2.6-14.3-3.8
		c-1.1-0.3-1.5-0.9-1.6-2c-0.2-3-0.5-5.9-0.8-8.9c-0.1-0.6-0.8-1.5-1.3-1.6C44.5,110.4,40,110.5,35.3,110.5z"
        />
        <path
          className="st0"
          d="M92.9,5.9C94,7.2,94.7,8,95.5,8.8c1.9,2,3.6,4.2,5.7,6.1c2.2,1.9,1.8,4.3,1.7,6.7c0,0.3-0.8,0.9-1.2,0.9
		c-6.4,0.1-12.7,0.1-19.1,0.1c-0.1,0-0.3,0-0.4,0c-2-1.4-0.6-3.4-0.9-5.2c-0.1-0.4,0.9-1.3,1.4-1.3c2.7-0.1,5.4-0.1,8.6-0.1
		c-0.6-0.8-1-1.3-1.4-1.7c-2.5-2.7-5-5.6-7.6-8.2c-1.6-1.6-1-3.5-1-5.3c0-0.2,0.7-0.6,1-0.7c6.6,0,13.2,0,19.7,0
		c0.2,0.3,0.3,0.4,0.4,0.5c0.5,5.3,0.5,5.3-4.8,5.3C96.2,5.9,94.8,5.9,92.9,5.9z"
        />
        <path
          className="st0"
          d="M66.1,16c-2.9-3.2-5.3-6.1-8-8.6c-2.2-2-2.5-4.3-2-7.2c0.5-0.1,1.1-0.2,1.7-0.2c5.9,0,11.8,0,17.7,0
		C77,0,77.3,0.6,77.3,2c-0.1,4.9,0.3,3.8-3.8,3.9c-1.8,0-3.6,0-5.8,0c0.5,0.6,0.7,1.1,1,1.4c2.6,2.8,5.2,5.7,7.9,8.5
		c0.9,1,1.6,4.9,1,6.1c-0.2,0.3-0.7,0.6-1.1,0.6c-6.7,0-13.4,0-20.4,0c0-2-0.1-3.9,0.1-5.8c0-0.3,1-0.8,1.5-0.8
		C60.2,15.9,62.9,16,66.1,16z"
        />
        <path
          className="st0"
          d="M33.3,0.1c6.1,0,11.8,0,17.6,0.1c0.4,0,1.1,0.8,1.1,1.2c0.1,1.5,0.1,3,0.1,4.7c-3,0-5.7,0.1-8.5,0
		c-1.8-0.1-2.5,0.4-2.3,2.3c0.2,1.7,0.2,3.4,0,5.1c-0.2,2,0.7,2.6,2.6,2.5c2.6-0.1,5.3,0,8.2,0c0,2,0.1,3.8-0.1,5.6
		c0,0.4-0.8,1.1-1.2,1.1c-5.4,0.1-10.8,0.1-16.3,0c-0.4,0-1.1-0.7-1.1-1C33.3,14.5,33.3,7.4,33.3,0.1z"
        />
      </g>
    </svg>
  );

  const ios = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 106.9 111.6"
      style={{ enableBackground: "new 0 0 106.9 111.6" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g>
        <path
          className="st0"
          d="M78.2,104.8c-16.5,0-33,0-49.4,0c-0.2-0.1-0.5-0.2-0.7-0.2c-11.2-1.3-20.5-9.7-22.9-20.7
   c-0.2-1.1-0.4-2.2-0.7-3.4c0-16.5,0-33,0-49.4c0.1-0.2,0.2-0.5,0.2-0.7c1.7-10.8,7.6-18.2,17.8-22c2-0.7,4.1-1,6.2-1.5
   c16.5,0,33,0,49.4,0C78.4,6.9,78.7,7,78.9,7c10.5,1.2,19.5,8.8,22.4,19c0.5,1.7,0.7,3.4,1.1,5.1c0,16.5,0,33,0,49.4
   c-0.1,0.2-0.2,0.4-0.2,0.6c-1.7,11.2-7.9,18.7-18.6,22.4C81.9,104.2,80,104.4,78.2,104.8z M98,55.8c0-7.4,0-14.8,0-22.2
   c0-12.6-9.8-22.4-22.4-22.4c-14.8,0-29.5,0-44.3,0c-12.6,0-22.4,9.8-22.4,22.4c0,14.7,0,29.5,0,44.2c0,1.4,0.1,2.8,0.4,4.1
   c2.1,11,11,18.4,22.2,18.4c14.7,0,29.3,0,44,0c1.4,0,2.8-0.1,4.1-0.3C90.6,97.9,98,89,98,77.8C98,70.4,98,63.1,98,55.8z"
        />
        <path
          className="st0"
          d="M59.9,56.4c0.1,2.5-0.3,5-1.3,7.4c-2,4.6-5.5,7-10.5,7.4c-4.8,0.4-9-1-11.9-5.1c-1.3-1.9-2-4-2.4-6.3
   c-0.5-3-0.3-6.1,0.5-9c1.8-6,6.7-9.5,13-9.1c7.3,0.4,11.9,5.1,12.5,12.6C59.9,55,59.9,55.7,59.9,56.4z M55.7,56.7
   c-0.3-1.7-0.4-3.2-0.7-4.5c-1.1-4-4-6.3-7.8-6.4c-4.1-0.1-7.1,2.1-8.3,6c-1,3.2-1,6.4,0.1,9.6c1.2,3.6,4.1,5.7,7.8,5.7
   c3.7,0,6.6-1.9,7.9-5.5C55.2,60,55.4,58.2,55.7,56.7z"
        />
        <path
          className="st0"
          d="M85.8,49.7c-1.4,0-2.8,0-4.1,0c-1.8-3.8-5.1-5.2-9-3.7c-0.9,0.4-1.9,1.1-2.4,2c-1.1,1.7-0.4,3.7,1.5,4.6
   c1.7,0.7,3.5,1.1,5.3,1.7c1.6,0.5,3.1,0.9,4.7,1.4c6.4,2.5,5.3,10.5,1.6,13.2c-1.6,1.2-3.4,1.9-5.3,2.1c-2.9,0.4-5.8,0.3-8.5-1
   c-2.9-1.4-4.6-3.7-4.9-7.1c1.4,0,2.6,0,3.9,0c0.2,0,0.4,0.2,0.5,0.3c0.8,2.9,3.1,3.7,5.7,4c1.8,0.2,3.6-0.1,5.2-1.1
   c2.7-1.7,2.6-5.1-0.3-6.4c-2.2-0.9-4.5-1.3-6.8-2.1c-1.4-0.4-2.8-0.8-4-1.6c-4.2-2.4-4.6-8.7-0.9-12c3.8-3.4,11.4-3.5,15.3,0
   C84.7,45.7,85.7,47.4,85.8,49.7z"
        />
        <path
          className="st0"
          d="M21.6,70.5c0-7.1,0-14.1,0-21.2c1.4,0,2.7,0,4.1,0c0,7.1,0,14.1,0,21.2C24.3,70.5,23,70.5,21.6,70.5z"
        />
        <path
          className="st0"
          d="M23.7,40.4c1.5,0,2.6,1.1,2.6,2.6c0,1.5-1.2,2.6-2.8,2.6c-1.5,0-2.6-1.2-2.6-2.6
   C20.9,41.4,22.1,40.4,23.7,40.4z"
        />
      </g>
    </svg>
  );

  const java = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 82.6 111.6"
      style={{ enableBackground: "new 0 0 82.6 111.6" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g>
        <g>
          <path
            className="st0"
            d="M53,0c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.5,0.7,1.1,0.9,1.7c0.8,2.7,1.1,5.3,0.9,8.1c-0.3,3.1-1.4,6-3.2,8.5
			c-0.9,1.3-2,2.4-3.1,3.5c-2.7,2.6-5.4,5.2-8.1,7.8c-1.7,1.6-3.2,3.3-4.7,5.1c-1.1,1.3-2,2.7-2.7,4.2c-0.7,1.5-1.1,3-0.8,4.6
			c0.2,0.9,0.4,1.7,0.7,2.6c0.8,2.5,2,4.8,3.4,7.1c1.2,2,2.4,4,3.8,5.9c0.2,0.2,0.3,0.5,0.5,0.7c0,0,0,0.1,0,0.1
			c-0.1-0.1-0.2-0.1-0.2-0.2c-3.5-3.5-6.8-7.1-9.7-11c-1.3-1.8-2.5-3.6-3.5-5.6c-1-2-1-4-0.1-6.1c0.7-1.6,1.7-3,2.8-4.3
			c1.5-1.8,3.2-3.5,5-5c3.3-2.8,6.5-5.5,9.8-8.2c1.7-1.4,3.3-2.9,4.6-4.7c2.8-3.8,4.1-8.1,3.8-12.9c0-0.6-0.2-1.2-0.2-1.8
			c0-0.2,0-0.3,0-0.5C52.9,0,52.9,0,53,0z"
          />
          <path
            className="st0"
            d="M76.1,95.6c0.6,0.3,0.9,0.7,1.2,1.1c0.3,0.5,0.3,1-0.1,1.4c-0.2,0.3-0.5,0.6-0.8,0.9c-1,0.8-2.1,1.4-3.3,2
			c-2.3,1.1-4.8,1.8-7.3,2.3c-3.1,0.7-6.3,1.2-9.5,1.5c-2.6,0.2-5.2,0.4-7.8,0.6c-4.7,0.3-9.4,0.4-14.1,0.4c-2.5,0-5.1,0-7.6-0.1
			c-3.5-0.1-7.1-0.3-10.6-0.6c-3.1-0.3-6.1-0.6-9.1-1.1c-1.5-0.3-2.9-0.6-4.4-1.1c-0.4-0.2-0.8-0.3-1.2-0.6
			c-0.3-0.2-0.7-0.4-0.9-0.7c-0.4-0.4-0.5-0.9-0.3-1.5c0.3-0.7,0.7-1.3,1.3-1.8c1-0.8,2.1-1.3,3.2-1.7c2.6-1,5.3-1.6,8.1-1.9
			c0.9-0.1,1.8-0.2,2.8-0.3c0.5,0,1.1,0,1.6,0.1c-0.7,0.1-1.5,0.3-2.2,0.4c-2,0.4-4,0.8-5.9,1.7c-0.5,0.2-0.9,0.6-1.4,0.9
			c-0.1,0.1-0.3,0.3-0.4,0.4c-0.4,0.6-0.4,1.1,0.2,1.5c0.5,0.4,1.1,0.6,1.7,0.8c1.6,0.5,3.2,0.8,4.8,1c2,0.3,4,0.5,6.1,0.8
			c1.4,0.2,2.8,0.3,4.1,0.4c1.5,0.1,3.1,0.3,4.6,0.3c3.6,0,7.1,0,10.7-0.1c1.7,0,3.3-0.1,5-0.1c2.4-0.1,4.9-0.2,7.3-0.4
			c2-0.1,4-0.3,6-0.4c4.3-0.4,8.6-0.8,12.8-1.8c1.3-0.3,2.6-0.6,3.8-1.2c0.5-0.2,0.9-0.5,1.3-0.8c0.7-0.5,0.8-1.1,0.6-1.9
			C76.3,95.9,76.2,95.8,76.1,95.6z"
          />
          <path
            className="st0"
            d="M45.5,64.5c0.2-0.3,0.4-0.6,0.6-0.9c0.7-1,1.3-2,1.9-3.1c0.9-1.7,0.9-3.4,0-5.1c-0.4-0.8-0.9-1.5-1.3-2.2
			c-0.9-1.3-1.8-2.5-2.7-3.8c-1.2-1.9-2-3.9-2.3-6.2c-0.3-2.2,0.1-4.2,1.1-6.1c1-2.2,2.6-3.9,4.4-5.4c2.4-2,5.1-3.7,7.9-5
			c3.3-1.6,6.7-2.9,10.2-4c0.2,0,0.3-0.1,0.5-0.1c0,0,0,0.1,0.1,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c-2.2,1.4-4.4,2.8-6.6,4.3
			c-2.6,1.8-5.1,3.7-7.3,6c-1.1,1.1-2,2.3-2.8,3.7c-0.5,0.9-0.8,1.9-0.9,3c0,0.9,0.1,1.7,0.5,2.5c0.5,0.9,1,1.8,1.5,2.7
			c0.8,1.3,1.6,2.5,2.4,3.8c0.7,1.2,1.2,2.5,1.5,3.8c0.3,1.5,0.2,2.8-0.5,4.2c-0.8,1.7-1.9,3-3.3,4.2c-1.3,1.2-2.6,2.2-4.1,3.1
			c-0.1,0.1-0.2,0.1-0.4,0.2C45.7,64.5,45.6,64.5,45.5,64.5z"
          />
          <path
            className="st0"
            d="M25.8,86.4c-0.1,0.2-0.2,0.3-0.2,0.4c-0.4,0.7-0.3,1.3,0.4,1.7c0.6,0.5,1.3,0.7,2.1,0.8
			c0.8,0.1,1.6,0.2,2.5,0.3c3.2,0.2,6.4,0.2,9.6,0.1c3.3-0.1,6.6-0.3,9.9-0.9c0.7-0.1,1.4-0.3,2-0.4c0.5-0.1,0.9-0.1,1.3,0.2
			c1.7,1,3.4,2,5.2,2.8c0,0,0,0,0.1,0.1c-0.2,0.1-0.3,0.2-0.5,0.2c-1.1,0.3-2.2,0.7-3.2,1c-3.1,0.9-6.2,1.6-9.3,2
			c-2.1,0.3-4.2,0.4-6.3,0.3c-3.6-0.1-7.2-0.3-10.8-1c-1.4-0.3-2.8-0.7-4.2-1.2c-0.7-0.3-1.4-0.6-2-1.1c-0.3-0.2-0.5-0.5-0.7-0.8
			C21,90,21.1,89,22,88.2c1-1,2.3-1.5,3.7-1.8C25.7,86.3,25.7,86.4,25.8,86.4z"
          />
          <path
            className="st0"
            d="M23.3,74.3c-0.1,0.2-0.3,0.3-0.4,0.5c-0.9,1.1-0.7,2,0.6,2.7c0.8,0.4,1.7,0.6,2.6,0.8
			c1.9,0.3,3.8,0.4,5.6,0.4c2.6,0.1,5.2,0,7.8-0.1c2.6-0.1,5.2-0.4,7.7-0.6c2.7-0.2,5.4-0.6,8.1-1.1c0.6-0.1,1,0,1.5,0.4
			c0.8,0.7,1.7,1.3,2.6,1.8c0.3,0.1,0.6,0.3,1.1,0.4c-0.3,0.1-0.6,0.2-0.8,0.3c-3.6,1.1-7.2,1.9-10.9,2.5c-3.8,0.5-7.6,0.7-11.4,0.6
			c-1.7-0.1-3.3-0.1-5-0.2c-3.4-0.2-6.8-0.5-10.1-1.3c-1-0.2-2-0.6-2.9-1c-0.4-0.2-0.7-0.4-1-0.7c-0.5-0.4-0.6-1-0.5-1.6
			c0.2-0.7,0.5-1.3,1-1.8c1.2-1.1,2.6-1.6,4.1-1.9C23.1,74.2,23.2,74.2,23.3,74.3C23.2,74.2,23.2,74.2,23.3,74.3z"
          />
          <path
            className="st0"
            d="M64.6,65.4c-0.1,0.1-0.3,0.2-0.4,0.3c-1.2,0.7-2.4,1.4-3.5,2.2c-0.3,0.2-0.7,0.3-1.1,0.4
			c-3.4,0.5-6.9,1-10.3,1.4c-2.2,0.3-4.4,0.5-6.6,0.7c-3.2,0.3-6.4,0.5-9.7,0.5c-2.4,0-4.8-0.1-7.2-0.2c-1.4-0.1-2.8-0.1-4.2-0.2
			c-2.8-0.2-5.7-0.5-8.5-1c-0.9-0.2-1.7-0.4-2.5-0.7c-0.4-0.1-0.8-0.3-1.1-0.5c-0.6-0.4-0.6-0.9-0.2-1.4c0.4-0.4,0.8-0.7,1.2-0.9
			c1.3-0.8,2.8-1.4,4.3-1.9c2.8-1,5.7-1.8,8.7-2.2c1-0.2,2.1-0.3,3.2-0.4c0.2,0,0.4,0,0.6,0.1c-0.2,0-0.3,0.1-0.5,0.1
			c-2.1,0.6-4.2,1.2-6.2,2c-0.7,0.3-1.4,0.6-2.1,1c-0.4,0.2-0.7,0.5-1,0.8c-0.5,0.5-0.4,1,0.3,1.4c0.3,0.2,0.7,0.3,1.1,0.4
			c1.3,0.4,2.7,0.5,4.1,0.6c4.6,0.4,9.2,0.5,13.8,0.4c2,0,4.1-0.2,6.1-0.3c1.5-0.1,3-0.2,4.5-0.3c1.8-0.2,3.7-0.3,5.5-0.5
			c2.1-0.2,4.1-0.5,6.2-0.8c1.7-0.2,3.4-0.5,5.1-0.8C64.2,65.3,64.4,65.3,64.6,65.4C64.6,65.3,64.6,65.3,64.6,65.4z"
          />
          <path
            className="st0"
            d="M82.5,101.2c-0.1,0.6-0.5,1.1-0.8,1.6c-0.7,0.9-1.5,1.6-2.5,2.2c-2.5,1.6-5.2,2.7-8.1,3.5
			c-2.5,0.7-5.1,1.1-7.7,1.5c-2.4,0.3-4.8,0.6-7.2,0.9c-2,0.2-4,0.3-6.1,0.4c-2.6,0.1-5.2,0.2-7.7,0.3c-1.9,0-3.7,0-5.6-0.1
			c-1.4,0-2.9-0.1-4.3-0.3c-2.3-0.2-4.6-0.4-6.9-0.7c-3.1-0.4-6.1-1.1-9.1-2c-0.2,0-0.3-0.1-0.5-0.2c0,0,0-0.1-0.1-0.1
			c0.4,0,0.7,0.1,1,0.1c2.1,0.2,4.3,0.4,6.4,0.6c1.5,0.1,3.1,0.2,4.6,0.3c3,0.1,6.1,0.3,9.1,0.3c2.2,0.1,4.4,0.1,6.6,0.1
			c2.8,0,5.6-0.1,8.3-0.3c5-0.3,9.9-0.8,14.8-1.8c3.1-0.6,6.1-1.4,9-2.6c2.3-0.9,4.4-2,6.3-3.5c0.1-0.1,0.2-0.2,0.4-0.3
			C82.4,101.2,82.4,101.2,82.5,101.2z"
          />
          <path
            className="st0"
            d="M65.6,81.7c0.2-0.1,0.4-0.3,0.6-0.4c1.9-1.2,3.8-2.4,5.5-3.9c1.7-1.4,3.3-2.9,4.5-4.7c0.9-1.2,1.5-2.5,1.8-4
			c0.3-2.2-0.2-4.1-1.8-5.7c-0.9-0.8-1.9-1.3-3.1-1.5c-1.6-0.3-3.3-0.3-5,0c0.1-0.1,0.2-0.2,0.3-0.3c0.8-0.6,1.7-0.8,2.6-1
			c2.6-0.5,5-0.3,7.3,1c1.4,0.8,2.5,1.8,3.3,3.1c1.3,2.3,1.2,4.7,0,7.1c-0.9,1.7-2.2,3.1-3.6,4.3c-2.7,2.2-5.7,3.7-8.9,4.9
			c-1,0.4-2,0.7-3,1C66,81.8,65.8,81.8,65.6,81.7C65.6,81.8,65.6,81.8,65.6,81.7z"
          />
        </g>
      </g>
    </svg>
  );

  const kotlin = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 136.7 137"
      style={{ enableBackground: "new 0 0 136.7 137" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g>
        <g>
          <path
            className="st0"
            d="M135.6,0.2c-5,5.4-9.7,10.8-14.8,15.8c-16.9,16.5-34,32.9-51,49.3c-1.8,1.7-1.4,2.9,0.1,4.4
			c17.2,17,34.3,34.1,51.4,51.2c4.5,4.5,9,8.9,13.5,13.4c0.6,0.6,1,1.3,1.9,2.4c-1.6,0.1-2.7,0.3-3.8,0.3c-42.8,0-85.5,0-128.3,0
			C0.1,137,0,137,0,132.4C0,89.8,0,47.2,0,4.7C0,0.1,0.1,0,4.6,0c42.7,0,85.3,0,128,0.1C133.7,0.1,134.8,0.2,135.6,0.2z"
          />
        </g>
      </g>
    </svg>
  );

  const maui = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 136.7 47.8"
      style={{ enableBackground: "new 0 0 136.7 47.8" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g>
        <path
          className="st0"
          d="M20.9,27.5c0.3,0.7,0.6,1.5,0.9,2.2c0.3-0.8,0.6-1.5,1-2.3c0.3-0.7,0.7-1.5,1-2.1l10-19.7
		C34,5.3,34.2,5,34.4,4.8c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.5-0.1,0.8-0.1c0.3,0,0.6,0,1,0h6.9v38.8h-7.9V20.8
		c0-1.1,0.1-2.3,0.2-3.5l-10.3,20c-0.3,0.6-0.8,1.1-1.3,1.4c-0.5,0.3-1.2,0.5-1.9,0.5h-1.2c-0.7,0-1.3-0.2-1.9-0.5
		c-0.5-0.3-1-0.8-1.3-1.4l-10.3-20c0.1,0.6,0.1,1.2,0.2,1.9c0,0.6,0.1,1.2,0.1,1.7v22.4H0V4.3h6.9c0.4,0,0.7,0,1,0s0.6,0,0.8,0.1
		c0.2,0.1,0.4,0.2,0.6,0.4C9.5,5,9.7,5.3,9.9,5.6l10,19.7C20.2,26,20.6,26.7,20.9,27.5z"
        />
        <path
          className="st0"
          d="M86.3,43.1h-7c-0.8,0-1.4-0.2-1.9-0.5c-0.5-0.4-0.9-0.8-1.1-1.4L74,34.4H59.2l-2.3,6.8c-0.2,0.5-0.5,0.9-1,1.3
		s-1.1,0.6-1.9,0.6h-7L62,4.3h9.2L86.3,43.1z M61.3,28.3h10.6l-3.6-10.6c-0.2-0.7-0.5-1.5-0.8-2.4c-0.3-0.9-0.6-1.9-0.9-3
		c-0.3,1.1-0.6,2.1-0.9,3c-0.3,0.9-0.6,1.7-0.9,2.4L61.3,28.3z"
        />
        <path
          className="st0"
          d="M104.2,36c1.2,0,2.3-0.2,3.2-0.6c1-0.4,1.8-1,2.4-1.7c0.7-0.7,1.2-1.7,1.5-2.7c0.3-1.1,0.5-2.3,0.5-3.7v-23h9
		v23c0,2.4-0.4,4.6-1.2,6.6c-0.8,2-1.9,3.7-3.3,5.1c-1.4,1.4-3.2,2.6-5.3,3.4c-2.1,0.8-4.4,1.2-7,1.2c-2.6,0-4.9-0.4-7-1.2
		c-2.1-0.8-3.8-1.9-5.3-3.4c-1.4-1.4-2.5-3.2-3.3-5.1c-0.8-2-1.1-4.2-1.1-6.6v-23h9v23c0,1.4,0.2,2.6,0.5,3.7c0.3,1.1,0.8,2,1.5,2.7
		c0.7,0.8,1.5,1.3,2.4,1.7C101.9,35.8,103,36,104.2,36z"
        />
        <path className="st0" d="M136.7,43.1h-9.1V4.3h9.1V43.1z" />
      </g>
    </svg>
  );

  const ps = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 106.9 111.6"
      style={{ enableBackground: "new 0 0 106.9 111.6" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g id="IB4kX4.tif">
        <g>
          <path
            className="st0"
            d="M17.7,3.7c23.8,0,47.6,0,71.4,0c0.1,0,0.3,0.1,0.4,0.1c0.7,0.1,1.5,0.2,2.2,0.3c8.9,1.9,15.1,9.5,15.1,18.6
			c0,22.1,0,44.2,0,66.3c0,2.6-0.5,5.2-1.6,7.6c-3.5,7.4-9.4,11.3-17.5,11.4c-22.9,0.1-45.8,0-68.6,0c-2.8,0-5.6-0.5-8.1-1.8
			c-5.8-2.8-9.3-7.3-10.6-13.6C0.2,91.7,0.1,91,0,90.2c0-22.9,0-45.8,0-68.8c0-0.1,0.1-0.2,0.1-0.3c0.2-3.2,1.2-6.1,3-8.7
			C6,7.9,10.1,5.1,15.5,4C16.2,3.9,17,3.8,17.7,3.7z M24.1,53.9c0,7.6,0,15.1,0,22.7c0,0.7,0.1,0.8,0.8,0.8c2.7,0,5.3,0,8,0
			c0.7,0,0.9-0.1,0.9-0.8c0-4.5,0-8.9,0-13.4c0-0.7,0-0.7,0.7-0.7c1.2,0,2.4,0.1,3.6,0.1c3.3,0,6.6-0.3,9.7-1.4
			c4.2-1.5,7.3-4.2,8.9-8.4c1.2-3.1,1.4-6.3,0.8-9.6c-0.6-3.3-2.1-6.1-4.6-8.3c-2.1-1.9-4.7-3-7.4-3.7c-2.3-0.6-4.7-0.8-7.2-0.7
			c-4.5,0.1-8.9,0.2-13.4,0.3c-0.8,0-0.8,0-0.8,0.9C24.1,38.9,24.1,46.4,24.1,53.9z M62.5,71.6c0,1.2,0,2.5,0,3.7
			c0,0.5,0.2,0.8,0.6,0.9c0.7,0.3,1.5,0.6,2.2,0.8c3.3,1,6.6,1.2,10,1.1c2.3-0.1,4.5-0.5,6.6-1.5c4.7-2.1,7-6.5,6.3-11.4
			c-0.4-2.9-2-5-4.4-6.6c-1.9-1.2-4-2.2-6.1-3c-1.3-0.5-2.6-1.1-3.8-1.7c-0.5-0.2-1-0.6-1.4-1c-1.1-1.1-0.7-2.9,0.8-3.4
			c0.6-0.2,1.3-0.3,2-0.4c2.5-0.1,5,0.2,7.4,0.9c0.9,0.3,1.8,0.7,2.7,1.1c0.6,0.2,1,0.1,1-0.4c0-2.4,0-4.9,0-7.3
			c0-0.4-0.2-0.7-0.6-0.8c-0.9-0.3-1.8-0.6-2.8-0.8c-3.3-0.6-6.5-0.8-9.8-0.4c-2.6,0.3-5,1.3-7.1,3C62,48,61.1,55,65.4,59.3
			c1.4,1.4,3,2.4,4.8,3.2c1.8,0.8,3.6,1.5,5.4,2.3c0.8,0.4,1.6,0.8,2.3,1.4c0.5,0.4,0.8,0.9,0.8,1.6c0,1-0.6,1.7-1.7,2.1
			c-1.2,0.4-2.4,0.5-3.7,0.4c-3.6-0.1-7-1-10.2-2.8c-0.5-0.3-0.7-0.2-0.7,0.4C62.5,69.1,62.5,70.4,62.5,71.6z"
          />
          <path
            className="st0"
            d="M33.8,54c0-5,0-10,0-15c0.3,0,0.5-0.1,0.8-0.1c1.7,0,3.4,0,5.2,0c1.6,0,3.1,0.4,4.6,1c2.4,1.1,3.5,3,3.8,5.6
			c0.1,1.1,0,2.2-0.3,3.3c-0.7,2.2-2.3,3.6-4.4,4.3c-1.8,0.7-3.6,0.9-5.5,0.9c-1,0-2,0-2.9,0C34.5,54.1,34.2,54.1,33.8,54z"
          />
        </g>
      </g>
    </svg>
  );

  const react = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 106.9 111.6"
      style={{ enableBackground: "new 0 0 106.9 111.6" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g id="_x34_KNZUd.tif_5_">
        <g>
          <path
            className="st0"
            d="M0.7,56.9c0.2-0.7,0.4-1.4,0.6-2c1.1-3.3,3.4-5.6,6-7.6c4.2-3.2,9-5.1,14-6.7c0.5-0.2,1-0.3,1.5-0.5
			c-0.4-1.9-0.8-3.7-1-5.5c-0.8-5.3-1.2-10.7,0.5-15.9c1.4-4.2,4-7.1,8.6-7.4c2.6-0.2,5,0.4,7.4,1.4c5,2,9.3,5.1,13.3,8.7
			c0.4,0.4,0.9,0.8,1.3,1.2c0,0,0.1,0.1,0.1,0.1c2.3-1.9,4.6-3.8,7-5.6c3.7-2.7,7.7-4.9,12.3-5.6c4.7-0.8,8.4,1.1,10.5,5.3
			c1.4,2.7,1.9,5.7,2.1,8.7c0.3,4.7-0.4,9.4-1.4,14c-0.1,0.2-0.1,0.5-0.2,0.8c1.5,0.5,3,1,4.5,1.5c4.6,1.7,9.1,3.9,12.8,7.2
			c1.9,1.8,3.6,3.8,4.2,6.3c1,3.7-0.4,6.8-2.9,9.5c-3.4,3.8-7.7,6.2-12.4,8.1c-2,0.8-4,1.5-6.1,2.3c0.3,1.8,0.7,3.6,1,5.4
			c0.7,4.8,0.9,9.7-0.3,14.5c-0.1,0.6-0.3,1.1-0.5,1.7c-2.2,6.1-6.7,8.5-13,7c-5.1-1.2-9.4-4.1-13.4-7.3c-1.4-1.1-2.7-2.3-4.1-3.5
			c-1.7,1.4-3.3,2.8-5,4.1c-3.9,3-8,5.5-12.9,6.6c-7.2,1.6-11.9-1.3-13.6-8.5c-1.2-4.8-0.9-9.6-0.1-14.4c0.3-1.8,0.7-3.6,1-5.4
			c-2-0.7-4-1.4-5.9-2.2c-4-1.6-7.8-3.7-11-6.6c-2.4-2.2-4.4-4.8-4.9-8.1c0-0.1-0.1-0.1-0.2-0.2C0.7,57.7,0.7,57.3,0.7,56.9z
			 M54.8,41c0-0.1,0-0.2,0-0.3c-3.4,0.1-6.7,0.2-10.1,0.4c-0.5,0-1.2,0.3-1.4,0.7c-3,5-5.9,10.1-8.8,15.2c-0.2,0.4-0.2,1,0,1.4
			c2.9,5.1,5.9,10.2,8.8,15.2c0.2,0.3,0.7,0.6,1,0.6c5.9,0,11.8,0,17.6,0c0.4,0,0.9-0.3,1.1-0.7c3-5.1,5.9-10.2,8.8-15.3
			c0.2-0.3,0.2-0.9,0-1.3c-2.9-5-5.8-10.1-8.7-15c-0.2-0.4-0.9-0.8-1.4-0.8C59.4,40.9,57.1,41,54.8,41z M23.9,44.4
			c-4.6,1.3-9.1,3-13.1,5.9c-1.6,1.2-3.1,2.7-4.4,4.3C5,56.3,5,58.4,6.3,60.2c0.9,1.2,1.8,2.4,3,3.4c4.2,3.6,9.2,5.7,14.5,7.2
			c0.1,0,0.2,0,0.1,0c1.7-4.3,3.5-8.6,5.1-12.8c0.1-0.3,0.1-0.7,0-0.9C27.3,52.9,25.6,48.8,23.9,44.4z M27.2,76.4
			c-1.1,4.7-2,9.3-1.5,14c0.2,2.1,0.8,4.2,1.6,6.2c0.8,2.1,2.7,3,4.9,2.9c0.7,0,1.4-0.1,2.1-0.3c6.3-1.5,11.2-5.4,15.9-9.7
			c-0.1-0.2-0.1-0.3-0.2-0.3c-2.8-3.4-5.5-6.8-8.3-10.2c-0.3-0.4-1-0.6-1.6-0.7c-1.8-0.3-3.7-0.4-5.5-0.7
			C32.2,77.3,29.7,76.9,27.2,76.4z M79.5,76.2c-0.4,0-0.6,0-0.8,0.1c-4.3,0.7-8.6,1.3-12.9,2c-0.4,0.1-0.8,0.4-1,0.7
			c-1,1.2-1.8,2.5-2.8,3.8c-1.8,2.3-3.6,4.5-5.5,6.8c0.8,0.7,1.6,1.5,2.4,2.2c3.6,3.1,7.5,5.9,12.1,7.3c4.9,1.5,7.6,0.1,9-4.9
			c1-3.7,1-7.4,0.6-11.1C80.3,80.8,79.9,78.5,79.5,76.2z M27.1,38.9c0.5-0.1,0.9-0.1,1.2-0.2c3.3-0.5,6.6-1.3,10-1.6
			c2.1-0.2,3.4-0.9,4.6-2.6c2.2-3,4.6-5.9,7.1-8.9c-1.4-1.2-2.8-2.4-4.2-3.6c-3.4-2.6-6.9-4.9-11.1-6c-4.8-1.2-7.3,0.3-8.4,5
			c-0.8,3.5-0.8,7-0.3,10.5C26.2,34,26.7,36.4,27.1,38.9z M56.5,25.6c2.9,3.7,5.6,7.2,8.4,10.8c0.2,0.2,0.4,0.4,0.7,0.4
			c4.5,0.7,9,1.3,13.6,2c1.1-5,2-10.2,1.2-15.5c-0.3-1.9-1-3.7-1.8-5.4c-0.6-1.4-1.9-2.3-3.6-2.3c-0.9,0-1.9,0-2.8,0.2
			C65.9,17.4,61.1,21.5,56.5,25.6z M82.6,70.7c1-0.3,2-0.6,2.9-1c4.6-1.7,9.1-3.7,12.7-7.3c3.6-3.6,3.6-6.5-0.1-10
			c-4.5-4.1-10-6.2-15.7-8c-1.2,3.1-2.3,6.1-3.4,9c-0.5,1.3-1.6,2.7-1.6,4c0,1.3,1.1,2.6,1.6,4C80.2,64.5,81.3,67.5,82.6,70.7z
			 M68.4,73.6c3.3-0.6,6.5-1.1,9.8-1.7c-1.2-3.2-2.3-6.1-3.5-9.2C72.6,66.4,70.6,69.9,68.4,73.6z M28.3,72.1c3.3,0.5,6.4,1,9.7,1.5
			c-2.2-3.7-4.1-7.2-6.2-10.8C30.5,65.9,29.4,68.9,28.3,72.1z M47,78.8c2.2,2.6,4.2,5,6.4,7.6c2.1-2.6,4.1-5,6.2-7.6
			C55.2,78.8,51.3,78.8,47,78.8z M59.4,36.3c-2.1-2.6-4.1-4.9-6.2-7.4c-2.1,2.5-4.1,4.9-6.2,7.4C51.2,36.3,55.1,36.3,59.4,36.3z
			 M78,43.3c-3.3-0.6-6.4-1.1-9.6-1.7c2.1,3.6,4.1,7.1,6.2,10.7C75.8,49.2,76.9,46.4,78,43.3z M37.9,41.6c-3.2,0.6-6.3,1.1-9.5,1.6
			c1.2,3.2,2.2,6.1,3.3,9.1C33.7,48.7,35.7,45.3,37.9,41.6z"
          />
          <path
            className="st0"
            d="M62.5,57.5c0,5.2-4.1,9.3-9.3,9.3c-5.2,0-9.3-4.1-9.3-9.3c0-5.2,4.1-9.4,9.3-9.4
			C58.3,48.1,62.5,52.3,62.5,57.5z"
          />
        </g>
      </g>
    </svg>
  );

  const swift = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 136.7 137"
      style={{ enableBackground: "new 0 0 136.7 137" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g id="JGOJg1.tif">
        <g>
          <path
            className="st0"
            d="M67.1,0.1c13.3,0,26.7,0,40,0c15.3,0,27.4,10.5,29.3,25.7c0.4,3,0.6,6,0.6,9c0,22.8,0.1,45.7,0,68.5
			c0,7.1-1.1,14.1-5.3,20.3c-6.4,9.2-15.5,13.3-26.4,13.3c-25.3,0.1-50.5,0.2-75.8-0.2c-17.4-0.3-29.9-13.5-29.9-30.9
			c0-25-0.1-50.1,0-75.1C-0.2,19.1,4,9.3,14.6,3.6c3.4-1.8,7.5-3.2,11.3-3.3C39.6-0.1,53.4,0.1,67.1,0.1z M62.5,63.1
			c-0.1,0.1-0.2,0.3-0.3,0.4c-12.9-9.8-25.7-19.7-38.6-29.5C37.5,53.1,52.8,71,71.7,86c-9.6,5.6-19.6,6.8-30.1,5.1
			C31.4,89.4,22,85.3,13.4,79.5c0.1,0.8,0.4,1.3,0.7,1.8c1.4,1.9,2.9,3.8,4.4,5.7c11.9,14.1,27,22.6,45.4,24.8
			c9.4,1.1,18.5-0.7,27-5c9.3-4.6,18.9-4.9,24.6,5.4c0.1,0.1,0.3,0.1,1.1,0.4c0.3-1.5,0.7-2.9,0.7-4.3c0.2-7.9-2.5-14.8-6.8-21.3
			c-0.9-1.3-1.1-3.3-1.1-4.9c0-5.7,1.1-11.4,0.4-17c-1.3-10.8-6.2-20.3-13-28.8c-5.4-6.7-11.5-12.6-19.3-16.7
			c4.6,7.3,8.3,14.9,10.2,23.4c1.9,8.6,1.8,17.1-1.6,25.8C67.8,56.8,51.4,43.1,36.1,28c-0.3,0.3-0.6,0.5-0.9,0.8
			C44.3,40.2,53.4,51.6,62.5,63.1z"
          />
        </g>
      </g>
    </svg>
  );

  const android = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 122.2 76.6"
      style={{ enableBackground: "new 0 0 122.2 76.6" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g id="yGCI29.tif">
        <g>
          <path
            className="st0"
            d="M26,5.8c0.2,0,0.3,0,0.5,0c0.9,0.2,1.4,0.8,1.8,1.6c2.8,4.9,5.6,9.7,8.4,14.6c0.2,0.4,0.4,0.7,0.6,1
			c15.9-6.7,31.8-6.7,47.7,0c0.1-0.1,0.1-0.2,0.2-0.3c2.9-5,5.8-10.1,8.7-15.1c0.4-0.8,0.9-1.5,1.8-1.7c0.2,0,0.3,0,0.5,0
			c1.9,0.6,2,2.1,1.2,3.5c-2.1,3.5-4.1,7-6.1,10.5c-0.9,1.6-1.9,3.2-2.8,4.9c0,0.1,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.2
			c10,5.6,17.6,13.6,22.6,23.9c2.5,5.1,4.1,10.5,4.9,16.1c0.1,0.9,0.3,1.9,0.4,2.8c0,0.2,0,0.5,0,0.7c-0.2,0-0.5,0-0.7,0
			c-36.6,0-73.2,0-109.8,0c-0.2,0-0.5,0-0.8,0c2.2-19.4,11.5-34,28.4-43.8c-1-1.7-2-3.4-3-5.2c-2-3.5-4.1-7-6.1-10.5
			C23.9,7.6,24.7,6.1,26,5.8C26,5.9,26,5.9,26,5.8z M40.2,48.1c0-2.5-2.1-4.6-4.6-4.6c-2.5,0-4.6,2.1-4.6,4.6c0,2.5,2.1,4.6,4.6,4.6
			C38.1,52.7,40.2,50.6,40.2,48.1z M91.4,48.1c0-2.6-2-4.6-4.6-4.6c-2.6,0-4.6,2.1-4.6,4.6c0,2.5,2.1,4.6,4.6,4.6
			C89.3,52.7,91.4,50.6,91.4,48.1z"
          />
        </g>
      </g>
    </svg>
  );

  const aws = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 136.7 170"
      style={{ enableBackground: "new 0 0 136.7 170" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g>
        <g>
          <path
            className="st0"
            d="M70.9,116.6c-26.4-0.1-47-7.4-65.1-21.8c-1.6-1.3-3.2-2.7-4.7-4c-0.3-0.3-0.7-0.6-0.9-1
			C0,89.4,0,88.9,0.2,88.6c0.2-0.2,0.7-0.4,1.1-0.3c0.5,0.1,1,0.4,1.4,0.7C15.9,96.7,30,101.8,45,104.6c6.4,1.2,12.9,1.8,19.4,2.2
			c6,0.3,11.9,0,17.8-0.6c13.2-1.4,25.8-4.6,38-9.8c0.5-0.2,0.9-0.4,1.4-0.5c1-0.2,1.8,0.1,2.3,1c0.5,0.9,0.1,1.7-0.6,2.2
			c-1.7,1.2-3.3,2.5-5.1,3.6c-9.6,6-20.1,9.8-31.1,12C80.7,116,74.2,116.7,70.9,116.6z"
          />
          <path
            className="st0"
            d="M69.8,45c-1.2,5.1-2.4,10.2-3.7,15.3c-1.1,4.5-2.1,8.9-3.2,13.4c-0.5,2-1,2.4-3.2,2.5c-0.9,0-1.9,0-2.8,0
			c-2.3,0-2.7-0.3-3.4-2.5c-2.6-8.5-5.2-17-7.8-25.5c-1-3.3-2-6.6-3-10c-0.5-1.8-0.2-2.2,1.6-2.2c1.1,0,2.2,0,3.3,0
			c2.1,0,2.5,0.3,3.1,2.4c1.3,4.9,2.5,9.9,3.8,14.8c1.2,4.5,2.3,9.1,3.5,13.6c0.1,0.3,0.2,0.6,0.5,1c0.3-1.4,0.7-2.8,1-4.2
			c2-8.3,3.9-16.7,5.9-25c0.5-2.2,1-2.6,3.3-2.6c1.1,0,2.1,0,3.2,0c1.7,0.1,2.2,0.4,2.6,2.1c0.9,3.5,1.7,7,2.5,10.4
			c1.5,6.3,3,12.7,4.5,19c0,0.2,0.1,0.3,0.2,0.9c0.2-0.7,0.4-1,0.5-1.4c2.4-9.4,4.9-18.7,7.3-28.1c0.1-0.4,0.3-0.9,0.4-1.3
			c0.3-1.1,1.1-1.6,2.2-1.6c1.5,0,2.9,0,4.4,0c0.8,0,1.3,0.5,1.1,1.3c-0.1,0.5-0.2,1-0.3,1.4c-3.7,11.7-7.3,23.4-11,35.1
			c-0.6,2.1-1.1,2.4-3.3,2.4c-1.1,0-2.1,0-3.2,0c-1.9,0-2.4-0.4-2.8-2.3c-1.7-7.1-3.5-14.3-5.2-21.4c-0.6-2.5-1.2-5-1.8-7.5
			C69.9,45,69.8,45,69.8,45z"
          />
          <path
            className="st0"
            d="M30.8,53.7c0-2.8,0.2-5.5-0.7-8.2c-1.1-3.3-3.8-4.2-6.8-4.4c-4.2-0.4-8.2,0.6-12,2.1
			c-2.8,1.1-2.9,0.9-3.1-2.1c-0.1-2,0.7-3.1,2.5-3.8c5.4-2.2,11-3.1,16.8-2.1c6.3,1.1,10.2,5.2,10.5,11.6c0.3,5.4,0.2,10.8,0.2,16.2
			c0,3.1,0.4,5.9,2,8.6c0.6,1.1,0.5,1.7-0.6,2.4c-0.9,0.6-1.8,1.2-2.7,1.8c-1.1,0.7-1.9,0.6-2.7-0.4c-0.8-1-1.5-2.1-2.2-3.2
			c-0.3-0.4-0.5-0.8-0.8-1.4c-0.5,0.6-0.9,1.1-1.4,1.5c-4.4,4.2-9.6,5.6-15.4,4.4c-6.3-1.3-9.5-6.6-8.8-13.1
			c0.7-6,5.2-10.2,11.5-11.1c4.2-0.6,8.3-0.1,12.4,0.8C29.9,53.6,30.3,53.7,30.8,53.7z M30.9,58.8c-4-0.9-7.8-1.4-11.6-0.8
			c-4.9,0.8-6.8,4.4-5.7,9.1c0.5,2.1,1.9,3.5,4,3.9c1.6,0.3,3.3,0.3,4.9,0c4-0.8,7.1-2.8,8-7.1C30.8,62.3,30.8,60.6,30.9,58.8z"
          />
          <path
            className="st0"
            d="M114.5,77.4c-3.8-0.1-7.4-0.7-10.9-2.1c-2.6-1.1-3.6-3.3-2.7-6c0.3-0.8,0.9-0.9,1.6-0.6
			c0.8,0.2,1.5,0.6,2.2,0.9c4.5,1.7,9,2.4,13.8,1.3c2.1-0.5,4-1.5,4.6-3.9c0.7-2.2-0.1-4.3-2.1-5.5c-1.3-0.7-2.7-1.3-4.1-1.8
			c-2.3-0.8-4.7-1.5-7.1-2.3c-2.5-0.9-4.7-2.2-6.2-4.3c-3.7-5-2.6-11.6,2.5-15.3c3.6-2.6,7.7-3.4,11.9-2.9c2.7,0.3,5.3,0.9,7.9,1.7
			c2.5,0.8,3.5,3.4,2.5,5.8c-0.3,0.9-1,0.7-1.7,0.5c-1.8-0.6-3.6-1.3-5.5-1.7c-2.8-0.6-5.6-0.6-8.4,0.1c-2.8,0.7-4.1,2.4-4,5
			c0,1.6,0.8,2.9,2.2,3.7c1.4,0.8,2.9,1.4,4.4,1.9c2.7,1,5.4,1.7,8,2.7c4.2,1.6,6.9,4.6,7.2,9.2c0.3,4.8-1.8,8.4-5.8,10.9
			C121.7,76.8,118.1,77.3,114.5,77.4z"
          />
          <path
            className="st0"
            d="M122.5,91c-3,0.3-6.4,0.7-9.8,0.9c-0.5,0-0.9-0.2-1.4-0.4c0.2-0.4,0.4-1,0.7-1.3c2.4-1.8,5.2-2.8,8.2-3.4
			c4.6-0.9,9.1-1.1,13.7,0c2.5,0.6,3,1,2.8,4c-0.4,6.1-2.4,11.6-6.1,16.5c-0.8,1-1.7,1.9-2.7,2.8c-0.4,0.3-1,0.4-1.6,0.6
			c0-0.6-0.2-1.2,0-1.7c1.2-3.6,2.5-7.1,3.6-10.7c0.3-1,0.5-2.1,0.6-3.1c0.3-2.4-0.9-3.7-3.6-4.1C125.6,91.2,124.3,91.1,122.5,91z"
          />
        </g>
      </g>
    </svg>
  );

  const blender = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 106.9 111.6"
      style={{ enableBackground: "new 0 0 106.9 111.6" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g id="gu9zC5.tif_3_">
        <g>
          <path
            className="st0"
            d="M33,60.5c-1.1,0.9-2,1.7-3,2.4c-6,4.9-12,9.8-18,14.7c-1.9,1.6-4.2,2.3-6.7,2.2c-3.4-0.2-5.9-3.3-5.3-6.6
			c0.4-2.4,1.7-4.3,3.6-5.8C9.5,63,15.3,58.6,21,54.2c6.9-5.3,13.8-10.5,20.6-15.8c0.2-0.1,0.3-0.3,0.7-0.5c-0.4,0-0.7-0.1-1-0.1
			c-6.7,0-13.4,0.1-20.1,0.1c-1.1,0-2.2-0.1-3.2-0.6c-2.5-1.1-3.5-3.6-2.2-6c1.2-2.2,3.1-3.4,5.5-3.8c0.9-0.2,1.9-0.1,2.8-0.2
			c7.8,0,15.6,0,23.4,0c5.7,0,11.5,0,17.2,0c0.2,0,0.3,0,0.7,0c-0.2-0.2-0.4-0.4-0.5-0.5c-4.4-3.5-8.7-7.1-13.1-10.6
			c-1-0.8-1.8-1.7-1.8-3.1c0-1.3,0.6-2.3,1.6-3.1c2.4-2,6.3-2.1,8.8-0.2c11,8.4,22,16.9,33,25.4c5.6,4.3,9.7,9.8,11.9,16.6
			c1.2,3.6,1.7,7.3,1.4,11c-0.4,7.6-3.3,14.2-8.3,19.8C92.8,89,85.5,92.8,77,94.5c-3.5,0.7-7.1,0.9-10.7,0.6
			c-9.5-0.9-17.8-4.5-24.5-11.3c-4.3-4.4-7.2-9.6-8.5-15.6c-0.5-2.3-0.7-4.6-0.3-6.9C33,61,33,60.8,33,60.5z M92.5,58.4
			c0-0.4,0-0.9,0-1.3c0-0.4-0.1-0.9-0.1-1.3c-0.6-4.1-2.3-7.8-5.3-10.7c-5.8-5.9-12.9-8.1-21-7c-5.3,0.7-9.8,3-13.5,6.9
			c-4.2,4.4-6.1,9.6-5.4,15.7c0.5,4.6,2.5,8.5,5.9,11.7c5.6,5.4,12.5,7.4,20.2,6.4c4.8-0.6,9-2.5,12.6-5.8
			C90.1,69.1,92.4,64.3,92.5,58.4z"
          />
          <path
            className="st0"
            d="M55.4,57.4c0.3-5.6,3.1-9.4,8.1-11.7c5.8-2.6,12.7-1.4,17.1,3.1c2.3,2.3,3.6,5.1,3.6,8.3
			c0.1,4.6-1.9,8.1-5.6,10.7c-6.7,4.7-16.3,3.1-20.9-3.3C56.2,62.4,55.4,59.9,55.4,57.4z"
          />
        </g>
      </g>
    </svg>
  );

  const figma = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 436 469"
      style={{ enableBackground: "new 0 0 436 469" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g id="yuDzNJ.tif">
        <g>
          <path
            className="st0"
            d="M153.5,18.9c50.5,0,101,0,151.5,0c0.3,0,0.6,0.1,0.9,0.1c4.5,0.2,8.9,1,13.3,2.1
			c14.7,3.9,27.1,11.6,36.8,23.4c14.5,17.7,19.7,37.9,15.3,60.3c-3.5,17.8-12.8,32.1-27.2,43.1c-9.2,7-19.5,11.4-30.8,13.3
			c-3.1,0.5-6.2,0.8-8.8,1.1c37.6,1.5,67.9,32.6,68.3,70.7c0.4,38.2-29,69.6-66.4,72.3c-18.3,1.3-35-3.7-49.4-15.1
			c-16.5-13-25.4-30.2-27.5-51c0,0.6,0,1.2,0,1.8c0,45.1,0,90.3,0,135.4c0,11.2-2.2,21.9-7.2,31.9c-8.1,16.4-20.6,28.2-37.5,35.2
			c-7.5,3.1-15.4,4.8-23.6,5.2c-8.7,0.4-17.3-0.7-25.6-3.4c-12.2-4-22.6-10.8-31.2-20.3c-8.8-9.8-14.5-21.1-17-34
			c-0.7-3.6-0.9-7.2-1.4-10.8c0-2.1,0-4.2,0-6.4c0.1-1,0.2-2,0.3-3.1c1-10.9,4.2-21.2,10-30.5c10.7-17.3,26-28.4,45.8-33
			c4.3-1,8.6-1.4,13-1.7c-0.1,0-0.3-0.1-0.4-0.1c-8.3-0.3-16.2-2-23.8-5.1c-10.3-4.2-19.2-10.4-26.6-18.7
			c-8.7-9.7-14.4-21-16.9-33.8c-0.7-3.6-1-7.4-1.4-11.1c0-2,0-4,0-6c0.2-1.7,0.3-3.4,0.5-5c1.4-12.5,5.8-23.8,13.2-34
			c9.1-12.5,21-21.2,35.7-26c6.2-2,12.6-3.2,18.4-3.4c-2.7-0.3-6-0.6-9.2-1.2c-17.1-3.3-31.3-11.7-42.5-25.1
			C94,126,88.9,114.4,86.9,101.4c-0.4-2.3-0.5-4.7-0.8-7c0-2.6,0-5.2,0-7.7c0-0.3,0.1-0.6,0.1-0.8c0.3-2.8,0.5-5.5,1.1-8.3
			c3.6-18.9,13.3-34,28.8-45.3c10-7.3,21.2-11.5,33.4-12.9C150.9,19.2,152.2,19.1,153.5,18.9z M297.4,162.3c-0.1,0-0.2,0-0.3,0
			c-22.2,0-44.3,0-66.5,0c-1,0-1.1,0.1-1.1,1.3c0,21.4,0,42.7,0,64.1c0,0.3,0,0.7,0,1C232.3,193.2,260.8,164.4,297.4,162.3z"
          />
        </g>
      </g>
    </svg>
  );

  const firebase = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 436 469"
      style={{ enableBackground: "new 0 0 436 469" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g>
        <g>
          <path
            className="st0"
            d="M257.3,145.1c-9.7-18.5-18.3-35.5-27.5-52.2c-2.1-3.8-6.6-9.2-9.7-9c-3.9,0.3-8.7,4.8-10.9,8.7
			C168.4,165.1,128,237.8,87.5,310.4c-0.8,1.5-1.3,3.2-2,4.9c-0.3-0.4-0.5-0.8-0.8-1.2C142.2,257.8,199.6,201.6,257.3,145.1z"
          />
          <path
            className="st0"
            d="M213.5,418.4c-44.8-24.9-89.6-49.9-136.8-76.1c20-19.3,39.1-37.4,57.7-55.9
			c56.4-56.5,112.6-113.2,168.9-169.7c4.1-4.1,8-11.6,14.7-7.7c3.9,2.3,6.3,9.1,7.1,14.2c11.7,71.2,23,142.5,34.7,213.7
			c0.8,5.1-0.7,7.1-4.7,9.3c-40.4,22.4-80.7,45-121.1,67.5c-3.2,1.8-6.7,3.2-10.1,4.7C220.4,418.4,216.9,418.4,213.5,418.4z"
          />
          <path
            className="st0"
            d="M138.8,27.9c9.9,17.8,19.8,35.7,29.7,53.5c2.5,4.5,4.2,9.7,7.4,13.7c8.1,10.3,6.4,19,0,30.1
			c-28.1,48.7-55.1,98.1-82.5,147.2c-1.1,2-2.3,3.9-4.8,5.5c2.5-16.2,4.9-32.4,7.4-48.6c9.6-62.4,19.2-124.9,29.2-187.3
			c0.8-4.9,4.4-9.4,6.7-14.1C134.2,27.9,136.5,27.9,138.8,27.9z"
          />
          <path
            className="st0"
            d="M257.3,145.1c-57.7,56.5-115.2,112.7-172.6,169c0.3,0.4,0.5,0.8,0.8,1.2c0.6-1.6,1.1-3.3,2-4.9
			c40.5-72.6,81-145.3,121.8-217.8c2.2-3.9,7-8.5,10.9-8.7c3.1-0.2,7.6,5.1,9.7,9C239.1,109.6,247.7,126.6,257.3,145.1z"
          />
        </g>
      </g>
    </svg>
  );

  const zenject = (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 436 469"
      style={{ enableBackground: "new 0 0 436 469" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g>
        <g>
          <path
            className="st0"
            d="M231.1,153.1c-2.7-0.2-4.7-0.4-6.6-0.4c-19,0-38-0.1-57,0.1c-4.3,0.1-6.6-1.4-8.6-5.1
			c-11.1-19.6-22.4-39-33.7-58.5c-0.6-1.1-1.1-2.4-2-4.3c2.2-0.2,3.9-0.5,5.7-0.5c59,0,118,0,177-0.1c3.8,0,6.1,1.2,8,4.5
			c4.6,8.3,9.4,16.5,14.3,24.6c2,3.3,1.9,5.9,0,9.2c-40.5,70-80.9,140-121.3,210c-0.8,1.4-1.5,2.9-2.6,5.1c2.7,0.2,4.6,0.4,6.5,0.4
			c19.7,0,39.3,0,59,0c3,0,5.3,0.4,7.1,3.4c11.7,20.5,23.7,41,35.5,61.5c0.4,0.7,0.6,1.5,1.2,3c-1.9,0.2-3.5,0.4-5,0.4
			c-59.2,0-118.3,0-177.5,0.1c-4.1,0-6.3-1.4-8.2-4.9c-4.7-8.6-9.7-17.1-14.7-25.6c-1.8-2.9-1.7-5.3,0-8.3
			c28.1-48.6,56.1-97.3,84.2-146c12.1-21.1,24.3-42.2,36.4-63.3C229.4,157.1,230,155.5,231.1,153.1z"
          />
          <path
            className="st0"
            d="M91.3,345.4c-1.1-1.7-2.1-3-2.9-4.4c-18.7-33.3-37.2-66.6-56-99.8c-2.3-4.1-2.2-7.2,0.2-11.4
			c23.7-40.6,47.1-81.3,70.6-122c0.7-1.3,1.6-2.4,2.9-4.5c4.2,7,8,13.4,11.7,19.8c7.4,12.7,14.5,25.5,22.1,38
			c2.3,3.8,2,6.8-0.2,10.5c-11.5,19.5-22.6,39.2-34.2,58.7c-2.4,4-2,7.1,0.1,10.9c7,12.5,13.7,25.1,20.3,37.9
			c0.9,1.7,1.2,4.7,0.4,6.3c-10.8,19.2-21.9,38.2-32.9,57.2C92.9,343.5,92.3,344.2,91.3,345.4z"
          />
          <path
            className="st0"
            d="M344.5,143.4c8.1,14.5,15.6,27.8,23.1,41.1c12.1,21.5,24,43.1,36.2,64.5c1.9,3.4,2.1,6.1,0.1,9.6
			c-23.6,40.6-47,81.4-70.5,122.1c-0.9,1.5-1.9,3-3.2,5c-1.1-1.3-1.9-2-2.5-2.9c-10.8-18.6-21.5-37.2-32.3-55.8
			c-1.7-2.8-1.5-4.9,0.1-7.7c11.8-20.1,23.3-40.4,35.1-60.6c2-3.4,2.2-6,0.2-9.6c-7.2-12.8-14.1-25.7-20.8-38.7
			c-0.9-1.7-1.3-4.7-0.4-6.3c11.1-19.6,22.4-39,33.7-58.5C343.5,145.2,343.7,144.7,344.5,143.4z"
          />
        </g>
      </g>
    </svg>
  );
  const unirx = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 436 469"
      style={{ enableBackground: "new 0 0 436 469" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g>
        <path
          className="st0"
          d="M252,382.7c0.3-11.7-2.9-22.6-7.6-33.1c-3-6.7-6.9-13-10.4-19.5c-0.2-0.3-0.3-0.7-0.6-1.4
		c0.8-0.1,1.5-0.3,2.1-0.3c11.2,0,22.4,0,33.6,0c1,0,2.3,0.5,2.9,1.2c9.9,13.1,18.3,26.9,21.6,43.3c0.7,3.3,0.9,6.6,1.3,9.9
		C280.6,382.7,266.3,382.7,252,382.7z"
        />
        <path
          className="st0"
          d="M187.2,330.1c-12.8,0-25,0-36.7,0c-0.6-2.2-0.8-4.2-1.6-6c-7.4-16.7-15.2-33.3-22.1-50.1
		c-3.8-9.3-6.3-19.1-9.4-28.7c-1.1-3.5-2.2-6.9-3-10.7c0.8,0.8,1.6,1.5,2.3,2.3c7.6,8.2,15.2,16.4,22.9,24.6c1.5,1.6,3,3.1,4.9,4.2
		c3.5,2,5,5.2,6.5,8.7c3.6,8.5,7.5,16.8,11.5,25.1c1,2,2.4,3.9,3.9,5.7c2.5,2.8,3.4,2.7,5.6-0.5c-8.4-9.9-12.6-22-18.4-33.9
		c2.2,0.2,3.8,0.4,5.3,0.5c8.1,0.5,15.7-1.9,23.1-4.8c10.4-4,20.7-8,31.1-12c2.8-1.1,2.9-0.9,3.1,2.2c0.2,5.3,0.4,10.6,0.7,16
		c0,0.6,0.2,1.2,0.3,1.7c0.3,0.1,0.5,0.3,0.6,0.2c2.7-1.9,4.4-0.3,6.1,1.6c14.2,15.6,28.5,31.2,42.8,46.8c0.4,0.4,0.7,1,1.3,1.8
		c-1.3,0.1-2.1,0.3-2.9,0.3c-10.6,0-21.1,0-31.7,0.1c-1.9,0-3-0.6-4.1-2.1c-9-12-18.1-23.9-27.2-35.9c-1.2-1.6-2.2-1.9-3.6,0
		c2.6,3.4,5.2,6.9,8.2,10.8c-1.3,0.2-2.1,0.4-3,0.4c-5.4,0-10.8,0.3-16.2-0.1c-4.2-0.3-7.3,1.1-10.2,3.9c-2.8,2.7-2.9,2.6-1.1,6
		c3.3,6.4,6.7,12.8,10,19.3C186.4,328,186.6,328.8,187.2,330.1z"
        />
        <path
          className="st0"
          d="M222,274.5c0-3,0-5.1,0-7.2c0.1-5.1,0.1-10.2,0.4-15.2c0-0.9,0.9-2.1,1.7-2.5c21.2-10.8,40.7-24.4,60.9-37
		c0.9-0.6,1.9-1.1,3.1-1.7c0.1,0.9,0.3,1.4,0.3,1.9c-0.4,7.5-0.7,14.9-1.2,22.4c-0.1,1.1-1,2.5-2,3.2c-6.9,4.4-13.9,8.6-20.9,12.9
		c-0.7,0.4-1.4,1-2.7,1.8c6.3,6.1,12.3,11.9,18.6,17.9c-0.7,0.2-1.1,0.4-1.5,0.4c-6.9,0-13.8,0-20.7-0.1c-1,0-2.2-0.4-2.9-1.1
		c-2.5-2.2-4.8-4.6-7.3-6.8c-0.6-0.6-1.6-0.7-2.7-0.6c13.7,14.3,28.6,27,43.8,40.5c-1.4,0.1-2.1,0.2-2.9,0.2c-9.8,0-19.5,0-29.3,0.1
		c-1.9,0-3.3-0.5-4.6-1.9c-8.4-8.9-16.9-17.7-25.4-26.5C225.6,273.9,224.7,272.4,222,274.5z"
        />
        <path
          className="st0"
          d="M300.1,169c0-1.6-0.1-2.7,0-3.9c0.6-9.4,1.2-18.7,1.9-28c0.1-0.9,0.5-2.1,1.2-2.7
		c15.9-13.7,32.6-26.1,51.9-34.8c2.7-1.2,5.5-2.3,8.8-3.6c0,3.4,0.2,6.2,0,9c-0.6,9.6-1.4,19.2-2.1,28.8c-0.1,1.6-0.5,2.6-2.2,3.2
		c-14.5,4.5-27.9,11.5-40.6,19.7c-5.6,3.6-11.1,7.4-16.6,11.1C301.7,168.1,301,168.4,300.1,169z"
        />
        <path
          className="st0"
          d="M340.1,363.5c-2.4,0-4.3,0-6.3,0c-11.1,0-22.2,0-33.3,0c-2.2,0-3.3-0.7-4.3-2.8c-5.8-13.2-14-24.9-23.2-35.9
		c-4.1-4.9-8.4-9.7-12.7-14.5c-0.3-0.4-0.6-0.8-1.1-1.6c1.5,0,2.7,0,3.8,0c9.8,0,19.7,0,29.5,0.1c1.4,0,3,0.6,4,1.5
		c10.5,9.6,20.7,19.5,29.3,30.8c5,6.5,9.1,13.7,13.6,20.6C339.8,362.1,339.8,362.5,340.1,363.5z"
        />
        <path
          className="st0"
          d="M243.3,358.7c-1.1,0.1-1.9,0.2-2.7,0.2c-11.7,0-23.4,0-35,0c-1.7,0-2.8-0.3-3.4-2.2
		c-3.9-13.6-10.1-26.2-16.8-38.6c-2.2-4-4.5-7.9-6.7-11.8c-0.3-0.6-0.6-1.2-1.1-2.2c1.1-0.1,1.9-0.2,2.6-0.2
		c9.5,0,19.1-0.1,28.6,0.1c1.2,0,2.9,0.7,3.6,1.6c9.2,12.3,17.9,24.9,24.8,38.6c2,4,3.7,8.3,5.5,12.4
		C243,357.2,243,357.7,243.3,358.7z"
        />
        <path
          className="st0"
          d="M225.2,196.6c-0.4,4.7-0.7,8.8-1,12.9c-0.2,3.2-0.4,6.3-0.8,9.5c-0.1,1-0.8,2.4-1.7,2.7
		c-16.9,7.8-34.4,13.7-53.4,13.1c-7.3-0.2-14.4-1.3-21.1-4.3c-1-0.5-2.3-1.8-2.4-2.8c-0.6-8.4-0.8-16.8-1.2-25.2
		c0-0.4,0.2-0.7,0.4-1.5c0.8,0.5,1.5,0.8,2.1,1.2c10.3,7,21.7,9.4,34,8.4c15.2-1.2,29.1-6.2,42.5-13.1
		C223.2,197.3,223.9,197.1,225.2,196.6z"
        />
        <path
          className="st0"
          d="M111.1,181.1c0.3,1,0.6,1.5,0.7,2.1c1.5,14.3,8.3,26,18.2,36.1c5.8,5.9,11.8,11.6,19.8,14.6
		c5.6,2.1,11.5,3.2,17.5,3.4c2.1,0.1,2.8,0.9,3.4,2.8c2.2,7.5,4.5,15,7,22.5c0.6,1.8,0.2,2.5-1.6,2.7c-13.3,1.9-25.3-0.9-35.9-9.5
		c-9.5-7.7-18.2-16.1-26-25.5c-0.4-0.5-0.9-1-1-1.6c-1.4-5.6-3.4-11.2-4-16.9c-1.1-9.8,0.5-19.6,1.5-29.4
		C110.5,182.1,110.7,181.8,111.1,181.1z"
        />
        <path
          className="st0"
          d="M253.3,206.5c0,1.5,0.1,2.6,0,3.7c-0.4,6.2-0.8,12.4-1.4,18.5c-0.1,1.2-0.8,2.7-1.8,3.3
		c-11.2,7.2-22.4,14.2-34.9,19c-10.4,4-20.9,8-31.4,12c-1.3,0.5-2.1,0.3-2.5-1.1c-2.1-7.3-4.2-14.7-6.2-22c-0.4-1.4-0.4-2.4,1.6-2.7
		c24.1-2.9,46.1-11.5,66.4-24.7c2.6-1.7,5.3-3.3,7.9-4.9C251.6,207.3,252.2,207,253.3,206.5z"
        />
        <path
          className="st0"
          d="M130.9,100.9c0,0.9,0,1.8,0,2.7c-0.2,16.6,1.9,32.8,8.1,48.3c4.6,11.7,11.2,21.7,22.5,28.1
		c5.1,2.9,10.7,4.5,16.4,5.4c2,0.3,2.4,0.9,1.9,3c-1.3,6-2.3,12.2-3.6,18.2c-0.2,0.7-1.2,1.9-1.8,1.9c-9.3-0.5-18.3-2.1-26.3-7.2
		c-7.9-5-12.1-12.6-14.8-21.3c-5.1-16.7-7.9-33.8-9.4-51.2c-0.7-8.5,2.6-15.9,4.6-23.6c0.4-1.5,1-2.9,1.5-4.4
		C130.3,100.8,130.6,100.8,130.9,100.9z"
        />
        <path
          className="st0"
          d="M230,217.4c0-1.5-0.1-2.5,0-3.5c0.4-6.1,0.8-12.2,1.4-18.3c0.1-1.1,0.9-2.6,1.9-3.2
		c19.1-12,36.3-26.4,53.1-41.4c3.2-2.8,6.4-5.6,10.1-8.9c0.1,1.3,0.3,2,0.2,2.7c-0.6,9.3-1.2,18.5-1.7,27.8c-0.1,1.7-0.7,2.8-2,3.8
		c-19.4,14.5-39.8,27.6-60.7,39.9C231.7,216.7,231.2,216.9,230,217.4z"
        />
        <path
          className="st0"
          d="M261.6,147.9c0,1.9,0,3.1,0,4.4c-0.1,5.5-0.2,11-0.5,16.4c-0.1,1.1-0.5,2.5-1.3,3.1
		c-17.5,13.6-36.1,25.2-57.3,32.1c-5.3,1.7-10.8,2.7-16.2,4c-1.9,0.5-2.6-0.2-2.2-2c1.1-5.8,2.2-11.7,3.3-17.5
		c0.3-1.8,1.2-2.8,3.3-3.1c13.3-2,25.1-7.9,36.6-14.4c10.7-6.1,21.3-12.4,30.9-20.2C259.1,149.8,260.1,149.1,261.6,147.9z"
        />
        <path
          className="st0"
          d="M256.6,227.8c0.3-4.8,0.5-9,0.7-13.2c0.2-3,0.2-6,0.5-9c0.1-1.2,0.8-2.7,1.7-3.3
		c16.8-11.5,33.7-22.9,50.6-34.4c2.4-1.6,4.9-3.2,7.7-5.1c0.1,1,0.3,1.7,0.3,2.3c-0.4,9-0.8,18.1-1.4,27.1c0,0.8-0.8,2-1.5,2.3
		c-20,8.4-38.1,20.2-56.3,31.9C258.5,226.8,257.8,227.1,256.6,227.8z"
        />
        <path
          className="st0"
          d="M332.1,86c-0.2,2.3-0.4,4-0.5,5.7c-0.4,5.9-0.8,11.9-1.4,17.8c-0.1,1.2-0.8,2.7-1.7,3.3
		c-18.4,13.7-34.9,29.5-51.9,44.7c-2.8,2.5-5.7,4.9-9,7.9c-0.1-1.6-0.2-2.4-0.2-3.2c0.1-6,0.2-11.9,0.5-17.9
		c0.1-1.3,0.7-2.8,1.6-3.7c11.1-10.4,22.1-20.8,33.4-30.8C311.8,102,321,94.6,330,87C330.4,86.7,331,86.5,332.1,86z"
        />
        <path
          className="st0"
          d="M349.8,331.9c-0.8,0.1-1.5,0.2-2.3,0.2c-7.3,0-14.6,0.1-21.9-0.1c-1.4,0-3.2-0.7-4.1-1.7
		c-15-15.3-31.2-29.1-47.4-43.1c-3.2-2.8-6.5-5.6-9.7-8.5c-0.3-0.3-0.6-0.7-1-1.2c0.6-0.2,1-0.4,1.4-0.4c6.6-0.1,13.2-0.2,19.8,0
		c1.7,0,3.7,0.7,4.9,1.8c17.7,15,35.3,30.1,52.9,45.2c2.7,2.3,5.2,4.8,7.8,7.2C350,331.4,349.9,331.7,349.8,331.9z"
        />
        <path
          className="st0"
          d="M139.3,225.8c-3-2.8-5.6-5.1-8.1-7.7c-11.3-11.9-17.2-26-18.7-42.3c-0.7-7,0.9-13.6,2.4-20.2
		c1.8-7.9,3.9-15.7,5.9-23.6c0.1-0.5,0.4-0.9,1.2-1.3c0.3,2.3,0.5,4.6,0.8,6.9c1.8,13.8,3.4,27.6,7.7,40.9c1.7,5.2,4.7,10,7,15
		c0.4,0.8,0.8,1.7,0.8,2.6c0.4,9.1,0.8,18.2,1.1,27.3C139.5,224.1,139.4,224.6,139.3,225.8z"
        />
        <path
          className="st0"
          d="M158.2,176.4c-0.5-0.4-1.2-0.9-1.8-1.5c-5.6-5.9-10.2-12.6-14.1-19.7c-1-1.8-1.1-3.5-0.5-5.5
		c2.6-7.6,5-15.2,7.4-22.9c0.4-1.4,1.1-2.2,2.6-2.2c7.7,0,15.4,0,23.1,0c0.4,0,0.8,0.2,0.9,0.2C170.1,142.1,164.2,159.1,158.2,176.4
		z"
        />
        <path
          className="st0"
          d="M193.4,151.8c-1.4,4.5-2.9,8.7-4.1,12.9c-1.6,5.6-3.2,11.3-4.5,17c-0.5,2-1.4,3-3.4,2.6
		c-5.6-1.1-11.2-2.1-16.7-3.6c-2.9-0.8-3.1-1.8-2.4-4.7c1.9-7.4,3.9-14.7,5.8-22.1c0.4-1.3,0.8-2.6,2.6-2.6c7,0,14,0,21,0
		C192.1,151.4,192.6,151.6,193.4,151.8z"
        />
        <path
          className="st0"
          d="M155.8,99.3c-5.4,15.8-10.7,31.5-16,47.1c-0.3,0-0.5,0-0.8,0c-0.5-1.4-1-2.7-1.4-4.1c-4-13.4-4.9-27.1-4.4-41
		c0-1.3,0.4-2.4,2-2.4c6.6,0,13.2,0,19.8,0C155.2,99,155.5,99.2,155.8,99.3z"
        />
      </g>
    </svg>
  );

  const spine = (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1180.3 353.5"
      style={{ enableBackground: "new 0 0 1180.3 353.5" }}
      xmlSpace="preserve"
      onClick={onClick}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g id="Mxw3kp.tif">
        <g>
          <path
            className="st0"
            d="M0,218.2c3-7.6,7.7-6.8,13.6-3.2c18.6,11.4,38.3,18.1,60.6,14.1c18.4-3.2,28.6-14.7,28-31.8
     c-0.3-9-5.1-15.5-12.1-19.9c-7.7-4.8-16-8.5-24.3-12.3c-9.2-4.2-18.8-7.5-27.8-12.1C14.8,141.4,3.1,122.1,5.2,99.9
     c2.3-24.9,16.7-42.4,42.2-49.6c23.2-6.6,46.1-5.1,68.1,5c9.7,4.5,14.5,19.7,9.7,29.1c-2.4,4.6-5.4,2.6-8.9,1.3
     c-11.7-4.4-23.4-9.6-35.5-12.1c-7.5-1.6-16.3-0.1-23.8,2.3c-21,6.7-25.6,31.8-8.4,45.5c6.2,5,13.7,8.5,21,11.9
     c11.9,5.5,24.4,9.8,36.2,15.6c19.2,9.4,30.6,24.5,30.7,46.5c0.1,22.8-8.8,41.3-29.6,51.6c-32.6,16-65.6,13.9-97.6-2.3
     c-3.9-2-6.1-7.5-9.1-11.3C0,228.3,0,223.2,0,218.2z"
          />
          <path
            className="st0"
            d="M522.7,0c22.7,6,45.7,10.1,69.3,10.9c8.8,0.3,17.7,1.2,26.3,3.2c11.8,2.7,17.8,11,16.2,22.8
     c-1.2,8.9-3.5,17.9-7,26.1c-6.6,15.3-20.3,21.5-35.9,22.8c-23.7,2.1-47-0.8-69-10.4c-23.7-10.2-34.2-31.5-29.5-56.8
     c1.7-9,6.3-15,14.5-18.6C512.6,0,517.7,0,522.7,0z"
          />
          <path
            className="st0"
            d="M391.4,95.3c-11.6-34.4-38.7-50.9-75.1-48.4c-19.4,1.3-35.1,10.5-49.3,22.8c-4.1,3.6-8.1,7.2-12.2,10.9
     c-0.7-1.1-1.2-1.5-1.2-1.9c-0.1-5.3-0.2-10.6-0.2-15.9c0-11.8-0.9-12.7-12.8-13.2c-1.8-0.1-3.6-0.1-5.3,0
     c-11.4,0.5-11.9,1.1-11.9,12.2c0,83,0,166.1,0,249.1c0,20.7,0.7,21.4,21.6,20.4c1.5-0.1,3-0.3,4.5-0.4c6-0.8,8.5-3.9,8.4-10.2
     c-0.3-27.5-0.1-55-0.1-82.5v-10c6.9,5.8,11.6,11,17.4,14.4c8.3,5,17,10.6,26.3,12.8c38.1,9,74.3-10.3,88.4-47.5
     C403.9,170.9,404.1,132.8,391.4,95.3z M356.5,195.7C343,230.3,310.3,238.1,281,215c-13-10.3-23.1-21.4-24.2-37
     c-0.1-1.8-0.1-3.7,0-5.7v-44.8c0-5.5,1.2-11,3.7-15.9c0.7-1.3,1.4-2.4,2.1-3.1c9.1-9,18.1-18.8,28.8-25.4c26-16,54.4-6.3,65,22.2
     C367.5,135.2,368,165.8,356.5,195.7z"
          />
          <path
            className="st0"
            d="M1164.4,158.7c11.1,0,15.8-4.6,15.9-15.6c0-7.3-0.4-14.6-1.2-21.9c-4.9-46.4-33.2-72.8-79.9-74.6
     c-44.3-1.7-77.5,21.6-90,63.8c-8.7,29.3-8.3,58.9,0.3,88c8.5,28.9,27.8,47.9,57.2,55.1c32.2,7.9,63.7,3.8,94.5-7.7
     c8.8-3.3,10.7-9.5,10.6-17.8c-0.2-13.4-2.3-15.1-14.5-9.9c-21.1,9-43,13.1-65.9,10.9c-20.6-2-37.4-10.7-45.2-30.6
     c-4.7-11.9-6.1-25.1-9.4-39.7h12C1087.5,158.7,1125.9,158.8,1164.4,158.7z M1039.9,132.7c-1-28.2,18.3-61.1,56.1-59.6
     c39.1,1.5,48.3,29.6,49.7,54.9c0.2,3.6-0.9,5.6-5.1,5.6c-32.9-0.1-65.8-0.1-98.7-0.2C1041.3,133.5,1040.6,133,1039.9,132.7z"
          />
          <path
            className="st0"
            d="M784.3,81.7c2.3-3.3,4-7.1,6.9-9.8c17.5-16.7,37.5-27,62.7-25.1c31.8,2.4,54.2,22.3,59,54
     c1.6,10.7,2.5,21.6,2.6,32.4c0.3,36.3,0.1,72.7,0.1,109c0,10.3-1.4,11.7-11.8,12.1c-22.7,0.9-22.6,0.9-22.7-21.5
     c-0.2-37.1-0.5-74.2-1-111.3c-0.1-5.2-1.3-10.5-2.8-15.5c-8.3-27.7-34.7-38.8-59.1-23.3c-11.9,7.5-21.6,18.8-31.7,28.9
     c-2,2-2.2,6.5-2.2,9.9c-0.1,40.4-0.1,80.8-0.1,121.2c0,10.1-1.1,11.3-11.2,11.7c-23.3,1-23.3,1-23.3-21.8c0-56.8,0-113.6,0-170.4
     c0-10.9,1-11.9,12.1-12.5c0.3,0,0.5,0,0.8,0c17.6-0.7,18.3-0.1,18.3,17.5c0,4,0,8,0,12C782,80,783.2,80.8,784.3,81.7z"
          />
          <path
            className="st0"
            d="M576.2,185c-10.3,2.4-21.1,2.6-31.5,0.7c-5.9-1.1-11.8-2.4-17.4-4.6c-18.8-7.2-25.5-24.4-23.2-44.5
     c1-8.5,5.5-12.3,13.9-13.4c2.2-0.3,4.6-0.4,6.8-0.2c22.7,2.5,45.4,1.4,68-1.8c3.7-0.5,7.6-0.2,11.3,0.1
     c7.8,0.7,12.4,5.3,13.9,12.7c4.1,19.9-4.7,37.9-23.8,45.6C588.6,182,582.5,183.6,576.2,185z"
          />
          <path
            className="st0"
            d="M583.9,274c-26.4,0-40.4-12.8-41.3-32.2c-0.3-7.5,2.9-12.5,9.9-14c18.8-3.8,37.6-7.5,56.5-10.4
     c9.3-1.4,15.4,3.4,18.4,12.3c6,18,0.9,30.3-16.8,37C600.3,270.6,589.3,272.5,583.9,274z"
          />
          <path
            className="st0"
            d="M597.5,353.5c-5.6-1.3-11.7-1.9-17.3-4c-11.8-4.5-18.6-18.7-14.1-30.5c1.2-3,6.2-6.3,9.6-6.4
     c15.5-0.8,31.2-0.6,46.7-0.5c7.9,0,10.9,3.3,11.1,11c0.5,13.6-5.6,22.6-18.6,26.8C609.5,351.6,603.7,352.3,597.5,353.5z"
          />
        </g>
      </g>
    </svg>
  );

  switch (icon) {
    case StackIcon.Unity:
      return unity;
    case StackIcon.ThreeDsMax:
      return threeDsMax;
    case StackIcon.CSharp:
      return cSharp;
    case StackIcon.Css:
      return css;
    case StackIcon.Html:
      return html;
    case StackIcon.Ios:
      return ios;
    case StackIcon.Java:
      return java;
    case StackIcon.Kotlin:
      return kotlin;
    case StackIcon.Maui:
      return maui;
    case StackIcon.Ps:
      return ps;
    case StackIcon.React:
      return react;
    case StackIcon.Swift:
      return swift;
    case StackIcon.Android:
      return android;
    case StackIcon.Aws:
      return aws;
    case StackIcon.Blender:
      return blender;
    case StackIcon.Zenject:
      return zenject;
    case StackIcon.UniRx:
      return unirx;
    case StackIcon.Figma:
      return figma;
    case StackIcon.Firebase:
      return firebase;
    case StackIcon.Spine:
      return spine;
    default:
      throw `wrong icon: ${icon}`;
  }
};

export { StackIcon, Svgs };
