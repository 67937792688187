import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import FIrebaseInitializer from "./components/Firebase/FIrebaseInitializer";
import StateProvider from "./store/StateProvider";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <FIrebaseInitializer />
    <StateProvider>
      <App />
    </StateProvider>
  </BrowserRouter>
);
