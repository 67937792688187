import { StackIcon } from "./components/StackIcons";

const DUMMY_SERVICES = [
  {
    id: 1,
    imageSource: "gameDev",
    title: "Game Development",
    description:
      "Our game development team has extensive experience using the Unity engine to create engaging and immersive 2D and 3D games.\nFrom concept to deployment, we'll work with you to bring your game ideas to life.",
    stack: [
      StackIcon.Unity,
      StackIcon.CSharp,
      StackIcon.Blender,
      StackIcon.ThreeDsMax,
      StackIcon.Ps,
      StackIcon.UniRx,
    ],
  },
  {
    id: 2,
    imageSource: "gameDev",
    title: "AR/VR/XR",
    description:
      "Augmented reality (AR), virtual reality (VR), and extended reality (XR) are rapidly changing the way we experience the world. Our AR/VR/XR team has the expertise to help you create cutting-edge experiences in these rapidly growing technologies.",
    stack: [
      StackIcon.Unity,
      StackIcon.CSharp,
      StackIcon.Blender,
      StackIcon.ThreeDsMax,
      StackIcon.Ps,
      StackIcon.UniRx,
    ],
  },
  {
    id: 3,
    imageSource: "mobileApp",
    title: "Mobile Applications",
    description:
      "Our mobile development team specializes in creating native iOS and Android apps that are fast, reliable, and optimized for each specific platform. Whether you need a simple app or a complex, multi-featured solution, we have the skills to deliver.",
    stack: [
      StackIcon.Ios,
      StackIcon.Android,
      StackIcon.Maui,
      // StackIcon.Aws,
      // StackIcon.CSharp,
      // StackIcon.Java,
      // StackIcon.Kotlin,
      // StackIcon.Firebase,
    ],
  },
  {
    id: 4,
    imageSource: "webDev",
    title: "Web Applications",
    description:
      "Our web development team has the experience and expertise to help you build robust and scalable web applications that meet your business needs. From custom web portals to e-commerce platforms, we'll work with you to create a solution that fits your specific requirements.",
    stack: [
      StackIcon.React,
      StackIcon.Aws,
      StackIcon.Css,
      StackIcon.Html,
      StackIcon.Firebase,
    ],
  },
  // {
  //   id: 4,
  //   imageSource: "uiUx",
  //   title: "UI/UX Design",
  //   description:
  //     "With our exceptional UI/UX design services, we help you show off your brand's finest and exceed user expectations. Let’s collaborate to establish long-term customer retention strategies and real, human-centered brand identities that have a direct impact on today's digital landscape.",
  //   stack: [StackIcon.Ps, StackIcon.Figma, StackIcon.Android, StackIcon.Ios, StackIcon.Css],
  // },
];

export default DUMMY_SERVICES;
