import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import DUMMY_SERVICES from "../../ServicesData";
import Phone from "../Phone/Phone";
import Testimonials from "../Testimonials/Testimonials";
import SericeItem from "../Home/ServiceItem";
import classes from "./Home.module.css";
import { ORIENTATION } from "../Utils";
import StateContext from "../../store/state-context";
import useSmoothScroll from "../hooks/use-smooth-scroll";

const DEFAULT_STATE = {
  servicesScroll: 0,
  portfolioScroll: 0,
  portfolioY: 0,
  testimonialsScroll: 0,
  testimonialY: 0,
  isScrolling: false,
};

const stateReducer = (state, action) => {
  switch (action.type) {
    case "scrollPos":
      let scrollY =
        state.servicesHeight > state.windowHeight
          ? window.scrollY - (state.servicesHeight - state.windowHeight)
          : window.scrollY;

      let servicesScroll = scrollY / state.windowHeight;
      servicesScroll = servicesScroll.toFixed(2);

      let portfolioScroll = servicesScroll;
      let testimonialsScroll = servicesScroll - 1;

      if (servicesScroll > 1) {
        servicesScroll = 1;
      } else if (servicesScroll < 0) {
        servicesScroll = 0;
      }
      if (portfolioScroll > 0.99) {
        portfolioScroll = 1;
      }
      if (testimonialsScroll > 0.99) {
        testimonialsScroll = 1;
      }

      let portfolioY = portfolioScroll >= 0.8 ? 0 : 10;
      let testimonialY = testimonialsScroll >= 0.8 ? 0 : 10;

      const orientation =
        window.innerHeight > window.innerWidth
          ? ORIENTATION.Portrait
          : ORIENTATION.Landscape;

      return {
        ...state,
        lastKnownScrollPosition: action.lastKnownScrollPosition,
        ticking: false,
        servicesScroll: servicesScroll,
        portfolioScroll: portfolioScroll,
        portfolioY: portfolioY,
        testimonialsScroll: testimonialsScroll,
        testimonialY: testimonialY,
        orientation,
        isScrolling: false,
      };
    case "setHeight":
      var body = document.body,
        html = document.documentElement;

      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      const servicesRect = document
        .getElementById("services")
        .getBoundingClientRect();
      const portfolioRect = document
        .getElementById("portfolio")
        .getBoundingClientRect();
      const testimonialsRect = document
        .getElementById("testimonials")
        .getBoundingClientRect();

      let phoneHeight, phoneWidth;

      const windowWidth = document.documentElement.clientWidth;
      const windowHeight = document.documentElement.clientHeight;

      if (windowHeight < 768 && windowWidth > windowHeight) {
        // console.log(
        //   `setting for landscape ${windowWidth} ${windowHeight}`
        // );
        phoneWidth = windowWidth * 0.8;
        phoneHeight = phoneWidth / 2.164;
      } else {
        // console.log(
        //   `setting for portrait ${windowWidth} ${windowHeight}`
        // );
        phoneHeight = windowHeight * 0.8;
        phoneWidth = phoneHeight / 2.164;
      }

      return {
        ...state,
        windowHeight: windowHeight,
        pageHeight: height,
        servicesHeight: servicesRect.height,
        portfolioHeight: portfolioRect.height,
        testimonialsHeight: testimonialsRect.height,
        orientation:
          windowHeight > windowWidth
            ? ORIENTATION.Portrait
            : ORIENTATION.Landscape,
        phoneHeight: phoneHeight,
        phoneWidth: phoneWidth,
        textScale: 1,
      };
    default:
      return DEFAULT_STATE;
  }
};

const TITLES_DURATION = 300;
const PANELS_DURATION = 400;

const Home = () => {
  const [state, dispatch] = useReducer(stateReducer, DEFAULT_STATE);
  const landingRef = useRef();
  const portfolioRef = useRef();
  const testimonialsRef = useRef();

  const serviceRefs = Array.from({ length: DUMMY_SERVICES.length }, () => createRef());

  // const sectionRefs = [
  //   landingRef,
  //   ...serviceRefs,
  //   portfolioRef,
  //   testimonialsRef
  // ];
  // useSmoothScroll(sectionRefs)

  var stateCtx = useContext(StateContext);

  const resizeHandler = useCallback((event) => {
    dispatch({ type: "setHeight" });
  }, []);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      resizeHandler();
    }, 100);

    return () => {
      clearTimeout(timeOut);
    };
  }, [resizeHandler]);

  const portfolioScale = state.portfolioScroll >= 0.8 ? 1 : 0.9;
  const testimonialsScale = state.testimonialsScroll >= 0.8 ? 1 : 0.9;

  const titlesClassName = `text-backgroundColorDark dark:text-backgroundColor pl-8 md:pl-0
          text-4xl md:text-center ease-in-out
          `;

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (stateCtx.scroll === "Services") {
        serviceRefs[0].current.scrollIntoView({ behavior: "smooth" });
        stateCtx.setScroll(null);
      } else if (stateCtx.scroll === "Portfolio") {
        portfolioRef.current.scrollIntoView({ behavior: "smooth" });
        stateCtx.setScroll(null);
      }
    }, 100);

    return () => clearTimeout(timeOut);
  }, [stateCtx.scroll]);

  return (
    <div
      className={`bg-backgroundColor dark:bg-backgroundColorDark ${classes.homePage}`}
      ref={landingRef}
    >
      <div
        className={`relative 
         flex flex-col md:flex-row min-h-screen py-28 md:py-32
         ${classes["snap-section"]}}
        `}
        id="services"
      >
        <div className={`lg:flex-[.6] md:flex-1 relative  lg:pl-16`}>
          <p
            className={`
            text-backgroundColorDark dark:text-backgroundColor
          text-[1.8rem] 
            lg:text-6xl
          font-light lg:font-extrabold p-8
          uppercase
          select-none
          cursor-default
        `}
            style={{
              lineHeight: `${window.innerWidth > 820 ? "5.25rem" : "2.8rem"}`,
            }}
          >
            We are a
            <br />
            technical agency
            <br />
            that builds
            <br />
            digital products
            <br />
            for businesses
            <br />
            worldwide.
          </p>
        </div>
      </div>
      <div className="flex flex-col w-full h-fit">
        {DUMMY_SERVICES.map((s, index) => (
          <SericeItem service={s} className={`${classes["snap-section"]}`} key={`serivice${index}`} ref={serviceRefs[index]} />
        ))}
      </div>
      <div
        className={`relative min-h-screen pt-8 ${classes["snap-section"]}`}
        id="portfolio"
        ref={portfolioRef}
      >
        <p
          className={titlesClassName}
          style={{
            transitionDuration: `${TITLES_DURATION}ms`,
            transform: `translateY(${state.portfolioY}px)`,
            scale: `${portfolioScale}`,
          }}
        >
          Portfolio
        </p>
        <div
          className="flex-[1.5] relative
          mt-8 h-[90%] w-[100%]
          flex justify-center
          ease-in-out"
          style={{
            transitionDuration: `${PANELS_DURATION}ms`,
            transform: `translateY(${state.portfolioY}px)`,
            scale: `${portfolioScale}`,
          }}
        >
          <Phone
            width={state.phoneWidth}
            height={state.phoneHeight}
            className={`ease-out duration-[400ms]`}
          />
        </div>
      </div>
      <div
        className={`${classes.part} ${classes.testimonials} ${classes["snap-section"]}`}
        id="testimonials"
        ref={testimonialsRef}
      >
        <p
          className={`${titlesClassName} origin-top`}
          style={{
            transitionDuration: `${TITLES_DURATION}ms`,
            transform: `translateY(${state.testimonialY}px)`,
            scale: `${testimonialsScale}`,
          }}
        >
          Testimonials
        </p>
        <div
          className="w-full flex items-start justify-center
          pt-4 origin-bottom"
          style={{
            transitionDuration: `${PANELS_DURATION}ms`,
            transform: `translateY(${state.testimonialY}px)`,
            scale: `${testimonialsScale}`,
            transitionTimingFunction: "ease-in-out",
          }}
        >
          <Testimonials className="flex min-h-[25rem] w-[100%] max-w-lg" />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Home);
