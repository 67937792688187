import { useState } from "react";

/*${
    isActive ? "border-toofstone-green" : "border-black"
}
*/
const CustomNavLink = ({ text, onClick, className, style }) => {
  const [isActive, setIsActive] = useState(false);

  //   const textColor = isActive ? "text-white" : "text-black";
  return (
    <div
      className={`box-border flex items-center justify-between mb-[1vh]
      border-b-[3px] ${
        isActive ? "border-toofstone-green" : "border-transparent"
      }
      ease-out duration-[333ms] ${className} cursor-pointer`}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      onClick={onClick}
    >
      <p
        className={`text-4xl leading-tight md:text-[5vmin] 
        origin-left
        ease-in-out duration-[333ms]
          ${isActive ? "translate-x-2 scale-105" : "translate-x-0 scale-100"}
          `}
      >
        {text}
      </p>
    </div>
  );
};

export default CustomNavLink;
