import React, { useContext, useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Pages/Home";
import ContactUs from "./components/Pages/ContactUs";
import Footer from "./components/Footer/Footer";
import Header from "./components/MobileHeader/Header";
import StateContext, { HEADER_STATE } from "./store/state-context";
import Menu from "./components/Menu/Menu";
import ScrollUpSVG from "./components/ScrollUpSVG";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";

const App = () => {
  const [showScrollUp, setShowScrollUp] = useState(false);

  const footerRef = useRef();

  var stateCtx = useContext(StateContext);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY || window.pageYOffset;
      setShowScrollUp(scroll > window.innerHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`absolute min-h-[100vh] min-w-[100vw] `}>
      <ScrollUpSVG isVisible={showScrollUp}/>
      <Header />
      <div
        className={`w-fit h-fit ${
          stateCtx.headerState === HEADER_STATE.Open ? "hidden" : "visible"
        }`}
      >
        <main>
          <Routes>
            <Route path="/" exact element={<Home footerRef={footerRef} scroll={false} />} />
            <Route path="/contactUs" exact element={<ContactUs />} />
            <Route path="/privacypolicy" exact element={<PrivacyPolicy />} />
          </Routes>
        </main>
        <Footer ref={footerRef}/>
      </div>
      {(stateCtx.headerState === HEADER_STATE.Open ||
        stateCtx.headerState === HEADER_STATE.Closing) && <Menu />}

    </div>
  );
};

export default App;
