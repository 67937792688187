import React, {forwardRef} from "react";
import Logo from "../Logo";
import Svg from "../SVG";

import classes from "./Footer.module.css";

const Footer = forwardRef((props, ref) => {
  const onFbClick = () => {
    window.open(
      "https://www.facebook.com/toofstone",
      "_blank",
      "noopener,noreferrer"
    );
  };
  const onLinkedInClick = () => {
    window.open(
      "https://www.linkedin.com/company/toofstone/",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <footer
      className={`w-[100vw] h-[22vh] bg-toofstone-green dark:bg-buttonBgDark shadow-inner flex flex-col align-middle justify-between ${props.className} `}
      ref={ref}
    >
      <div className={classes.top}>
        <Logo className={`${classes.logo}`} inFooter={true} />
        <div className={classes.right}>
          <div className={classes.followUs}>
            <h1>Follow Us</h1>
            <div>
              <button onClick={onFbClick}>
                <Svg icon={"fb"} id={classes.icon} backgroundFill={"white"} />
              </button>
              <button onClick={onLinkedInClick}>
                <Svg
                  icon={"linkedin"}
                  id={classes.icon}
                  backgroundFill={"white"}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.line}></div>
      <div className={classes.bottom}>
        <p>© 2022 toof</p>
        <p>All Rights Reserved. AKM</p>
      </div>
      {/* <div className={classes.mobileFooter}>
        <p>© 2022 toof</p>
      </div> */}
    </footer>
  );
});

export default Footer;
