import React, { useCallback, useEffect, useReducer } from "react";
import TestimonialItem from "./TestimonialItem";
import walter from "../../assets/Testimonials/WalterHessert.jpeg";
import scott from "../../assets/Testimonials/Scott.jpg";
import mike from "../../assets/Testimonials/Mike.jpeg";
import mesrop from "../../assets/Testimonials/Mesrop.jpeg";

const TESTIMONIALS = [
  {
    id: "1",
    photo: walter,
    name: "Walter Hessert",
    position: "Head Of Strategy",
    company: "Paxos",
    description:
      "When they started delivering on all our wildest requests, that’s when we decided to invest in building a long-term relationship with TOOFstone. The developers I personally got to work with have strong commitment to work. Everything they’ve promised, they delivered.",
  },
  {
    id: "2",
    photo: scott,
    name: "Scott Hoffer",
    position: "Founder",
    company: "Forklift Studios",
    description:
      "TOOFstone's leadership has deep experience in engineering management, which is part of the reason they've been so reliable in pairing our projects with exactly the right developers. They took the time to understand what we needed to hit the ground running fast (and on budget) and have made our outsourcing process remarkably easy and efficient.",
  },
  {
    id: "3",
    photo: mike,
    name: "Michael Reilly",
    position: "Co-Founder",
    company: "SiteSpecs",
    description:
      "When looking to outsource developers for some of our projects, I was fortunate to sign with TOOFStone. The human resources are of the highest quality. The team provides an exceptional combination of enhanced technical skills and strong communication which is something you do not always get from an external team.",
  },
  {
    id: "4",
    photo: mesrop,
    name: "Mesrop Minasyan",
    position: "Founder & CEO",
    company: "NAME.AM, Inc",
    description:
      "TOOFstone is very honest and realistic when scoping and scheduling the development which highly enhances our ability to plan. The team is not only responsive to changes in requirements, but also accessible. I’ve worked with TOOFstone in the past, and I’m sure we’ll have another opportunity to cooperate.",
  },
];
//https://toof-stone-default-rtdb.firebaseio.com/

const VISIBLE_COUNT_HALF = 2;

const DEFAULT_STATE = {
  mouseDown: false,
  mouseDownPosition: { x: 0, y: 0 },
  mouseDownTime: 0,
  direction: "none",
  activeItemIndex: 0,
  showTestionials: false,
};

const constructViewMatrix = (activeItemIndex) => {
  const matrix = {};

  for (let i = -VISIBLE_COUNT_HALF, j = 0; i <= VISIBLE_COUNT_HALF; i++, j++) {
    let index = activeItemIndex + i;
    if (index < 0) {
      index = TESTIMONIALS.length - Math.abs(index);
    }
    if (index >= TESTIMONIALS.length) {
      index -= TESTIMONIALS.length;
    }

    const abs = Math.abs(i);

    const scale = 1 - abs * 0.1;
    const x = (1 - scale) * 10;
    const y = (1 - scale) * 100 * Math.sign(i);
    const z = -abs*2;
    const opacity = i === 0 ? 1 : 1 - abs * 0.1;
    const zIndex = VISIBLE_COUNT_HALF * 2 - abs;
    const blur = abs * 2;

    matrix[`${index}`] = {
      scale,
      x,
      y,
      z,
      opacity,
      zIndex,
      blur,
    };
  }

  return matrix;
};

const REDUCER_TYPE = {
  MouseDown: "mouseDown",
  MouseUp: "mouseUp",
  ScrollTo: "scrollTo",
};

const StateReducer = (state, action) => {
  switch (action.type) {
    case REDUCER_TYPE.MouseDown:
      return {
        ...state,
        mouseDown: true,
        mouseDownPosition: action.payload.position,
        mouseDownTime: Date.now(),
      };
    case REDUCER_TYPE.MouseUp:
      var newState = { ...state };
      const distance = action.payload.position.y - newState.mouseDownPosition.y;
      const direction = Math.sign(distance);
      newState.mouseDown = false;
      newState.direction = direction < 0 ? "up" : "down";

      const duration = Date.now() - newState.mouseDownTime;

      if (duration < 500 && Math.abs(distance) > 50) {
        newState.activeItemIndex -= Math.sign(distance);
        if (newState.activeItemIndex < 0) {
          newState.activeItemIndex = TESTIMONIALS.length - 1;
        } else if (newState.activeItemIndex === TESTIMONIALS.length) {
          newState.activeItemIndex = 0;
        }
      }

      return newState;
    case REDUCER_TYPE.ScrollTo:
      return {
        ...state,
        activeItemIndex: action.payload.index,
        direction: action.payload.index > state.activeItemIndex ? "up" : "down",
      };
    default:
      return DEFAULT_STATE;
  }
};

const Testimonials = (props) => {
  const [state, dispatch] = useReducer(StateReducer, DEFAULT_STATE);

  const mouseDownHandler = (event) => {
    const position = {
      x: event.clientX ? event.clientX : event.touches[0].pageX,
      y: event.clientY ? event.clientY : event.touches[0].pageY,
    };

    dispatch({ type: REDUCER_TYPE.MouseDown, payload: { position: position } });
  };

  const mouseUpHandler = (event) => {
    const clientY = event.clientY
      ? event.clientY
      : event.changedTouches[0].pageY;
    const clientX = event.clientX
      ? event.clientX
      : event.changedTouches[0].pageX;

    dispatch({
      type: REDUCER_TYPE.MouseUp,
      payload: { position: { x: clientX, y: clientY } },
    });
  };

  const viewMatrix = constructViewMatrix(state.activeItemIndex);

  const dots = TESTIMONIALS.map((t, index) => (
    <div
      key={`scrollDot${index}`}
      className={`w-4 h-4 rounded-full bg-toofstone-light-green border-2 ease-out duration-200 
      p-2
      ${index === state.activeItemIndex ? "opacity-100" : "opacity-50"}
      ${index === state.activeItemIndex ? "scale-100" : "scale-50"}
      ${
        index === state.activeItemIndex
          ? "border-toofstone-green "
          : "scale-50 border-toofstone-light-green"
      }
      `}
      onClick={() => dispatch({ type: "scrollTo", payload: { index: index } })}
    />
  ));

  return (
    <div
      className={`relative
      ${props.className}`}
      style={{ transform: `perspective(100vw)`, transformStyle: "preserve-3d" }}
    >
      {/* <h1 className="font-bold text-xl md:text-2xl lg:text-4xl mb-16 z-10  bg-white shadow-lg leading-loose text-center rounded-lg p-2">
        Testimonials
      </h1> */}
      <div className="absolute hidden lg:visible top-[35%] left-[100%] lg:flex lg:flex-col gap-1 justify-center p-4">
        {dots.map((d) => d)}
      </div>
      {TESTIMONIALS.map((testimonial, index) => (
        <div
          key={testimonial.id}
          className={`absolute
            flex flex-col
            touch-none
            duration-[400ms] ease-in-out
            w-[95%] md:w-[100%]  h-[90%] md:h-[100%] 
            left-[2.5%] md:left-0
            origin-top
            z-10
            ${index === state.activeItemIndex ? "shadow-lg" : "shadow-none"}
          `}
          onMouseDown={mouseDownHandler}
          onMouseUp={mouseUpHandler}
          onTouchStart={mouseDownHandler}
          onTouchEnd={mouseUpHandler}
          onDrag={(event) => {
            console.log("drag");
            event.preventDefault();
          }}
          style={{
            transform: `scale(${viewMatrix[index].scale}) translateX(${viewMatrix[index].x}%)
              translateY(${viewMatrix[index].y}%) translateZ(${viewMatrix[index].z}px)`,
            opacity: `${viewMatrix[index].opacity}`,
            // zIndex: `${viewMatrix[index].zIndex}`,
            filter: `blur(${viewMatrix[index].blur}px)`,
          }}
        >
          <TestimonialItem
            id={testimonial.id}
            photo={testimonial.photo}
            name={testimonial.name}
            position={testimonial.position}
            company={testimonial.company}
            description={testimonial.description}
            className={`duration-300 ease-out`}
            // z={
            //   viewMatrix.length - Math.abs(viewMatrix.indexOf(index) - 2)
            // }
            // className={`absolute touch-none ${
            //   index === state.activeItemIndex ? "ease-in-out" : "ease-in-out"
            // } duration-500
            // ${index === state.activeItemIndex ? "" : ""}
            // `}

            // onClick={scrollToHandler}
          />
        </div>
      ))}
    </div>
  );
};

export default React.memo(Testimonials);
