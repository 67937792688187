import React from "react";

export const HEADER_STATE = {
  Closed: 1,
  Open: 2,
  Opening: 3,
  Closing: 4,
};

const StateContext = React.createContext({
  headerState: HEADER_STATE.Closed,
  portfolioShownTutorialsCount: 0,
  scroll: "home",
  setHeaderState: (headerState) => {},
  incrementPortfolioShownTutorialsCount: () => {},
  resetPortfolioShownTutorialsCount: () => {},
  setScroll: (newScroll) => {}
});

export default StateContext;
