import React from "react";

const TestimonialItem = (props) => {
  return (
    <div
      className={`
      w-full h-full
      flex flex-col items-start justify-start
      bg-white dark:bg-buttonBgDark
      rounded-xl
      box-border 
      p-4
      gap-4
      pointer-events-none select-none
      ${props.className}`}
      draggable={false}
      style={props.style}
      onClick={props.onClick}
    >
      <div
        className={`h-[20%] md:h-[30%] lg:h-[35%] w-full relative flex justify-start`}
      >
        <div className="flex-[.6]">
          <img
            src={props.photo}
            alt="icon"
            loading="lazy"
            className="max-h-full rounded-full 
            drop-shadow-xl pointer-events-none select-none
          "
          />
        </div>

        <div className="flex-1 pt-2 md:pt-5 lg:pt-8">
          <p
            className={`font-semibold 
            text-[12px] md:text-lg leading-relaxed
            pointer-events-none select-none
            text-black dark:text-white
            `}
          >
            {props.name}
          </p>
          <p
            className={`font-medium 
            text-[10px] md:text-[16px]
             text-toofstone-green dark:text-toofstone-light-green leading-relaxed
              pointer-events-none select-none
            
            `}
          >
            {props.position}
          </p>
          <p
            className={`font-medium lg:text-lg text-[12px]
              pointer-events-none select-none 
              text-black dark:text-white
          `}
          >
            {props.company}
          </p>
        </div>
      </div>
      <div className={`overflow-hidden`}>
        <p
          className={` 
          font-normal 
          text-md md:text-lg lg:text-xl
           pointer-events-none select-none
           text-black dark:text-white`}
        >
          {props.description}
        </p>
      </div>
    </div>
  );
};

export default TestimonialItem;
