import classes from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
    return <div className={`px-8 flex flex-col items-center ${classes.container}`}>
        <h2 className={`text-2xl text-backgroundColorDark dark:text-backgroundColor ${classes.header}`}>Privact Policy</h2>
        <div className="text-backgroundColorDark dark:text-backgroundColor text-lg text-justify">
        This privacy policy governs your use of a software application (“Application”) on a mobile device that was created by TOOFstone. The Application includes gaming (both online and offline) and social sharing features.

What information does the Application obtain and how is it used?
User Provided Information – The Application obtains the information you provide when you download and register the Application. Registration with us is mandatory in order to be able to use the basic features of the Application.

When you register with us and use the Application, you generally provide (a) your user name and other registration information; (b) transaction-related information, such as when you make purchases, respond to any offers, or download or use applications from us; (c) information you provide us when you contact us for help; (d) information you enter into our system when using the Application, such as contact information and project management information. We may also use the information you provided us to contact your from time to time to provide you with important information, required notices and marketing promotions.

Automatically Collected Information - In addition, the Application may collect certain information automatically, such as the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the Application. See “Automatic Data Collection and Advertising” section for examples.

Does the Application collect precise real-time location information of the device?
This Application does not collect precise information about the location of your mobile device.

Do third parties see and/or have access to information obtained by the Application?
We may disclose User Provided and Automatically Collected Information:
• as required by law, such as to comply with a subpoena, or similar legal process;
• when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;
• with our trusted services providers who work on our behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.
• if TOOFstone is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of this information, as well as any choices you may have regarding this information;
• to advertisers and third-party advertising networks and analytics companies as described below under the Section entitled Automatic Data Collection and Advertising.

This Application is supported via advertising and collects data to help the Application serve ads. We may work with analytics companies to help us understand how the Application is being used, such as the frequency and duration of usage. We work with advertisers and third-party advertising networks, who need to know how you interact with advertising provided in the Application, which helps us keep the cost of the Application low. Advertisers and advertising networks use some of the information collected by the Application, including the unique identification ID of your mobile device. These third parties may also obtain information about other applications you’ve downloaded to your mobile device, the mobile websites you visit, your non-precise location information (e.g., your zip code), and other non-precise location information in order to help analyze and serve anonymous targeted advertising on the Application and elsewhere.

If you’d like to opt-out from third party use of this type of information to help serve targeted advertising, please visit the section entitled “Opt-out” below.

What are my opt-out rights?
Opt-out of all information collection by uninstalling the Application – You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as
        </div>
    </div>
}

export default PrivacyPolicy;