import React from "react";

const Logo = (props) => {
  return (
    <svg
      className={`${props.className}`}
      onClick={props.onClick}
      width="186"
      height="70"
      viewBox="0 0 186 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath="url(#clip0_4_261)"
        className={`${
          props.inFooter
            ? "fill-white dark:fill-backgroundColor"
            : "fill-black dark:fill-backgroundColor"
        }`}
      >
        <path d="M47.9131 0H8.61111L0 14V70H56.5242V14L47.9131 0ZM52.1083 65.625H4.41595V18.8125H52.1083V65.625Z" />
        <path d="M90.5712 29.75V9.8875H82.7991V3.5H105.806V9.8875H98.0342V29.75H90.5712Z" />
        <path d="M129.873 26.5562C127.268 29.1375 123.868 30.4062 119.761 30.4062C115.654 30.4062 112.298 29.1375 109.648 26.5562C107.043 23.975 105.718 20.6938 105.718 16.625C105.718 12.6 107.043 9.275 109.648 6.69375C112.254 4.1125 115.654 2.84375 119.761 2.84375C123.868 2.84375 127.224 4.1125 129.873 6.69375C132.479 9.275 133.803 12.6 133.803 16.625C133.803 20.6938 132.479 23.975 129.873 26.5562ZM119.805 23.6687C121.792 23.6687 123.382 23.0125 124.53 21.7C125.678 20.3875 126.252 18.725 126.252 16.625C126.252 14.525 125.678 12.8625 124.53 11.55C123.382 10.2375 121.836 9.58125 119.805 9.58125C117.818 9.58125 116.228 10.2375 115.08 11.55C113.932 12.8625 113.358 14.525 113.358 16.625C113.358 18.6375 113.932 20.3 115.08 21.6562C116.228 22.9688 117.818 23.6687 119.805 23.6687Z" />
        <path d="M159.504 26.5562C156.899 29.1375 153.499 30.4062 149.392 30.4062C145.285 30.4062 141.929 29.1375 139.279 26.5562C136.674 23.975 135.349 20.6938 135.349 16.625C135.349 12.6 136.674 9.275 139.279 6.69375C141.885 4.1125 145.285 2.84375 149.392 2.84375C153.499 2.84375 156.855 4.1125 159.504 6.69375C162.11 9.275 163.434 12.5562 163.434 16.625C163.434 20.6938 162.11 23.975 159.504 26.5562ZM149.392 23.6687C151.379 23.6687 152.969 23.0125 154.117 21.7C155.265 20.3875 155.839 18.725 155.839 16.625C155.839 14.525 155.265 12.8625 154.117 11.55C152.969 10.2375 151.423 9.58125 149.392 9.58125C147.405 9.58125 145.815 10.2375 144.667 11.55C143.519 12.8625 142.944 14.525 142.944 16.625C142.944 18.6375 143.519 20.3 144.667 21.6562C145.859 22.9688 147.405 23.6687 149.392 23.6687Z" />
        <path d="M166.128 29.75V3.5H186V9.8875H173.547V13.3438H184.852V19.5125H173.547V29.75H166.128Z" />
        <path d="M97.7251 50.575C95.7821 48.8688 93.8832 48.5625 91.7194 48.5625C88.7165 48.5188 85.8462 49.6563 85.9345 52.0625C86.0228 54.6 89.3789 55.125 91.7635 55.5188C95.208 56.0875 99.8889 56.6562 99.7123 61.1187C99.5798 65.3187 95.208 66.4562 91.8077 66.4562C88.4074 66.4562 85.0513 65.1875 83.3732 62.65L84.7863 61.425C86.3761 63.7 89.2464 64.6625 91.8519 64.6625C94.1923 64.6625 97.7251 64.05 97.8576 61.0312C97.9459 58.275 94.7222 57.75 91.5869 57.2687C87.8333 56.6562 84.1239 55.9562 84.1239 52.15C84.0798 48.3875 87.8775 46.7688 91.7635 46.8125C94.5898 46.8125 97.0185 47.6 98.9174 49.35L97.7251 50.575Z" />
        <path d="M108.368 41.3438V47.075H114.903V48.65H108.368V60.2875C108.368 62.8688 108.897 64.7062 111.9 64.7062C112.828 64.7062 113.887 64.4 114.859 63.9188L115.521 65.45C114.329 66.0188 113.093 66.4125 111.9 66.4125C107.793 66.4125 106.469 64.0063 106.469 60.2875V48.65H102.362V47.075H106.469V41.5625L108.368 41.3438Z" />
        <path d="M118.436 56.7C118.436 50.6188 122.808 46.7688 128.195 46.7688C133.583 46.7688 137.954 50.6188 137.954 56.7C137.954 62.7813 133.583 66.4562 128.195 66.4562C122.808 66.4562 118.436 62.7375 118.436 56.7ZM136.1 56.7C136.1 51.7563 132.567 48.5625 128.195 48.5625C123.823 48.5625 120.291 51.7563 120.291 56.7C120.291 61.6438 123.823 64.6187 128.195 64.6187C132.567 64.6187 136.1 61.6438 136.1 56.7Z" />
        <path d="M144.269 50.5312C145.771 47.9062 148.42 46.8125 151.114 46.7688C156.236 46.7688 159.813 49.875 159.813 55.1687V66.15H157.915V55.1687C157.915 50.8375 155.221 48.5188 151.07 48.5625C147.095 48.6062 144.313 51.5813 144.313 55.5188V66.15H142.415V47.075H144.269V50.5312Z" />
        <path d="M163.699 56.6562C163.699 50.925 168.071 46.8125 173.459 46.8125C178.89 46.8125 183.704 50.0938 182.953 57.5312H165.598C165.996 61.9937 169.44 64.575 173.415 64.575C175.932 64.575 178.89 63.5688 180.348 61.6875L181.717 62.7812C179.862 65.2313 176.506 66.4562 173.415 66.4562C168.071 66.4562 163.699 62.6938 163.699 56.6562ZM181.275 55.8687C181.231 51.1437 178.14 48.5188 173.503 48.5188C169.528 48.5188 166.084 51.1875 165.687 55.8687H181.275Z" />
      </g>
      <defs>
        <clipPath id="clip0_4_261">
          <rect width="186" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
