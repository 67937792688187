import emailjs from "@emailjs/browser";
import { useState } from "react";

const validateName = (name) => name.trim().length > 0;
const validateEmail = (email) => email.trim().length > 0;

const ContactForm = (props) => {
  const [enteredMessage, setEnteredMessage] = useState("");
  const [enteredName, setEnteredName] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [nameInputHasError, setNameInputHasError] = useState(false);
  const [emailInputHasError, setEmailInputHasError] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const nameChangeHandler = (event) => {
    setEnteredName(event.target.value);
    setNameInputHasError(false);
  };
  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
    setEmailInputHasError(false);
  };

  const messageChangeHandler = (event) => {
    setEnteredMessage(event.target.value);
  };

  const confirmHandler = (event) => {
    event.preventDefault();
    if (isSending) {
      return;
    }

    const isEmailValid = validateEmail(enteredEmail);
    const isNameValid = validateName(enteredName);

    if (!isEmailValid || !isNameValid) {
      setEmailInputHasError(!isEmailValid);
      setNameInputHasError(!isNameValid);
      return;
    }

    setIsSending(true);

    var templateParams = {
      username: enteredName,
      email: enteredEmail,
      message: enteredMessage,
    };

    emailjs
      .send(
        "service_d1ppc0m",
        "template_hvjftpz",
        templateParams,
        "xMR5o8IuUWnXPuWbe"
      )
      .then(
        function (response) {
          setIsSending(false);
          setEnteredName("");
          setEnteredEmail("");
          setEnteredMessage("");
        },
        function (error) {
          setIsSending(false);
        }
      );
  };

  return (
    <div>
      <form onSubmit={confirmHandler}>
        <div
          className={`relative w-full md:w-[35vw] md:max-w-md h-11 ${
            nameInputHasError ? "mb-8" : "mb-4"
          }`}
        >
          <input
            type="text"
            id="name"
            placeholder="Full Name"
            value={enteredName}
            onChange={nameChangeHandler}
            className={`
                caret-black dark:caret-white
                text-black dark:text-white
                outline-none
                bg-transparent
                w-full h-full
                ${
                  nameInputHasError
                    ? "border-b-red-700"
                    : "border-b-black dark:border-b-backgroundColor"
                } border-b-[3px] rounded-none`}
          />
          {nameInputHasError && (
            <p className={`text-red-600`}>Entered name can not be empty!</p>
          )}
        </div>
        <div
          className={`relative w-full md:w-[35vw] md:max-w-md h-11 ${
            emailInputHasError ? "mb-8" : "mb-4"
          }`}
        >
          <input
            type="email"
            id="email"
            placeholder="E-mail"
            value={enteredEmail}
            onChange={emailChangeHandler}
            className={`
            caret-black dark:caret-white
            text-black dark:text-white  
                    outline-none    
                    bg-transparent
                    w-full h-full
                    ${
                      emailInputHasError
                        ? "border-b-red-700"
                        : "border-b-black dark:border-b-backgroundColor"
                    } border-b-[3px] rounded-none`}
          />
          {emailInputHasError && (
            <p className={`text-red-600`}>Please enter valid email!</p>
          )}
        </div>
        <div className={`relative w-full md:w-[35vw] md:max-w-md h-11 mb-4`}>
          <textarea
            name="message"
            id="message"
            placeholder="Type your message here..."
            // rows={10}
            onChange={messageChangeHandler}
            value={enteredMessage}
            className={`
            caret-black dark:caret-white
            text-black dark:text-white
                rounded-none
                resize-y 
                bg-transparent
                w-full h-full
                outline-none
                border-black dark:border-b-backgroundColor border-b-[3px]
            `}
          ></textarea>
        </div>
        <div className={`flex justify-center md:justify-start`}>
          <button
            className={`bg-toofstone-green shadow-md rounded-md font-bold text-white text-3xl py-2 px-5 ${
              isSending ? "animate-pulse transition-transform ease-out" : ""
            }`}
          >
            {isSending ? "Sending" : "Send"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
