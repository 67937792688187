import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import phoneWallpaper from "../../assets/phone-wallpaper.webp";
import useIsInViewport from "../hooks/use-is-in-viewport";
import { PORTFOLIO_ITEMS } from "../Pages/Portfolio";
import StateContext from "../../store/state-context";
import { ORIENTATION } from "../Utils";

const AppIconView = ({ id, appName, className, onClick }) => {
  return (
    <div
      className={`relative flex flex-col ${className}`}
      onClick={() => onClick(id)}
    >
      <img
        src={`/icons/${appName}.webp`}
        alt="appIcon"
        className="flex-2 aspect-square rounded-lg hover:scale-105 transition-transform ease-out duration-[333ms]"
      />
    </div>
  );
};

const DEFAULT_STATE = {
  activeItem: -1,
  playVideo: false,
  isBackButtonVisible: false,
  isTutorialVisible: false,
  tutorialNeeded: true,
};

const REDUCER_ACTION_TYPE = {
  SetActiveItem: "phone_spfi",
  PlayVideo: "phone_pv",
  BackButtonClick: "phone_bbc",
  ShowTutorial: "phone_st",
  HideTutorial: "phone_hide-tutorial",
  SetBackButtonVisible: "phone_setbuttonvisible",
  CheckPhoneOrientation: "phone_check-orientation",
};

const stateReducer = (state, action) => {
  switch (action.type) {
    case REDUCER_ACTION_TYPE.SetActiveItem:
      return {
        ...state,
        activeItem: action.itemId,
        isTutorialVisible: false,
        tutorialNeeded: false,
      };
    case REDUCER_ACTION_TYPE.PlayVideo:
      if (
        !PORTFOLIO_ITEMS.filter((item) => item.id === state.activeItem)[0].demo
      ) {
        return state;
      }

      return { ...state, playVideo: true };
    case REDUCER_ACTION_TYPE.BackButtonClick:
      return {
        ...state,
        activeItem: -1,
        playVideo: false,
        isBackButtonVisible: false,
      };
    case REDUCER_ACTION_TYPE.SetBackButtonVisible:
      return { ...state, isBackButtonVisible: action.visible };
    case REDUCER_ACTION_TYPE.ShowTutorial:
      return { ...state, isTutorialVisible: true };
    case REDUCER_ACTION_TYPE.HideTutorial:
      return { ...state, isTutorialVisible: false };
    case REDUCER_ACTION_TYPE.CheckPhoneOrientation:
      // const orientation =
      //   action.width > action.height ? ORIENTATION.Landscape : ORIENTATION.Portrait;

      // const portfolioItem = PORTFOLIO_ITEMS.filter(
      //   (item) => item.id === state.activeItem
      // )[0];

      // if (state.playVideo && portfolioItem.orientation !== orientation) {
      //   if (portfolioItem.orientation === ORIENTATION.Landscape) {
      //     if (action.height < window.innerWidth && state.phoneRotation !== 90) {
      //       dispatch({
      //         type: REDUCER_ACTION_TYPE.SetPhoneRotation,
      //         rotation: 90,
      //       });
      //     } else {
      //       console.log("show rotation tutorial");
      //     }
      //   } else {
      //     if (action.width < window.innerHeight && state.phoneRotation !== 0) {
      //       // dispatch({
      //       //   type: REDUCER_ACTION_TYPE.SetPhoneRotation,
      //       //   rotation: 0,
      //       // });
      //     }
      //     console.log("show rotation tutorial for portrait");
      //   }
      // } else if (state.phoneRotation !== 0 && state.activeItem !== -1) {
      //   // dispatch({
      //   //   type: REDUCER_ACTION_TYPE.SetPhoneRotation,
      //   //   rotation: 0,
      //   // });
      // }
      return { ...state, phoneRotation: action.rotation };
    default:
      return DEFAULT_STATE;
  }
};

const TUTORIALS_COUNT = 10;

const Phone = ({ className, width, height }) => {
  const ctx = useContext(StateContext);
  const [state, dispatch] = useReducer(stateReducer, DEFAULT_STATE);
  const phoneRef = useRef();

  const isIntersecting = useIsInViewport(phoneRef);

  const itemClickHandler = useCallback((itemId) => {
    dispatch({ type: REDUCER_ACTION_TYPE.SetActiveItem, itemId: itemId });
  }, []);

  const showTutorialHandler = useCallback(() => {
    if (state.isTutorialVisible || state.activeItem !== -1) {
      return state;
    }
    dispatch({ type: REDUCER_ACTION_TYPE.ShowTutorial });
    ctx.incrementPortfolioShownTutorialsCount();
  }, [ctx, state]);

  const hideTutorialHandler = useCallback(() => {
    if (!state.isTutorialVisible) {
      return;
    }
    dispatch({ type: REDUCER_ACTION_TYPE.HideTutorial });
  }, [state.isTutorialVisible]);

  useEffect(() => {
    if (state.activeItem === -1) {
      return;
    }

    const timeOut = setTimeout(() => {
      dispatch({ type: REDUCER_ACTION_TYPE.PlayVideo });
    }, 1000);
    return () => clearTimeout(timeOut);
  }, [state.activeItem]);

  useEffect(() => {
    if (!state.tutorialNeeded) {
      return;
    }
    const canShowTutorial = () =>
      ctx.portfolioShownTutorialsCount < TUTORIALS_COUNT;
    if (isIntersecting) {
      if (!canShowTutorial()) {
        return;
      }
      showTutorialHandler();
    } else {
      hideTutorialHandler();
    }
  }, [
    ctx.portfolioShownTutorialsCount,
    hideTutorialHandler,
    isIntersecting,
    showTutorialHandler,
    state.isTutorialVisible,
    state.tutorialNeeded,
  ]);

  const portfolioItem = PORTFOLIO_ITEMS.filter(
    (item) => item.id === state.activeItem
  )[0];

  // useEffect(() => {

  // }
  // }, [height, orientation, portfolioItem, portfolioItem.orientation, state.phoneRotation, width]);

  let orientation =
    width > height ? ORIENTATION.Landscape : ORIENTATION.Portrait;

  if (
    state.activeItem !== -1 &&
    state.playVideo &&
    orientation !== portfolioItem.orientation
  ) {
    if (portfolioItem.orientation === ORIENTATION.Landscape) {
      if (height < window.innerWidth) {
        orientation = ORIENTATION.Landscape;
        const h = height;
        height = width;
        width = h;
      } else {
        // console.log("show landscape tutorial");
      }
    } else {
      if (width < window.innerHeight) {
        // console.log("asasas");
        orientation = ORIENTATION.Portrait;
        const h = height;
        height = width;
        width = h;
      } else {
        // console.log("show portrait tutorial");
      }
    }
  }

  return (
    <div
      ref={phoneRef}
      className={`relative origin-center
            border-[10px] border-phoneBorderColorLight dark:border-backgroundColorDark rounded-3xl
            overflow-hidden
            shadow-phoneLight dark:shadow-phoneDark
            ${className}
            `}
      onMouseEnter={() => {
        dispatch({
          type: REDUCER_ACTION_TYPE.SetBackButtonVisible,
          visible: true,
        });
      }}
      onMouseLeave={() => {
        dispatch({
          type: REDUCER_ACTION_TYPE.SetBackButtonVisible,
          visible: false,
        });
      }}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <img
        src={phoneWallpaper}
        alt="wallpaper"
        className="absolute top-0 left-0 w-[100%] h-[100%] object-cover
         "
      />

      <p
        className={`relative text-center  
        font-semibold text-xl
        ${
          orientation === ORIENTATION.Landscape
            ? "float-left pl-[10%] pt-[3.5%] text-backgroundColorDark"
            : "pt-[35%] text-backgroundColor"
        }
      `}
      >
        {state.isTutorialVisible ? "Click on icon" : "Portfolio"}
      </p>
      <div
        className={`
                absolute 
                ${
                  orientation === ORIENTATION.Landscape
                    ? "w-[40%] h-[85.6%] pl-[45%] pt-[3.5%]"
                    : "pt-[10%] px-[7.2%] w-[85.6%] h-[40%] "
                } 
                ${state.activeItem !== -1 ? "ease-in duration-150 scale-75" : "ease-out scale-100 duration-300"}
                rounded-3xl`}
      >
        <div
          className={`
                absolute 
                w-[100%] h-[100%] 
                bg-phoneBorderColorLight/30 dark:bg-black/30 backdrop-blur-md
                rounded-3xl`}
        ></div>
        <div
          className={`
                absolute 
                w-[100%] h-[100%] 
                p-[5%]
                grid grid-cols-3 grid-rows-3 gap-x-[12%] gap-y-[12%] 
                z-10
                `}
        >
          {PORTFOLIO_ITEMS.map((item) => (
            <AppIconView
              id={item.id}
              appName={item.id}
              key={item.id}
              onClick={itemClickHandler}
              className={`ease-out duration-100 ${state.activeItem === item.id ? "opacity-0" : "opacity-100"} `}
            />
          ))}
        </div>
      </div>
      <div
        className={`absolute top-0 left-0 w-[100%] h-[100%]
        bg-gray-800 rounded-xl z-10
        ${
          state.activeItem === -1
            ? "duration-[250ms] ease-in scale-0 opacity-0"
            : "duration-[333ms] ease-out scale-100 opacity-100"
        }
      `}
      >
        {state.activeItem !== -1 && !state.playVideo && (
          <img
            src={`/icons/${portfolioItem.id}.webp`}
            alt="appIcon"
            className={`absolute 
            ${
              orientation === ORIENTATION.Landscape
                ? "h-[25%] left-[44.25%] top-[40%]"
                : "w-[25%] left-[37.5%] top-[40%] "
            }
            
            block aspect-square rounded-lg
            `}
          ></img>
        )}
        {state.playVideo && (
          <video
            className={`w-[100%] h-[100%] border-none rounded-lg ${
              portfolioItem.orientation === orientation ? "object-cover" : ""
            }`}
            autoPlay
            muted
            playsInline
          >
            <source
              src={portfolioItem.demo}
              type="video/mp4"
              className={`h-fit w-fit object-fill border-none rounded-lg`}
            />
          </video>
        )}
      </div>
      {state.activeItem !== -1 && (
        <BackButton
          isVisible={state.isBackButtonVisible}
          landscape={orientation === ORIENTATION.Landscape}
          onClick={() => {
            dispatch({ type: REDUCER_ACTION_TYPE.BackButtonClick });
          }}
        />
      )}
    </div>
  );
};

export default React.memo(Phone);

const BackButton = ({ isVisible, landscape, onClick }) => {
  return (
    <div
      className={`absolute rounded-full
  aspect-square z-20
    ${
      landscape
        ? "w-[10%] left-[45%] bottom-[5%]"
        : "w-[20%] left-[40%] bottom-[10%]"
    }
  
    bg-slate-900 
    border-x-white border-[3px]
      opacity-0 ${isVisible ? "opacity-100" : "opacity-0"}
  `}
      onClick={onClick}
    ></div>
  );
};
